// import { ScratchBlocks } from '../../libs';
//
// const buildImgUrl = (name, ext = 'svg') =>
//     `/media/junior/${name}.${ext}`;

const penCustomDefine = () => ({});

const musicCustomDefine = () => ({});

export default {
    pen: penCustomDefine,
    music: musicCustomDefine
};
