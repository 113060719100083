import React from 'react';

import '!style-loader!css-loader!./scratch-playground-loading.css';

const ScratchPlaygroundLoading = () => (
    <div className="scratch-playground-loading">
        <div className="loading-wheel" />
    </div>
);

export default ScratchPlaygroundLoading;
