import Cookie from 'js-cookie';

const AccountType = {
    TEACHER: 0,
    STUDENT: 1
};

const DefaultAccount = {
    id: 0,
    name: 'scratch-cat',
    avatar: '//img.wkcoding.com/1499248056914?imageView2/2/w/200',
    userType: AccountType.STUDENT
};

const getLocalAccount = () => {
    const username = Cookie.get('username') || Cookie.get('account') || 'x';
    const nickname = Cookie.get('nickname') || 'x';
    const userid = Cookie.get('userid') || 5;
    const usertype = Cookie.get('usertype') || 4;
    const avator = Cookie.get('avator');

    return {
        userid,
        username,
        nickname,
        avator,
        usertype
    };
};

export {
    getLocalAccount,
    AccountType,
    DefaultAccount
};
