import ScratchBlocks from 'scratch-blocks';

let isTextureRendererInstalled = false;
const textureRendererBaseStyle = {};

const installTextureRenderer = () => {
    const baseStyle = textureRendererBaseStyle;

    ScratchBlocks.BlockSvg.prototype._oldRenderDraw_ =
        ScratchBlocks.BlockSvg.prototype.renderDraw_;
    ScratchBlocks.BlockSvg.prototype.renderDraw_ = function(
        iconWidth,
        inputRows
    ) {
        if (
            this.type === ScratchBlocks.PROCEDURES_DEFINITION_BLOCK_TYPE ||
            this.isInsertionMarker_
        ) {
            return this._oldRenderDraw_(iconWidth, inputRows);
        }

        if (!this.outputConnection && !this.previousConnection) {
            inputRows.forEach(row => (row.paddingStart += 20));
        }

        this._oldRenderDraw_(iconWidth, inputRows);
        if (!this.getSvgRoot().classList.contains('blocklyDraggable')) return;

        const targetPathEle = this.getSvgRoot().childNodes[0];
        let texturePath = '';

        let {width, height, x, y} = targetPathEle.getBBox();

        let isInsertDom = false;
        if (width || height || x || y) {
            isInsertDom = true;
        }
        width = isInsertDom ? width + x : this.width;
        height = isInsertDom ? height + y : this.height;

        const params = [this, width, height];

        if (!this.outputConnection && !this.previousConnection) {
            // hat
            targetPathEle.setAttribute(
                'd',
                targetPathEle.getAttribute('d').replace(/l[- 0-9,.]+?z$/i, 'z')
            );
            texturePath = baseStyle.buildHatTexturePath(...params);
        } else if (this.outputConnection) {
            switch (this.getOutputShape()) {
                case ScratchBlocks.OUTPUT_SHAPE_HEXAGONAL:
                    texturePath = baseStyle.buildBooleanTexturePath(...params);
                    break;
                case ScratchBlocks.OUTPUT_SHAPE_ROUND:
                default:
                    texturePath = baseStyle.buildNumberStringTexturePath(
                        ...params
                    );
            }
        } else if (this.nextConnection) {
            texturePath = baseStyle.buildStatementTexturePath(...params);
        } else {
            texturePath = baseStyle.buildEndTexturePath(...params);
        }

        texturePath = texturePath.trim().replace(/\n/gm, '');
        if (baseStyle.customRenderObj && baseStyle.customRenderObj[this.type]) {
            baseStyle.customRenderObj[this.type](this);
            return;
        }

        // targetPathEle.setAttribute('stroke', '');
        if (
            this.getSvgRoot().children[1] &&
            this.getSvgRoot().children[1].getAttribute('className') &&
            this.getSvgRoot().children[1].getAttribute('className') ===
                'cm-svg-texture-group'
        ) {
            this.getSvgRoot().removeChild(this.getSvgRoot().children[1]);
        }

        const gEle = ScratchBlocks.utils.createSvgElement('g', {
            className: 'cm-svg-texture-group'
        });
        gEle.innerHTML = texturePath;
        this.getSvgRoot().insertBefore(gEle, this.getSvgRoot().children[1]);
    };
};

/**
 * Apply new style.
 * @param {object} styles Blocks style definitions.
 */
export default function applyStyle(styles) {
    if (!isTextureRendererInstalled) {
        installTextureRenderer();
        isTextureRendererInstalled = true;
    }

    if (styles.init) {
        styles.init();
    }
    Object.assign(textureRendererBaseStyle, styles);
}
