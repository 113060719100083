import {DETECT} from '../../config';

import applyMerge from './apply/merge';

import defaultBlocks, {
    extension as defaultExtensionBlocks
} from './default/index';
import juniorBlocks, {extension as JuniorExtensionBlocks} from './junior/index';
import defaultMakeToolboxXml from './default/make-toolbox-xml';
import juniorMakeToolboxXml from './junior/make-toolbox-xml';
import loadExtension from './extension/index';

let isJunior = DETECT.isJunior;
const setJuniorType = () => {
    isJunior = true;
};

/**
 * Add junior blocks definition into default blocks OR opposite.
 * Allow editor in default mode can load junior mode. The same as opposite.
 * @param {VirtualMachine} vm Instance of VirtualMachine.
 */
const VMScratchBlocks = vm => {
    if (isJunior) {
        // The junior blocks contain pen blocks.
        vm.extensionManager.loadExtensionURL('pen');
        applyMerge(juniorBlocks, defaultBlocks, vm);
    } else {
        applyMerge(defaultBlocks, juniorBlocks, vm);
    }
};

/**
 *
 * @param {VirtualMachine} vm Instance of VirtualMachine.
 * @param {*} blocksInfo BlocksInfo.
 */
const applyExtensionCustom = function(vm, blocksInfo) {
    if (isJunior) {
        applyMerge(
            JuniorExtensionBlocks,
            defaultExtensionBlocks,
            vm,
            blocksInfo
        );
    } else {
        applyMerge(
            defaultExtensionBlocks,
            JuniorExtensionBlocks,
            vm,
            blocksInfo
        );
    }
};

/**
 * Enum for gui types.
 * @enum {string}
 */
const guiTypes = {
    DEFAULT: 'default',
    JUNIOR: 'junior'
};

/**
 * Store toolboxXML maker middlewares
 * @type {object.<string, function[]>}
 */
const makeToolboxXmlMiddlewares = {};
Object.values(guiTypes).forEach(type => (makeToolboxXmlMiddlewares[type] = []));

/**
 * Make toolbox xml. If user has defined, use that instead.
 * @param {object} args Source maketoolboxXml arguments.
 * @return {string} ToolboxXml.
 */
const makeToolboxXML = function(...args) {
    let type;
    let toolboxXmlMaker;

    if (isJunior) {
        type = guiTypes.JUNIOR;
        toolboxXmlMaker = juniorMakeToolboxXml;
    } else {
        type = guiTypes.DEFAULT;
        toolboxXmlMaker = defaultMakeToolboxXml;
    }

    return makeToolboxXmlMiddlewares[type].reduce(
        (toolboxXml, middleware) => middleware(toolboxXml),
        toolboxXmlMaker(...args)
    );
};

/**
 * Support customize what blocks show in flyoutworkspace.
 * @param {function[]|function} middlewares Middleware to customize toolboxXml.
 * @param {guiTypes} [mode] Gui type.
 */
const addMakeToolboxXmlMiddlewares = (middlewares, mode = null) => {
    middlewares = [].concat(middlewares);

    if (mode) {
        makeToolboxXmlMiddlewares[mode].push(...middlewares);
        return;
    }

    Object.values(guiTypes).forEach(type => {
        makeToolboxXmlMiddlewares[type].push(...middlewares);
    });
};

export {
    VMScratchBlocks as default,
    applyExtensionCustom,
    makeToolboxXML,
    guiTypes,
    makeToolboxXmlMiddlewares,
    addMakeToolboxXmlMiddlewares,
    loadExtension,
    setJuniorType
};
