import ScratchBlocks from 'scratch-blocks';

/**
 * Apply blocks colours definitions.
 * @param {object} colours Custom blocks colours definitions.
 */
export default function applyColours(colours) {
    Object.keys(colours).forEach(key => {
        ScratchBlocks.Colours[key].primary = colours[key].primary;
        ScratchBlocks.Colours[key].secondary = colours[key].secondary;
        ScratchBlocks.Colours[key].tertiary = colours[key].tertiary;
    });
}
