import {getLocalAccount} from '../lib/account';

import * as webConfig from 'PublicRepo/config/customize';
import oGet from 'lodash/get';

const UPDATE_ACCOUNT_INFO = 'scratch-gui/account/UPDATE_ACCOUNT_INFO';
const UPDATE_CUSTIMIZE_INFO = 'scratch-gui/account/UPDATE_CUSTIMIZE_INFO';

const formatAccountInfo = userInfo => {
    if (!userInfo) {
        return null;
    }

    return {
        id: String(userInfo.userid),
        hashId: String(userInfo.hash_id) || null,
        nickname: userInfo.nickname,
        username: userInfo.username,
        avatar: userInfo.avator,
        userType: userInfo.usertype,
        codeNum: userInfo.code_num || 0,
        goldNum: userInfo.gold_num || 0
    };
};

const localAccount = formatAccountInfo(getLocalAccount());

const initialState = Object.assign(
    localAccount ? {
        info: {
            ...localAccount,
            codeNum: 0,
            goldNum: 0
        },
        authenticated: false
    } : {
        authenticated: false
    },

    {
        webConfig: webConfig.getConfig()
    }
);

webConfig.load();

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case UPDATE_ACCOUNT_INFO:
        if (!action.info) return {authenticated: false};

        return Object.assign({}, state, {
            info: {...action.info},
            authenticated: true
        });
    case UPDATE_CUSTIMIZE_INFO:
        if (action.info) {
            return Object.assign({}, state, {
                webConfig: {...action.info}
            });
        }
        return state;
    default:
        return state;
    }
};

// 仅对后端返回的数据调用format
// const updateAccountInfo = accountInfo => ({
//     type: UPDATE_ACCOUNT_INFO,
//     info: formatAccountInfo(accountInfo)
// });

const updateAccountInfo = accountInfo => dispatch => {
    dispatch({
        type: UPDATE_ACCOUNT_INFO,
        info: formatAccountInfo(accountInfo)
    });
    // 更新同步用户机构品牌定制信息
    Promise.resolve(oGet(accountInfo, 'school.brand_id', 'bellcode'))
        .then(brandId => webConfig.sync(brandId))
        .then(config => {
            dispatch({
                type: UPDATE_CUSTIMIZE_INFO,
                info: config
            });
        });
};


export {
    reducer as default,
    initialState as accountInitialState,
    updateAccountInfo
};
