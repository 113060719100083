import Fetch from "PublicRepo/lib/fetch";
import Detect from "Common/detect";
import Config from "Config";

export default {
    reportActivityComplete({ costTime, errTimes, usedBlocksNum, score, total } = {}) {
        let commonParam = {};
        const { actId, lessonId, classId, taskId, packageId } = Detect;

        if (total) {
            commonParam = {
                time: costTime,
                error: errTimes,
                block: usedBlocksNum,
                score,
                total
            };
        }

        return Fetch({
            url: Config.serverIo.getIDEResult,
            method: "post",
            params: taskId ?
                { task_id: taskId, ...commonParam } :
                {
                    activity_id: actId,
                    lesson_id: lessonId,
                    class_id: classId,
                    package_id: packageId,
                    time: costTime || 1
                }
        });
    },

    submitCompetitionInfo(
        {
            name,
            video,
            cover,
            qrcode
        }
    ) {

        return Fetch({
            url: Config.serverIo.submitCompetitionInfo,
            method: "post",
            params: {
                name,
                video,
                cover,
                qrcode
            }
        });

    },
    submitCompetitionInfoDraft(
        {
            projectId,
            groupNo
        }
    ) {

        return Fetch({
            url: Config.serverIo.submitCompetitionInfoDraft,
            method: "post",
            params: {
                project_id: projectId,
                group_no: groupNo
            }
        });

    },


    getCompetitionInfo() {
        return Fetch({
            url: Config.serverIo.getCompetitionInfo,
            method: "get"
        });
    }


};
