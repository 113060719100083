import React from 'react';
import * as webConfig from 'PublicRepo/config/customize';
import loadingSrc from './loading.gif';

class PuzzleLoading extends React.Component {
    render() {
        const loadingGifUrl = webConfig.get('Site.guiLoadingGif', loadingSrc);
        return (
            <div
                ref={ele => {
                    this.loadingEle = ele;
                }}
                style={{
                    background: `url(${loadingGifUrl})  center no-repeat #f1fdff`,
                    height: '100vh',
                    position: 'fixed',
                    width: '100vw',
                    zIndex: 99
                }}
            />
        );
    }
}

export default PuzzleLoading;
