import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {createStore, combineReducers, compose} from 'redux';
import ConnectedIntlProvider from './connected-intl-provider.jsx';

import localesReducer, {initLocale, localesInitialState} from '../reducers/locales';

import modeReducer, {modeInitialState, setPlayer, setFullScreen, setIsJunior} from '../reducers/mode.js';

import locales from '../l10n';
import {detectLocale} from './detect-locale';

import userDataManager from 'public-repo/utility/userDataManager';
import Injector from '../cm/lib/injectors/injector.jsx';
import {updateAccountInfo} from '../reducers/account';
import {openAccount} from '../reducers/modals';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/*
 * Higher Order Component to provide redux state. If an `intl` prop is provided
 * it will override the internal `intl` redux state
 * @param {React.Component} WrappedComponent - component to provide state for
 * @param {boolean} localesOnly - only provide the locale state, not everything
 *                      required by the GUI. Used to exclude excess state when
                        only rendering modals, not the GUI.
 * @returns {React.Component} component with redux and intl state provided
 */
const AppStateHOC = function (WrappedComponent, localesOnly) {
    class AppStateWrapper extends React.Component {
        constructor (props) {
            super(props);
            let initialState = {};
            let reducers = {};
            let enhancer;

            let initializedLocales = localesInitialState;
            const locale = detectLocale(Object.keys(locales));
            if (locale !== 'en') {
                initializedLocales = initLocale(initializedLocales, locale);
            }
            if (localesOnly) {
                // Used for instantiating minimal state for the unsupported
                // browser modal
                reducers = {locales: localesReducer, mode: modeReducer};
                initialState = {locales: initializedLocales, mode: modeInitialState};
                enhancer = composeEnhancers();
            } else {
                // You are right, this is gross. But it's necessary to avoid
                // importing unneeded code that will crash unsupported browsers.
                const guiRedux = require('../reducers/gui');
                const guiReducer = guiRedux.default;
                const {
                    guiInitialState,
                    guiMiddleware,
                    initFullScreen,
                    initPlayer,
                    initTelemetryModal
                } = guiRedux;
                const {ScratchPaintReducer} = require('scratch-paint');

                let initializedGui = guiInitialState;
                if (props.isFullScreen || props.isPlayerOnly) {
                    if (props.isFullScreen) {
                        initializedGui = initFullScreen(initializedGui);
                    }
                    if (props.isPlayerOnly) {
                        initializedGui = initPlayer(initializedGui);
                    }
                } else if (props.showTelemetryModal) {
                    initializedGui = initTelemetryModal(initializedGui);
                }
                reducers = {
                    locales: localesReducer,
                    scratchGui: guiReducer,
                    scratchPaint: ScratchPaintReducer,
                    mode: modeReducer
                };
                initialState = {
                    locales: initializedLocales,
                    scratchGui: initializedGui,
                    mode: modeInitialState
                };
                this.props.injector.enhanceReducers(reducers);
                this.props.injector.enhanceInitialState(initialState);
                enhancer = composeEnhancers(guiMiddleware);
            }
            const reducer = combineReducers(reducers);
            this.store = createStore(
                reducer,
                initialState,
                enhancer
            );
        }
        componentDidMount () {
            // Activity already run initUserInfo in 'injection-hoc'.
            if (this.props.injector.type) {
                if (userDataManager.userInfo) {
                    this.store.dispatch(updateAccountInfo(userDataManager.userInfo));
                }
                return;
            }

            // User had login, check if the userData is outdated.
            if (!this.props.isPlayerOnly) {
                userDataManager.initUserInfo().then(() => {
                    if (userDataManager.userInfo) {
                        this.store.dispatch(updateAccountInfo(userDataManager.userInfo));
                    } else {
                        userDataManager.logout().then(() => {
                            this.store.dispatch(updateAccountInfo(userDataManager.userInfo));
                            this.store.dispatch(openAccount());
                        });
                    }
                });
            }

            // note: 微信会用ide.bellcode.com/gui/pathname做映射，所以需要兼容匹配pathname的最后一个值
            let type = location.pathname.match(/([^./]+)(\.html)?$/);
            type = type ? type[1] : '';
            if (type === 'junior') {
                this.store.dispatch(setIsJunior(true));
            }
        }
        componentDidUpdate (prevProps) {
            if (localesOnly) return;
            if (prevProps.isPlayerOnly !== this.props.isPlayerOnly) {
                this.store.dispatch(setPlayer(this.props.isPlayerOnly));
            }
            if (prevProps.isFullScreen !== this.props.isFullScreen) {
                this.store.dispatch(setFullScreen(this.props.isFullScreen));
            }
        }
        render () {
            const {
                isFullScreen, // eslint-disable-line no-unused-vars
                isPlayerOnly, // eslint-disable-line no-unused-vars
                showTelemetryModal, // eslint-disable-line no-unused-vars
                injector,
                ...componentProps
            } = this.props;
            return (
                <Provider store={this.store}>
                    <ConnectedIntlProvider>
                        {injector.hijack(
                            'player',
                            <WrappedComponent {...componentProps} />
                        )}
                    </ConnectedIntlProvider>
                </Provider>
            );
        }
    }
    AppStateWrapper.defaultProps = {
        injector: new Injector()
    };
    AppStateWrapper.propTypes = {
        injector: PropTypes.instanceOf(Injector).isRequired,
        isFullScreen: PropTypes.bool,
        isPlayerOnly: PropTypes.bool,
        showTelemetryModal: PropTypes.bool
    };
    return AppStateWrapper;
};

export default AppStateHOC;
