/**
 *
 *
 * @param {string} json - scratchVM.toJSON()
 * @returns
 */
const getStatisticsData = json => {
    /* eslint-disable */
    const validHat = [
        'event_whenflagclicked',
        'event_whenkeypressed',
        'event_whenthisspriteclicked',
        'event_whenstagetouched',
        'event_whenbackdropswitchesto',
        'event_whengreaterthan',
        'event_whenbroadcastreceived'
    ];
    const targets = JSON.parse(json).targets;
    const blocks = targets.map(({blocks}) =>
        Object.values(blocks).filter((block, index) => {
            if (!block.shadow) {
                block.id = Object.keys(blocks)[index];
                return true;
            } else {
                return false;
            }
        })
    );
    const scripts = blocks.map(block => block.filter(block => block.topLevel));
    const validScripts = blocks.map(block =>
        block.filter(
            block => validHat.indexOf(block.opcode) !== -1 && block.next
        )
    );

    const validBlocks = [];
    blocks.forEach(block => {
        const arr = [];
        block.forEach(item => {
            let parent = item;
            while (parent && parent.parent) {
                parent = block.find(bc => bc.id === parent.parent);
            }
            if (parent && parent.next) {
                arr.push(item);
            }
        });
        validBlocks.push(arr);
    });

    return JSON.stringify(
        targets.map((project, i) => ({
            role_name: project.name,
            block_num: validBlocks[i].length,
            un_block_num: blocks[i].length - validBlocks[i].length,
            script_num: validScripts[i].length,
            un_script_num: scripts[i].length - validScripts[i].length,
            voice_num: project.sounds.length,
            costume_num: project.costumes.length
        }))
    );
};

export {getStatisticsData};
