/* eslint-disable react/no-multi-comp */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import IntercomHandler from 'PublicRepo/components/Intercom/intercomHandler';
import UserData from 'PublicRepo/utility/userDataManager';
// import GeneralConfig from 'PublicRepo/utility/generalConfig';
import './contactTeacher.less';
import Track from 'PublicRepo/components/Track/Track';
import IsInWebView from 'PublicRepo/utility/checkIsInWebView';
import teacherIconDefault from './assets/teacher-icon-default.png';

export const ContactTeacherPages = {
  HOME: 'home',
  MAP: 'map',
  COURSElIST: 'course-list',
  PUZZLE: 'puzzle',
  IDE_PUZZLE: 'ide-puzzle',
  DIY: 'diy',
  HOMEWORK: 'homework',
  GUI: 'gui',
};

export function checkIfUserHasCourse() {
  return UserData.userInfo && UserData.userInfo.unlocked_level;
}

export function checkIsToCUser() {
  // 屏蔽老师咨询功能通过这个方法判断，现在返回false屏蔽咨询老师功能
  return false;
  // return (
  //   UserData.userInfo
  //   && !UserData.userInfo.is_offline_business_user
  //   && UserData.userInfo.usertype === GeneralConfig.userTypeMap.student
  // );
}

class FakeContactTeacher extends React.Component {
  render() {
    return null;
  }
}

class ContactTeacher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      startDragTimer: 0,
      stopDragTimer: 0,
      panelActive: false,
      pos: null,
    };
    this.commonTrackParams = {
      page_type: this.props.pageType,
    };
    this.defaultPos = this.setDefaultPosition();
    this.isFixed = this.props.pageType === ContactTeacherPages.HOME;
    this.handleStart = this.handleStart.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.onHidePanel = this.onHidePanel.bind(this);
    this.popUpIntercom = this.popUpIntercom.bind(this);
  }

  static propTypes = {
    classId: PropTypes.string, // 仅地图页，puzzle2，puzzle3，DIY，homework
    lessonId: PropTypes.string, // 仅地图页，puzzle2，puzzle3，DIY，homework
    pageType: PropTypes.string.isRequired, // 当前页面类型
    proId: PropTypes.string, // DIY, homework, GUI
  };

  componentDidMount() {
    this.initZhiChi();
  }

  componentWillUnmount() {
    window.zhiManager = undefined;
  }

  initZhiChi() {
    IntercomHandler.initZhiChi({
      onExpand: null,
      onCollapse: this.onHidePanel,
    });
  }

  setIsReady() {
    this.setState({
      isReady: true,
    });
  }

  setDefaultPosition() {
    const pos = {
      x: document.body.offsetWidth - 300,
      y: document.body.offsetHeight - 100,
    };
    if (this.isInPuzzle()) {
      pos.x = 40;
    } else if (this.isInGUI()) {
      pos.x = document.body.offsetWidth - 400;
    }
    return pos;
  }

  isInPuzzle() {
    return (
      this.props.pageType === ContactTeacherPages.IDE_PUZZLE
      || this.props.pageType === ContactTeacherPages.PUZZLE
    );
  }

  isInGUI() {
    return (
      this.props.pageType === ContactTeacherPages.HOMEWORK
      || this.props.pageType === ContactTeacherPages.DIY
      || this.props.pageType === ContactTeacherPages.GUI
    );
  }

  popUpIntercom() {
    // return on dragging or is opening
    if (
      this.state.stopDragTimer - this.state.startDragTimer > 300
      || this.state.panelActive
    ) return;
    const {
      classId: class_id,
      lessonId: lesson_id,
      proId: pro_id,
    } = this.props;
    const params = Object.assign(
      {},
      this.commonTrackParams,
      pro_id && { pro_id },
      class_id && { class_id },
      lesson_id && { lesson_id },
    );
    Track.track('Feedback_openFeedbackWindow', params);
    // const data = Object.assign({}, UserData.userInfo);
    IntercomHandler.showPanel(this.onHidePanel);
    if (this.isFixed) return;
    this.setState({
      panelActive: true,
      // pos: {
      //   x: document.body.offsetWidth - 600,
      //   y:
      //     document.body.offsetHeight
      //     - 100
      //     - document.body.getBoundingClientRect().y,
      // },
    });
    // IntercomHandler.handlePanelVisibility(this.onHidePanel);
  }

  onHidePanel() {
    this.setState({
      panelActive: false,
      pos: null,
    });
  }

  handleStart() {
    if (this.isFixed) return;
    this.setState({
      startDragTimer: Date.now(),
    });
  }

  handleStop() {
    if (this.isFixed) return;
    this.setState({
      stopDragTimer: Date.now(),
    });
  }

  render() {
    // if(!this.state.isReady) return null;
    // ${!this.isFixed && this.state.panelActive ? 'active' : ''}
    return ReactDOM.createPortal(
      <Draggable
        defaultClassName={`
                    contact-teacher-draggable 
                    ${this.state.panelActive ? 'hidden' : ''}
                    ${this.isFixed ? 'fixed' : ''}
                `}
        bounds={{
          left: 20,
          bottom: window.innerHeight - 60,
          top: 60,
          right: window.innerWidth - 220,
        }}
        defaultPosition={this.defaultPos}
        position={this.state.pos}
        onStart={this.handleStart}
        onDrag={this.handleDrag}
        onStop={this.handleStop}
      >
        <div onClick={this.popUpIntercom}>
          <img
            className="teacher-icon"
            draggable={false}
            src={teacherIconDefault}
          />
          <button className="contact-teacher-btn zhiCustomBtn">
            {/* {this.state.panelActive ? '点击老师说出你的问题' : '点这里问老师'} */}
            点这里问老师
          </button>
        </div>
      </Draggable>,
      document.body,
    );
    // document.getElementById('bellcode-root'))
  }
}

export default (IsInWebView ? FakeContactTeacher : ContactTeacher);
