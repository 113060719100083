import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import MenuBarMenu from '../../../components/menu-bar/menu-bar-menu.jsx';
import {MenuSection} from '../../../components/menu/menu.jsx';
import MenuItemContainer from '../../../containers/menu-item.jsx';
import dropdownCaret from '../../../components/menu-bar/dropdown-caret.svg';

import styles from '../../../components/menu-bar/account-nav.css';

const hideSignOut = true; // 屏蔽gui登出入口

const AccountNavComponent = props => (
    <React.Fragment>
        <div
            className={classNames(styles.userInfo, props.className)}
            onMouseUp={props.onClick}
        >
            {props.account.info.avatar ? (
                <img
                    className={styles.avatar}
                    src={props.account.info.avatar}
                />
            ) : null}
            <span className={styles.profileName}>
                {props.account.info.nickname}
            </span>
            <div className={styles.dropdownCaretPosition}>
                <img
                    className={styles.dropdownCaretIcon}
                    src={dropdownCaret}
                />
            </div>
        </div>
        <MenuBarMenu
            className={props.menuBarMenuClassName}
            open={props.isOpen}
            place={props.isRtl ? 'right' : 'left'}
            onRequestClose={props.onClose}
        >
            <MenuSection>
                {!hideSignOut && (
                    <MenuItemContainer onClick={props.onLogOut}>
                        <FormattedMessage
                            defaultMessage="Sign out"
                            description="Text to link to sign out, in the account navigation menu"
                            id="gui.accountMenu.signOut"
                        />
                    </MenuItemContainer>
                )}
                <MenuItemContainer onClick={props.onSwitchJunior}>
                    {props.isJunior ? '切换到：学龄' : '切换到：学龄前'}
                </MenuItemContainer>
            </MenuSection>
        </MenuBarMenu>
    </React.Fragment>
);

AccountNavComponent.propTypes = {
    account: PropTypes.shape({
        info: PropTypes.shape({
            avatar: PropTypes.string,
            nickname: PropTypes.string
        }),
        authenticated: PropTypes.bool.isRequired
    }),
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    isRtl: PropTypes.bool,
    menuBarMenuClassName: PropTypes.string,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    onLogOut: PropTypes.func,
    isJunior: PropTypes.bool.isRequired,
    onSwitchJunior: PropTypes.func
};

export default AccountNavComponent;
