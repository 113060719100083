import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import userDataManager from '../public-repo/utility/userDataManager';
import Fetch from '../public-repo/lib/fetch';
import GeneralConfig from '../public-repo/utility/generalConfig';

import AccountModalComponent from '../components/account-modal/account-modal.jsx';
import {updateAccountInfo} from '../reducers/account';
import {closeAccount} from '../reducers/modals';

class AccountModal extends React.Component {
    constructor (props) {
        super(props);

        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
        this.handleSignUp = this.handleSignUp.bind(this);
        this.handleTestUsername = this.handleTestUsername.bind(this);
        this.handleWechatSignIn = this.handleWechatSignIn.bind(this);
    }

    handleSignIn ({username, passwd}) {
        return userDataManager.login(username, passwd)
            .then(data => {

                // eslint-disable-next-line
                if (data.loginType !== undefined) {
                    return Promise.reject(data);
                }

                this.handleLoginSuccess(data);

            })
            .catch(err => err);
    }

    handleLoginSuccess (data) {
        this.props.onUpdateAccountInfo(data);
        this.props.onRequestClose(data);
    }

    handleSignUp (userData) {
        return userDataManager.register(userData)
            .then(this.props.onUpdateAccountInfo)
            .then(this.props.onRequestClose);
    }

    handleWechatSignIn () {
        return userDataManager.getBindUserInfo()
            .then(this.props.onUpdateAccountInfo)
            .then(this.props.onRequestClose);
    }

    handleTestUsername (name) {
        return Fetch({
            url: GeneralConfig.serverIo.uname_check,
            method: 'post',
            params: {
                username: name
            }
        });
    }

    render () {
        return (
            <AccountModalComponent
                id={'account'}
                title={'Account'}
                onClose={this.props.onRequestClose}
                onRequestClose={this.props.onRequestClose}
                onSignIn={this.handleSignIn}
                onLoginSuccess={this.handleLoginSuccess}
                onSignUp={this.handleSignUp}
                onTestUsername={this.handleTestUsername}
                onWechatSignIn={this.handleWechatSignIn}
            />
        );
    }
}

AccountModal.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    onUpdateAccountInfo: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    onRequestClose: () => dispatch(closeAccount()),
    onUpdateAccountInfo: accountInfo => dispatch(updateAccountInfo(accountInfo))
});

export default connect(null, mapDispatchToProps)(AccountModal);
