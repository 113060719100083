/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable quotes */
import xhr from 'xhr';
import Fetch from 'public-repo/lib/fetch';
import GeneralConfig from 'public-repo/utility/generalConfig';
import {getHttpURL} from 'public-repo/utility/locationBuilder';

const _getQiniuToken = (() => {
    const TokenTLT = 5 * 60 * 1000; // 5min
    const requestCache = new Map();

    return (bucket, prefix) => {
        const requestKey = `${bucket}-${prefix}`;
        const request = requestCache.get(requestKey);
        if (request) {
            if (+new Date() - request.timestamp < TokenTLT) {
                return request.promise;
            }

            requestCache.delete(requestKey);
        }

        const params = {
            bucket
        };

        if (prefix) {
            params.prefix = `${prefix}/`;
        }

        const promise = Fetch({
            method: 'get',
            url: GeneralConfig.serverIo.get_qiniu_token,
            params
        });

        requestCache.set(requestKey, {
            promise,
            timestamp: +new Date()
        });

        return promise.catch(err => {
            requestCache.delete(requestKey);
            throw err;
        });
    };
})();

const _readyQiniuUploadData = (data, {prefix, bucket, name}) => {
    const isBase64 = /data:image\/\w+;base64,/.test(data);

    // path for not base64 data is depended on upload filename instead of token.
    if (!isBase64) {
        // Here prefix has not use, just compact for be.
        // prefix = null;
        if (name && prefix) {
            name = `${prefix}/${name}`;
        }
    }

    return _getQiniuToken(bucket, prefix).then(({token, domain}) => {
        let uploadConfig;

        if (isBase64) {
            const base64Data = data.substr(data.indexOf(',') + 1);

            uploadConfig = {
                method: 'post',
                uri: getHttpURL(GeneralConfig.serverIo.uploadQiniu).href,
                body: base64Data,
                headers: {
                    'Content-Type': 'application/octet-stream',
                    Authorization: `UpToken ${token}`
                }
            };
        } else {
            const formData = new FormData();

            if (name) {
                formData.append('key', name);
            }
            formData.append('file', data);
            formData.append('token', `${token}`);

            uploadConfig = {
                method: 'post',
                uri: getHttpURL('//upload-z2.qiniup.com').href,
                body: formData
            };
        }

        return {
            domain,
            uploadConfig
        };
    });
};

const _uploadAsset = (data, bucket, prefix, name) =>
    _readyQiniuUploadData(data, {bucket, prefix, name}).then(
        ({uploadConfig, domain}) =>
            new Promise((resolve, reject) => {
                xhr(uploadConfig, (err, resp, body) => {
                    if (
                        err ||
                        (resp.statusCode < 200 || resp.statusCode > 300)
                    ) {
                        if (resp.statusCode === 614) {
                            // eslint-disable-next-line no-alert
                            alert(
                                `请联系 OO 老师。麻烦复制以下代码 ${prefix}/${name}`
                            );
                        }
                        reject(err);
                        return;
                    }

                    let parsedData;
                    try {
                        parsedData = JSON.parse(body);
                    } catch (e) {
                        reject(e);
                        return;
                    }

                    resolve({
                        path: `${domain}/${parsedData.key}`,
                        ...parsedData
                    });
                });
            })
    );

const AssetBucket = {
    IMAGE: 'image',
    OTHER: 'other',
    PROJECT: 'project'
};

const uploadImage = (data, prefix, name) =>
    _uploadAsset(data, AssetBucket.IMAGE, prefix || 'ide', name);
const uploadJSON = (data, prefix, name) =>
    _uploadAsset(data, AssetBucket.PROJECT, null, name);
const uploadAudio = (data, prefix, name) =>
    _uploadAsset(data, AssetBucket.OTHER, prefix || 'ide', name);
const uploadVideo = (data, prefix, name) =>
    _uploadAsset(data, AssetBucket.OTHER, prefix || 'ide', name);

export {uploadImage, uploadJSON, uploadAudio, uploadVideo};
