/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Modal } from 'antd';
import Config from 'PublicRepo/utility/generalConfig';
import UserData from 'PublicRepo/utility/userDataManager';
import CModel from 'PublicRepo/models/classRelatedInfo';
import get from 'lodash/get';
import Page from '../../Paging';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/modal/style/index.css';
import UniversalClassList from './universalClassList';
import AssistStuLogin from './assistStuLogin';
import './orgTeacherSignIn.less';

class OrgTeacherSignIn extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static propTypes = {
    orgTeacherSignInData: PropTypes.object,
    signInSucceed: PropTypes.func,
  };

  state = {
    stuList: [],
    classList: [],
    pageData: {}, // 班级列表页码信息
    searchKey: '', // 搜索关键字
    stuClassId: '', // 当前学生列表对应的班级ID
    stuPageData: null, // 学生列表页码信息
    isLoading: false, // 当前是否正在获取数据
    showTokenExipreModal: false, // 是否显示token过期对话框
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getClassListByTemporaryToken();
  }

  // 获取班级列表
  getClassListByTemporaryToken = (pageNum = 1) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const { self } = this.props.orgTeacherSignInData;
        const { searchKey } = this.state;
        CModel.getClassListByTemporaryToken(
          self.temporary_token,
          pageNum,
          5,
          searchKey,
        )
          .then(data => {
            const { list, total } = data;
            this.setState({
              isLoading: false,
              classList: list,
              pageData: {
                curPage: pageNum,
                totalPage: Math.ceil(total / 5),
              },
            });
          })
          .catch(err => {
            this.setState({
              isLoading: false,
            });
            this.handleTokenExpire(err);
          });
      },
    );
  };

  // 获取指定班级的学生
  getClassStudent = (class_id, pageNum = 1) => {
    const { self } = this.props.orgTeacherSignInData;
    CModel.getStudentListByTemporaryToken(
      self.temporary_token,
      class_id,
      pageNum,
      8,
    )
      .then(data => {
        const { list, total } = data;
        this.setState({
          stuClassId: class_id,
          stuList: list,
          stuPageData: {
            curPage: pageNum,
            totalPage: Math.ceil(total / 8),
          },
        });
      })
      .catch(err => {
        this.handleTokenExpire(err);
      });
  };

  // 教师使用临时token登录
  teacherSignIn = token => {
    UserData.loginByToken(token).then(data => {
      if (data && data.errType === Config.tokenType.OVER_DUE) {
        // todo 过期，返回页面 re-scan
      } else {
        UserData.initUserInfo()
          .then(userInfo => this.props.signInSucceed(userInfo))
          .catch(() => this.props.signInSucceed());
      }
    });
  };

  closeSignIn = () => {
    this.props.closePopup();
  };

  handleBackToTeacher = () => {
    this.setState({
      stuList: [],
      stuPageData: null,
      stuClassId: '',
    });
  };

  // 获取老师登录token
  getTeacherLoginToken = ({ temporary_token, user_id }) => {
    CModel.getUserTokenByTemporaryToken(temporary_token, user_id)
      .then(data => {
        const { token } = data;
        this.teacherSignIn(token);
      })
      .catch(err => {
        this.handleTokenExpire(err);
      });
  };

  renderSelfInfo = selfInfo => {
    const { customBgColor = '#1fa1ff' } = this.props;
    return (
      <header className="org-teacher-header">
        <div className="title">登录教师账号</div>
        <div className="content">
          <div className="item">
            <div className="item-name">
              账号：
              {selfInfo.user_name}
            </div>
            <div className="item-name">
              昵称：
              {selfInfo.nick_name}
            </div>
          </div>
          <div className="item">
            <div
              style={{ backgroundColor: customBgColor }}
              className="button"
              onClick={() => this.getTeacherLoginToken(selfInfo)}
            >
              确认登录
            </div>
          </div>
        </div>
      </header>
    );
  };

  // 处理enter键输入
  handleKeyDown = keyCode => {
    if (keyCode === 13) this.getClassListByTemporaryToken();
  };

  renderClassesInfo = () => {
    const {
 pageData, classList, searchKey, isLoading,
} = this.state;
    return (
      <div className="org-teacher-classes-info">
        <div className="searchCon">
          <div className="classTitle">班级名称</div>
          <Input
            className="searchInput"
            placeholder="请输入班级名称"
            value={searchKey}
            onChange={e => this.setState({ searchKey: e.target.value })}
            onKeyDown={e => this.handleKeyDown(e.keyCode)}
          />
          <Button
            className="searchBtn"
            onClick={() => {
              this.getClassListByTemporaryToken();
            }}
          >
            搜索
          </Button>
        </div>
        <div className="content">
          <UniversalClassList
            listType={Config.loginListTypeMap.TEACHER}
            list={classList}
            getClassStudent={this.getClassStudent.bind(this)}
            isLoading={isLoading}
            customBgColor={this.props.customBgColor}
          />
        </div>
        <Page
          totalPage={get(pageData, 'totalPage', 0)}
          curPage={get(pageData, 'curPage', 1)}
          goPage={this.getClassListByTemporaryToken.bind(this)}
          className="pageBottom"
        />
      </div>
    );
  };

  // 统一处理老师扫码操作认证token过期
  handleTokenExpire = err => {
    switch (err.code) {
      case 20001: // 老师扫码操作认证token失效
        Modal.error({
          title: '操作超时，请重新扫码登录',
          onOk: () => {
            window.location.reload();
          },
          okText: '确认',
        });
        break;
    }
  };

  // 学生列表翻页获取数据
  getSpecifyPageStudentList = pageNum => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const { self } = this.props.orgTeacherSignInData;
        const { stuClassId } = this.state;
        CModel.getStudentListByTemporaryToken(
          self.temporary_token,
          stuClassId,
          pageNum,
          8,
        )
          .then(data => {
            const { list, total } = data;
            this.setState({
              isLoading: false,
              stuList: list,
              stuPageData: {
                curPage: pageNum,
                totalPage: Math.ceil(total / 8),
              },
            });
          })
          .catch(err => {
            this.setState({
              isLoading: false,
            });
            this.handleTokenExpire(err);
          });
      },
    );
  };

  render() {
    const { self } = this.props.orgTeacherSignInData;
    const {
 stuList, stuPageData, isLoading, stuClassId,
} = this.state;
    const { customBgColor, customTextColor, customTitleColor } = this.props;

    return (
      <div className="org-teacher-sign-in">
        <div
          className="org-teacher-sign-in-close-button"
          onClick={this.closeSignIn}
        />

        {stuList.length ? (
          <div className="org-teacher-sign-in-ct">
            <AssistStuLogin
              temporaryToken={self.temporary_token}
              stuList={stuList}
              backToTeacher={this.handleBackToTeacher}
              teacherSignIn={this.teacherSignIn}
              stuPageData={stuPageData}
              getSpecifyStuList={this.getSpecifyPageStudentList}
              isLoading={isLoading}
              stuClassId={stuClassId}
              handleTokenExpire={this.handleTokenExpire}
              customBgColor={customBgColor}
              customTextColor={customTextColor}
              customTitleColor={customTitleColor}
            />
          </div>
        ) : (
          <div className="org-teacher-sign-in-content">
            <header className="org-teacher-sign-in-title">
              老师您好，请选择登录方式
            </header>
            {this.renderSelfInfo(self)}
            {this.renderClassesInfo()}
          </div>
        )}
      </div>
    );
  }
}

export default OrgTeacherSignIn;
