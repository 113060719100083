import React, { Component } from 'react';
import PropTypes from 'prop-types';

import i18n from 'PublicRepo/i18n/common';
import { USER } from 'PublicRepo/lib/config';

class SignUp extends Component {
  static propTypes = {
    onSignUp: PropTypes.func.isRequired,
    onTestUsername: PropTypes.func.isRequired,
    routeToSignIn: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired,
  };

  componentWillMount() {
    this.setState({
      usernameErr: null,
      unErrInfo: '',
      passwordErr: null,
      pwErrInfo: '',
    });
  }

  checkUsername() {
    const _username = this.uname.value.trim();

    if (!_username) {
      this.setState({
        usernameErr: true,
        unErrInfo: i18n.get('Username should not be null'),
      });

      return;
    }

    this.setState({
      usernameErr: false,
      unErrInfo: '',
    });

    this.props
      .onTestUsername(_username)
      .then(() => {})
      .catch(error => {
        console.log(error);
        this.setState({
          usernameErr: true,
          unErrInfo: i18n.get(error.message),
        });
      });
  }

  checkPassword() {
    const _password = this.password.value;

    if (!_password) {
      this.setState({
        passwordErr: true,
        pwErrInfo: i18n.get('Password should not be null'),
      });

      return;
    }

    if (_password.length < 6) {
      this.setState({
        passwordErr: true,
        pwErrInfo: i18n.get('Password should be at least 6 characters'),
      });

      return;
    }

    this.setState({
      passwordErr: false,
      pwErrInfo: '',
    });
  }

  postForm() {
    const username = this.uname.value;
    const passwd = this.password.value;

    if (!username || !passwd) {
      return;
    }

    const { usernameErr, passwordErr } = this.state;

    if (usernameErr || passwordErr) {
      alert(i18n.get('please fix the field '));
      return;
    }

    const data = {
      username,
      passwd,
      usertype: this.props.userType || USER.TYPE.STUDENT,
    };

    this.props.onSignUp(data);
  }

  render() {
    return (
      <div className="dialog-free-stu-account">
        <div className="con">
          <div className="free-stu-account-title">
            {i18n.get(
              this.props.userType === USER.TYPE.STUDENT
                ? 'FREE STUDENT ACCOUNT'
                : 'FREE TEACHER ACCOUNT',
            )}
          </div>
          <div
            className={`username ${
              this.state.usernameErr ? 'error-input' : ''
            }`}
          >
            <input
              type="text"
              ref={ele => {
                this.uname = ele;
              }}
              className="input-username"
              onBlur={() => {
                this.checkUsername();
              }}
              placeholder={i18n.get('Username (at least 6 characters)')}
            />
            <p className="tip">{this.state.unErrInfo}</p>
          </div>
          <div className={`pwd ${this.state.passwordErr ? 'error-input' : ''}`}>
            <input
              type="password"
              ref={ele => {
                this.password = ele;
              }}
              className="input-pwd"
              onBlur={() => {
                this.checkPassword();
              }}
              placeholder={i18n.get('Password (at least 6 characters)')}
            />
            <p className="tip">{this.state.pwErrInfo}</p>
          </div>
          <a
            role="button"
            tabIndex="0"
            onClick={() => {
              this.postForm();
            }}
            className="btn-next"
          >
            {i18n.get('Next')}
          </a>
          <div className="have-account">
            <span>{i18n.get('Already have a account?')}</span>
            <span
              role="button"
              tabIndex="0"
              className="btn-log-in"
              onClick={this.props.routeToSignIn}
            >
              {i18n.get('Log in')}
            </span>
          </div>
          <span role="button" tabIndex="0" className="btn-privacy-policy" />
        </div>
      </div>
    );
  }
}

export default SignUp;
