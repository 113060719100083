import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import eraseIcon from './icon-erase.svg';
import styles from './erase.css';

const EraseComponent = function (props) {
    const {
        active,
        className,
        onClick,
        title,
        ...componentProps
    } = props;
    return (
        <img
            className={classNames(
                className,
                styles.erase,
                {
                    [styles.isActive]: active
                }
            )}
            draggable={false}
            id={'gui-controls__erase'}
            src={eraseIcon}
            title={title}
            onClick={onClick}
            {...componentProps}
        />
    );
};

EraseComponent.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string
};

EraseComponent.defaultProps = {
    active: false,
    title: 'Erase'
};

export default EraseComponent;
