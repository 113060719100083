import ScratchBlocks from 'scratch-blocks';
import formatMessage from 'format-message';

const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px

const motion = function(isStage) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'MOTION_STAGE_SELECTED',
        'Stage selected: no motion blocks'
    );
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
        ${
            isStage
                ? `
        <label text="${stageSelected}"></label>
        `
                : `
        <block type="motion_movesteps_top" />
        <block type="motion_movesteps_bottom" />
        <block type="motion_movesteps_left" />
        <block type="motion_movesteps_right" />
        ${blockSeparator}
        <block type="motion_turnright_deg" />
        <block type="motion_turnleft_deg" />
        ${blockSeparator}
        ${
            !window.location.pathname.startsWith('/junior')
                ? `<block type="motion_goto" id="motion_goto">
            <value name="TO">
                <shadow type="motion_goto_menu">
                </shadow>
            </value>
        </block>`
                : ''
        }
        <block type="motion_cm_goto" id="motion_cm_goto">
            <value name="TO">
                <shadow type="motion_cm_goto_menu">
                </shadow>
            </value>
        </block>
        <block type="motion_goto__mouse_pointer" />
        <block type="motion_goto__random_position" />`
        }
        ${categorySeparator}
    </category>
    `;
};

const looks = function(isStage) {
    const hello = ScratchBlocks.ScratchMsgs.translate('LOOKS_HELLO', 'Hello!');
    // const hmm = ScratchBlocks.ScratchMsgs.translate('LOOKS_HMM', 'Hmm...');
    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
        ${
            isStage
                ? ''
                : `
        <block type="looks_sayforsecs_shorttime">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">${hello}</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        `
        }
        ${
            isStage
                ? `
            <block type="looks_nextbackdrop"/>
            <block type="looks_cm_switchbackdropto" id="looks_cm_switchbackdropto">
                <value name="BACKDROP">
                    <shadow type="looks_cm_backdrops"></shadow>
                </value>
            </block>
        `
                : `
            <block type="looks_nextcostume"/>
            <block type="looks_nextbackdrop"/>
            <block type="looks_cm_switchbackdropto" id="looks_cm_switchbackdropto">
                <value name="BACKDROP">
                    <shadow type="looks_cm_backdrops"></shadow>
                </value>
            </block>
            <block id="looks_cm_switchcostumeto" type="looks_cm_switchcostumeto">
                <value name="COSTUME">
                    <shadow type="looks_cm_costume">
                        <field name="COSTUME"></field>
                    </shadow>
                </value>
            </block>
            ${blockSeparator}
            <block type="looks_changesizeby_zoomin" />
            <block type="looks_changesizeby_zoomout" />
            <block type="looks_setsizeto_reset" />
        `
        }
        ${blockSeparator}
        <block type="looks_changeeffectby__fisheye_minus" />
        <block type="looks_changeeffectby__fisheye_plus" />
        <block type="looks_changeeffectby__mosaic_minus" />
        <block type="looks_changeeffectby__mosaic_plus" />
        <block type="looks_changeeffectby__ghost_minus" />
        <block type="looks_changeeffectby__ghost_plus" />
        <block type="looks_changeeffectby__whirl_minus" />
        <block type="looks_changeeffectby__whirl_plus" />
        <block type="looks_seteffectto__color">
            <value name="VALUE">
                <shadow type="math_whole_number">
                    <field name="NUM">20</field>
                </shadow>
            </value>
        </block>
        ${
            isStage
                ? ''
                : `
            <block type="looks_show"/>
            <block type="looks_hide"/>
            ${blockSeparator}
        `
        }
        ${categorySeparator}
    </category>
    `;
};

const sound = function(isStage, targetId) {
    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
        <block id="${targetId}_sound_play" type="sound_play">
            <value name="SOUND_MENU">
                <shadow type="sound_sounds_menu" />
            </value>
        </block>
        <block type="sound_stopallsounds" />
        ${categorySeparator}
    </category>
    `;
};

const events = function(isStage) {
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">
        <block type="event_whenflagclicked"/>
        <block type="event_whenkeypressed" />
        ${
            isStage
                ? `
            <block type="event_whenstageclicked"/>
        `
                : `
            <block type="event_whenthisspriteclicked"/>
            <block type="event_whenstagetouched"/>
        `
        }
        ${blockSeparator}
        <block type="event_whenbroadcastreceivedv2" />
        <block type="event_broadcastv2" />
        <block type="event_whentouchingotherobjects" />
         <block type="event_whentouchingtargetobject" />
        ${categorySeparator}
    </category>
    `;
};

const control = function() {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        <block type="control_wait">
            <value name="DURATION">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="control_repeat">
            <value name="TIMES">
                <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block id="forever" type="control_forever"/>
        <block  type="control_stop_other_scripts_in_sprite"/>
        <block  type="control_stop_all_scripts"/>
        ${categorySeparator}
    </category>
    `;
};

const pen = function() {
    const penName = formatMessage({
        id: 'pen.categoryName',
        default: 'pen',
        description: 'pen extension'
    });

    return `
    <category name="${penName}" id="pen" colour="#00B295" secondaryColour="#0B8E69">
        <block type="pen_pen_down" />
        <block type="pen_pen_up" />
        ${blockSeparator}
        <block type="pen_set_pen_color_to_color">
            <value name="COLOR">
                <shadow type="colour_picker" />
            </value>
        </block>
        <block type="pen_changePenSizeBy_up" />
        <block type="pen_changePenSizeBy_down" />
        <block type="pen_clear_pen" />

    </category>
    `;
};

const xmlOpen = '<xml style="display: none">';
const xmlClose = '</xml>';

/**
 * @param {!boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {?string} targetId - The current editing target
 * @param {string?} categoriesXML - null for default toolbox, or an XML string with <category> elements.
 * @returns {string} - a ScratchBlocks-style XML Body document for the contents of the toolbox.
 */
export const makeToolboxXmlBody = function(isStage, targetId, categoriesXML) {
    const gap = [categorySeparator];

    const everything = [
        motion(isStage, targetId),
        gap,
        looks(isStage, targetId),
        gap,
        sound(isStage, targetId),
        gap,
        events(isStage, targetId),
        gap,
        control(isStage, targetId)
    ];

    if (categoriesXML) {
        // Has pen extension loaded
        // if (categoriesXML.indexOf('pen_clear') !== -1) {
        // junior support pen
        everything.push(gap, pen(isStage, targetId));
        // }
    }

    return everything;
};

/**
 * @param {!boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {?string} targetId - The current editing target
 * @param {string?} categoriesXML - null for default toolbox, or an XML string with <category> elements.
 * @returns {string} - a ScratchBlocks-style XML document for the contents of the toolbox.
 */
const makeToolboxXml = function(isStage, targetId, categoriesXML) {
    const everything = makeToolboxXmlBody(isStage, targetId, categoriesXML);
    return [xmlOpen, everything, xmlClose].join('\n');
};

export default makeToolboxXml;
