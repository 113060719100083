import Cookie from 'js-cookie';
import i18n from 'PublicRepo/i18n';
const languageUtil = {
    setLocale: (lang, param) => {
        if (lang === 'zh-cn') {
            Cookie.set('lang', 'cn', param);
            i18n.setLang('cn');
        } else {
            Cookie.set('lang', lang, param);
            i18n.setLang(lang);
        }
    },
    getLocale: () => {
        const lang = Cookie.get('lang');
        if (lang === 'cn') {
            return 'zh-cn';
        }

        // default lang as zh-cn
        if (!lang) {
            return 'zh-cn';
        }
        return lang;
    }
};

export default languageUtil;
