// todo Remove below
/* eslint-disable no-mixed-operators */

import ScratchBlocks from 'scratch-blocks';
import commonStyleDefine from '../common/style';
import buildImgUrl from '../utils/build-img-url';

const BlockSvg = ScratchBlocks.BlockSvg;
const definedStyle = commonStyleDefine();
const customRenderObj = {
    control_stop_all_scripts: blockSvg => {
        blockSvg
            .getSvgRoot()
            .children[0].setAttribute(
                'd',
                'm0 4 4-4h20l8 8h16l8-8h20l4 4v34c0 50-80 50-80 0l0-27z'
            );
    }
};

export default {
    ...definedStyle,
    init() {
        const SIN_30 = Math.sin(Math.PI / 6);
        const SIN_60 = Math.sin(Math.PI / 3);
        const TAN_60 = Math.tan(Math.PI / 3);

        ScratchBlocks.Flyout.prototype.MARGIN = 32;

        BlockSvg.MIN_BLOCK_X = 18 * BlockSvg.GRID_UNIT;
        BlockSvg.MIN_BLOCK_Y = 18 * BlockSvg.GRID_UNIT;
        BlockSvg.SEP_SPACE_X = 4 * ScratchBlocks.BlockSvg.GRID_UNIT;
        BlockSvg.MIN_BLOCK_X_OUTPUT = 16 * BlockSvg.GRID_UNIT;
        BlockSvg.MIN_BLOCK_X_WITH_STATEMENT = 24 * BlockSvg.GRID_UNIT;
        BlockSvg.EXTRA_STATEMENT_ROW_Y = 12 * BlockSvg.GRID_UNIT;
        // BlockSvg.MIN_BLOCK_Y_SINGLE_FIELD_OUTPUT = 14 * BlockSvg.GRID_UNIT;
        BlockSvg.MIN_BLOCK_Y_REPORTER = 14 * BlockSvg.GRID_UNIT;
        BlockSvg.MIN_STATEMENT_INPUT_HEIGHT = 10 * BlockSvg.GRID_UNIT;
        // BlockSvg.EXTRA_STATEMENT_ROW_Y = 33 * BlockSvg.GRID_UNIT;
        // BlockSvg.STATEMENT_INPUT_EDGE_WIDTH = 30 * BlockSvg.GRID_UNIT;

        BlockSvg._HEXAGON_SIDE_LENGTH = 54;
        BlockSvg._INNER_HEXAGON_SIDE_LENGTH = 26;
        BlockSvg._ROUND_TRIANGLE_SIDE_LENGTH = 16;
        BlockSvg._HAT_HEIGHT = 80;

        BlockSvg.START_HAT_PATH = `
                M ${BlockSvg.CORNER_RADIUS} ${BlockSvg._HAT_HEIGHT -
            BlockSvg._NORTH_HEIGHT}
                l -${(BlockSvg._HEXAGON_SIDE_LENGTH * SIN_60) /
                    2} -${(BlockSvg._HEXAGON_SIDE_LENGTH * SIN_30) / 2}
                v -${BlockSvg._HEXAGON_SIDE_LENGTH}
                l ${BlockSvg._HEXAGON_SIDE_LENGTH *
                    SIN_60} -${BlockSvg._HEXAGON_SIDE_LENGTH * SIN_30}
                L ${((BlockSvg._HEXAGON_SIDE_LENGTH +
                    BlockSvg._HEXAGON_SIDE_LENGTH -
                    (BlockSvg._HAT_HEIGHT - BlockSvg._NORTH_HEIGHT)) *
                    TAN_60) +
                    BlockSvg.CORNER_RADIUS} 0
            `;
    },
    getHatImageTranslate(width, height) {
        return [
            BlockSvg.CORNER_RADIUS,
            BlockSvg._HAT_HEIGHT -
                BlockSvg.NOTCH_HEIGHT -
                height -
                (((((BlockSvg._HEXAGON_SIDE_LENGTH * 3) / 2) - height) / 2) * 1.2)
        ];
    },
    buildStatementTexturePath(target, width, height) {
        return `
            <path fill="${target.getColourTertiary()}" d="${this.getBottomPath(
            target,
            width,
            height
        ) + this.getRoundPath(target, width, height)}"/>
            <path fill="none" stroke="${target.getColourTertiary()}" stroke-width="1" d="${this.getBottomBranchPath(
            target,
            width,
            height
        ) + this.getLeftBranchPath(target, width, height)}" />
        `;
    },
    buildHatTexturePath(target, width, height) {
        const type = target.type;
        let texturePath = '';
        switch (type) {
            case 'event_whenflagclicked':
                texturePath += `
                    <image transform="translate(${this.getHatImageTranslate(
                        44,
                        51
                    ).join(' ')})" width="44" height="51"
                        xlink:href="${buildImgUrl(type, 'png')}" />
                `;
                break;
            case 'event_whenkeypressed':
                texturePath += `
                    <image transform="translate(${this.getHatImageTranslate(
                        60,
                        44
                    ).join(' ')})" width="60" height="50"
                        xlink:href="${buildImgUrl(type, 'png')}" />
                `;
                break;
            case 'event_whentouchingtargetobject':
                texturePath += `
                    <image transform="translate(${this.getHatImageTranslate(
                        60,
                        44
                    ).join(' ')})" width="60" height="50"
                        xlink:href="${buildImgUrl(
                            'event_whentouchingotherobjects',
                            'png'
                        )}" />
                `;
                break;
            case 'event_whenthisspriteclicked':
                texturePath += `
                    <image transform="translate(${this.getHatImageTranslate(
                        55,
                        47
                    ).join(' ')})" width="55" height="47"
                        xlink:href="${buildImgUrl(type, 'png')}" />
                `;
                break;
            case 'event_whenbroadcastreceived':
                texturePath += `
                    <image transform="translate(${this.getHatImageTranslate(
                        41,
                        48
                    ).join(' ')})" width="41" height="48"
                        xlink:href="${buildImgUrl(type)}" />
                `;
                break;
            case 'event_whentouchingotherobjects':
                texturePath += `
                    <image transform="translate(${this.getHatImageTranslate(
                        61,
                        38
                    ).join(' ')})" width="61" height="38"
                        xlink:href="${buildImgUrl(type, 'png')}" />
                `;
                break;
            case 'event_whenstageclicked':
                texturePath += `
                    <image transform="translate(${this.getHatImageTranslate(
                        43,
                        34
                    ).join(' ')})" width="43" height="34"
                        xlink:href="${buildImgUrl(type)}" />
                `;
                break;
            case 'event_whenstagetouched':
                texturePath += `
                    <image transform="translate(${this.getHatImageTranslate(
                        43,
                        34
                    ).join(' ')})" width="43" height="34"
                        xlink:href="${buildImgUrl(type, 'png')}" />
                `;
                break;
            default:
                texturePath += '';
        }

        texturePath += `
            <path fill="${target.getColourTertiary()}" d="${this.getHatBottomPath(
            target,
            width,
            height
        ) + this.getRoundPath(target, width, height)}" />
            <path fill="none" stroke="${target.getColourTertiary()}" stroke-width="1" d="${this.getHatBottomBranchPath(
            target,
            width,
            height
        )}" />
        `;

        return texturePath;
    },
    buildBooleanTexturePath(target, width, height) {
        return `
            <path fill="${target.getColourTertiary()}" d="${this.getBottomPolygonPath(
            target,
            width,
            height
        )}" />
        `;
    },
    buildNumberStringTexturePath(target, width, height) {
        return `
            <path fill="${target.getColourTertiary()}" d="${this.getBottomRoundPath(
            target,
            width,
            height
        )}" />
        `;
    },
    buildEndTexturePath(target, width, height) {
        return `
            <path fill="${target.getColourTertiary()}" d="${this.getShapeEndBottomPath(
            target,
            width,
            height
        ) + this.getRoundPath(target, width, height)}" />
            <path
                fill="none"
                stroke="${target.getColourTertiary()}"
                stroke-width="1"
                d="${this.getShapeEndBottomBranchPath(target, width, height) +
                    this.getShapeEndLeftBranchPath(target, width, height)}"
            />
        `;
    },
    customRenderObj
};
