import {defineMessages} from 'react-intl';
import sharedMessages from '../shared-messages';

let messages = defineMessages({
    meow: {
        defaultMessage: 'Meow',
        description: 'Name for the meow sound',
        id: 'gui.defaultProject.meow'
    },
    variable: {
        defaultMessage: 'my variable',
        description: 'Name for the default variable',
        id: 'gui.defaultProject.variable'
    }
});

messages = {...messages, ...sharedMessages};

// use the default message if a translation function is not passed
const defaultTranslator = msgObj => msgObj.defaultMessage;

/**
 * Generate a localized version of the default project
 * @param {function} translateFunction a function to use for translating the default names
 * @return {object} the project data json for the default project
 */
const projectData = translateFunction => {
    const translator = translateFunction || defaultTranslator;
    return ({
        targets: [
            {
                isStage: true,
                name: 'Stage',
                variables: {
                    '`jEk@4|i[#Fk?(8x)AV.-my variable': [
                        translator(messages.variable),
                        0
                    ]
                },
                lists: {},
                broadcasts: {},
                blocks: {},
                currentCostume: 0,
                costumes: [
                    {
                        assetId: 'cd21514d0531fdffb22204e0ec5ed84a',
                        name: translator(messages.backdrop, {index: 1}),
                        md5ext: 'cd21514d0531fdffb22204e0ec5ed84a.svg',
                        dataFormat: 'svg',
                        bitmapResolution: 1,
                        rotationCenterX: 1,
                        rotationCenterY: 1
                    }
                ],
                sounds: [
                    {
                        assetId: '83a9787d4cb6f3b7632b4ddfebf74367',
                        name: translator(messages.pop),
                        dataFormat: 'wav',
                        format: '',
                        rate: 11025,
                        sampleCount: 258,
                        md5ext: '83a9787d4cb6f3b7632b4ddfebf74367.wav'
                    }
                ],
                volume: 100
            },
            {
                isStage: false,
                name: translator(messages.sprite, {index: 1}),
                variables: {},
                lists: {},
                broadcasts: {},
                /*
                blocks: {},
                currentCostume: 0,
                costumes: [
                    {
                        assetId: 'b7853f557e4426412e64bb3da6531a99',
                        name: translator(messages.costume, {index: 1}),
                        bitmapResolution: 1,
                        md5ext: 'b7853f557e4426412e64bb3da6531a99.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 48,
                        rotationCenterY: 50
                    },
                    {
                        assetId: 'e6ddc55a6ddd9cc9d84fe0b4c21e016f',
                        name: translator(messages.costume, {index: 2}),
                        bitmapResolution: 1,
                        md5ext: 'e6ddc55a6ddd9cc9d84fe0b4c21e016f.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 46,
                        rotationCenterY: 53
                    }
                ],
                sounds: [
                    {
                        assetId: '83c36d806dc92327b9e7049a565c6bff',
                        name: translator(messages.meow),
                        dataFormat: 'wav',
                        format: '',
                        rate: 22050,
                        sampleCount: 18688,
                        md5ext: '83c36d806dc92327b9e7049a565c6bff.wav'
                    }
                ],
                */
                blocks: {
                    'UU]*bd?|{dw?m%_m-enJ': {
                        opcode: 'event_whenflagclicked',
                        next: 'T))W=LJq,[E,i_0^@`Uo',
                        parent: null,
                        inputs: {},
                        fields: {},
                        topLevel: true,
                        shadow: false,
                        x: 46,
                        y: 158
                    },
                    'T))W=LJq,[E,i_0^@`Uo': {
                        opcode: 'control_forever',
                        next: null,
                        parent: 'UU]*bd?|{dw?m%_m-enJ',
                        inputs: {
                            SUBSTACK: [
                                2,
                                '%@87iWl}wr:w!EeKq-U_'
                            ]
                        },
                        fields: {},
                        topLevel: false,
                        shadow: false
                    },
                    '%@87iWl}wr:w!EeKq-U_': {
                        opcode: 'looks_nextcostume',
                        next: 'On$3n%Q,yX.c@ZUg:V`O',
                        parent: 'T))W=LJq,[E,i_0^@`Uo',
                        inputs: {},
                        fields: {},
                        topLevel: false,
                        shadow: false
                    },
                    'On$3n%Q,yX.c@ZUg:V`O': {
                        opcode: 'control_wait',
                        next: null,
                        parent: '%@87iWl}wr:w!EeKq-U_',
                        inputs: {
                            DURATION: [
                                1,
                                [
                                    5,
                                    '0.1'
                                ]
                            ]
                        },
                        fields: {},
                        topLevel: false,
                        shadow: false
                    }
                },
                currentCostume: 0,
                costumes: [
                    {
                        assetId: 'e6b90f61e8c1e9a3cddd9aaab9e90ebe',
                        name: translator(messages.costume, {index: 1}),
                        bitmapResolution: 2,
                        md5ext: 'e6b90f61e8c1e9a3cddd9aaab9e90ebe.png',
                        dataFormat: 'png',
                        rotationCenterX: 257.5,
                        rotationCenterY: 280
                    },
                    {
                        assetId: '79d2c4614c2f5b51444e771a88f91907',
                        name: translator(messages.costume, {index: 2}),
                        bitmapResolution: 2,
                        md5ext: '79d2c4614c2f5b51444e771a88f91907.png',
                        dataFormat: 'png',
                        rotationCenterX: 257.5,
                        rotationCenterY: 280
                    },

                    {
                        assetId: '926cf51490a9342b0000cf3fc0b728b4',
                        name: translator(messages.costume, {index: 3}),
                        bitmapResolution: 2,
                        md5ext: '926cf51490a9342b0000cf3fc0b728b4.png',
                        dataFormat: 'png',
                        rotationCenterX: 257.5,
                        rotationCenterY: 280
                    },
                    {
                        assetId: '0d1717253c78571fa79fbda930b3ef13',
                        name: translator(messages.costume, {index: 4}),
                        bitmapResolution: 2,
                        md5ext: '0d1717253c78571fa79fbda930b3ef13.png',
                        dataFormat: 'png',
                        rotationCenterX: 257.5,
                        rotationCenterY: 280
                    },
                    {
                        assetId: 'b254724d6764684c021c44a9be9850d7',
                        name: translator(messages.costume, {index: 5}),
                        bitmapResolution: 2,
                        md5ext: 'b254724d6764684c021c44a9be9850d7.png',
                        dataFormat: 'png',
                        rotationCenterX: 257.5,
                        rotationCenterY: 280
                    },
                    {
                        assetId: '2b8d5bab05dd06a326ab272cd31b91b1',
                        name: translator(messages.costume, {index: 6}),
                        bitmapResolution: 2,
                        md5ext: '2b8d5bab05dd06a326ab272cd31b91b1.png',
                        dataFormat: 'png',
                        rotationCenterX: 257.5,
                        rotationCenterY: 280
                    },
                    {
                        assetId: '9c1bf72af3ae8489b8369cc4ce660c4c',
                        name: translator(messages.costume, {index: 7}),
                        bitmapResolution: 2,
                        md5ext: '9c1bf72af3ae8489b8369cc4ce660c4c.png',
                        dataFormat: 'png',
                        rotationCenterX: 257.5,
                        rotationCenterY: 280
                    },
                    {
                        assetId: '33910ab40a14d2ccf900bdede21f6ddf',
                        name: translator(messages.costume, {index: 8}),
                        bitmapResolution: 2,
                        md5ext: '33910ab40a14d2ccf900bdede21f6ddf.png',
                        dataFormat: 'png',
                        rotationCenterX: 257.5,
                        rotationCenterY: 280
                    }
                ],
                sounds: [],
                volume: 100,
                visible: true,
                x: 0,
                y: 0,
                size: 100,
                direction: 90,
                draggable: false,
                rotationStyle: 'all around'
            }
        ],
        meta: {
            semver: '3.0.0',
            vm: '0.1.0',
            agent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/65.0.3325.181 Safari/537.36' // eslint-disable-line max-len
        }
    });
};


export default projectData;
