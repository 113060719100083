import {addLocaleData} from 'react-intl';

// 官方的l10n某些系统缺失字体报错，解决该问题，则本地自己修改，且优化体积
// "scratch-l10n": "3.5.20190827223456",
// 因为它有依赖，所以在package.json文件保留了它的引用，这样保证了它相关的依赖能够下载


// import {localeData} from 'scratch-l10n';
import {localeData} from '../l10n';

// import editorMessages from 'scratch-l10n/locales/editor-msgs';

import editorMessages from '../l10n/locales/editor-msgs';

// import {isRtl} from 'scratch-l10n';

import {isRtl} from '../l10n';
import GeneralConfig from 'PublicRepo/utility/generalConfig'; //eslint-disable-line
import languageUtil from '../lib/lang-utils';

addLocaleData(localeData);

const UPDATE_LOCALES = 'scratch-gui/locales/UPDATE_LOCALES';
const SELECT_LOCALE = 'scratch-gui/locales/SELECT_LOCALE';

const customLangsTranslation = {
    'en': 'Code Project',
    'zh-cn': '编程作品'
};

Object.keys(editorMessages).forEach(lang => {
    editorMessages[lang]['gui.gui.defaultProjectTitle'] = customLangsTranslation[lang];
});

const initialState = {
    isRtl: false,
    locale: 'en',
    messagesByLocale: editorMessages,
    messages: editorMessages.en
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SELECT_LOCALE:
        return Object.assign({}, state, {
            isRtl: isRtl(action.locale),
            locale: action.locale,
            messagesByLocale: state.messagesByLocale,
            messages: state.messagesByLocale[action.locale]
        });
    case UPDATE_LOCALES:
        return Object.assign({}, state, {
            isRtl: state.isRtl,
            locale: state.locale,
            messagesByLocale: action.messagesByLocale,
            messages: action.messagesByLocale[state.locale]
        });
    default:
        return state;
    }
};

const selectLocale = function (locale) {
    languageUtil.setLocale(locale, {
        domain: GeneralConfig.cookieDomain
    });
    return {
        type: SELECT_LOCALE,
        locale: locale
    };
};

const setLocales = function (localesMessages) {
    return {
        type: UPDATE_LOCALES,
        messagesByLocale: localesMessages
    };
};
const initLocale = function (currentState, locale) {
    if (currentState.messagesByLocale.hasOwnProperty(locale)) {
        return Object.assign(
            {},
            currentState,
            {
                isRtl: isRtl(locale),
                locale: locale,
                messagesByLocale: currentState.messagesByLocale,
                messages: currentState.messagesByLocale[locale]
            }
        );
    }
    // don't change locale if it's not in the current messages
    return currentState;
};
export {
    reducer as default,
    initialState as localesInitialState,
    initLocale,
    selectLocale,
    setLocales
};
