import extendCostumeLibraryContent from './extend/costumes.json';
import extendBackdropLibraryContent from './extend/backdrops.json';
import extendSoundLibraryContent from './extend/sounds.json';
import extendSpriteLibraryContent from './extend/sprites.json';

import costumeLibraryContent from './costumes.json';
import backdropLibraryContent from './backdrops.json';
import soundLibraryContent from './sounds.json';
import spriteLibraryContent from './sprites.json';

// Commented func below is used to find out png with bitmapResolution 1.
// a.filter(c=>c.md5.lastIndexOf('png') !== -1).filter(c=>c.info[2]=== 1)

const transformSprite2To3 = function (sb2Sprite) {
    const json = sb2Sprite.json;
    const jsonTemp = {};

    if (json.penLayerMD5) {
        console.warn('Stage found! Skip it!'); // eslint-disable-line no-console
        return;
    }

    jsonTemp.name = json.objName;
    jsonTemp.isStage = false;

    jsonTemp.variables = {};
    jsonTemp.blocks = {};
    jsonTemp.costumes = (json.costumes || []).map(costume => {
        if (costume.md5ext) {
            return costume;
        }

        const [assetId, dataFormat] = costume.baseLayerMD5.split('.');
        return {
            name: costume.costumeName,
            assetId,
            dataFormat,
            md5ext: costume.baseLayerMD5,
            bitmapResolution: costume.bitmapResolution,
            rotationCenterX: costume.rotationCenterX,
            rotationCenterY: costume.rotationCenterY
        };
    });
    jsonTemp.sounds = (json.sounds || []).map(sound => {
        if (sound.md5ext) {
            return sound;
        }

        const [assetId, dataFormat] = sound.md5.split('.');
        return {
            name: sound.soundName,
            assetId,
            dataFormat,
            md5ext: sound.md5,
            rate: sound.rate,
            sampleCount: sound.sampleCount,
            format: sound.format
        };
    });
    jsonTemp.x = json.scratchX;
    jsonTemp.y = json.scratchY;
    jsonTemp.visible = json.visible;
    jsonTemp.size = json.scale * 100;
    jsonTemp.direction = json.direction;
    jsonTemp.draggable = json.isDraggable;
    jsonTemp.rotationStyle = 'all around';
    jsonTemp.currentCostume = json.currentCostumeIndex;

    sb2Sprite.json = jsonTemp;
};

spriteLibraryContent.forEach(transformSprite2To3);

// Temporarily remove this costume for it's rotationCenter is wrong.
// And if fix this bug, it would modify several places.
// So by now just remove it.
for (let i = costumeLibraryContent.length - 1; i >= 0; i--) {
    if (costumeLibraryContent[i].name === 'Trampoline') {
        costumeLibraryContent.splice(i, 1);
    }
}

backdropLibraryContent.unshift.apply(backdropLibraryContent, extendBackdropLibraryContent);
costumeLibraryContent.unshift.apply(costumeLibraryContent, extendCostumeLibraryContent);
soundLibraryContent.unshift.apply(soundLibraryContent, extendSoundLibraryContent);
spriteLibraryContent.unshift.apply(spriteLibraryContent, extendSpriteLibraryContent);
