import React, { Component } from 'react';
import PropTypes from 'prop-types';

import i18n from 'PublicRepo/i18n/common';
import UserData from 'PublicRepo/utility/userDataManager';
import NormalSignIn from './normalSignIn';
import WechatSignIn from './wechatSignIn';
import OrgTeacherSignIn from './orgTeacherSignIn/orgTeacherSignIn';

const TAB_INDEX = {
  QRCODE: 1,
  NORMAL: 2,
};

class SignIn extends Component {
  static propTypes = {
    onLoginSuccess: PropTypes.func,
    onSignIn: PropTypes.func.isRequired,
    onToggleAccountState: PropTypes.func,
    onClose: PropTypes.func,
    onWechatSignIn: PropTypes.func,
    unbindWechat: PropTypes.bool,
    showAccount: PropTypes.bool,
    showCloseIcon: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentTab: TAB_INDEX.QRCODE,
      url: '',
      ticket: '',
      isLoading: true,
      unbindUrl: '',
      isShowOrgTeacherSignIn: false,
      activatedTeacher: false,
    };
    this.renderContent = this.renderContent.bind(this);
    this.onGetWechatQrcode = this.onGetWechatQrcode.bind(this);
    this.handleSetUnbindUrl = this.handleSetUnbindUrl.bind(this);
  }

  componentDidMount() {
    this.setCurrentTab(TAB_INDEX.QRCODE);
  }

  componentWillUnmount() {
    this.clearQrCodeTimer();
  }

  handleSetUnbindUrl(url) {
    this.setState({
      unbindUrl: url,
    });
  }

  setCurrentTab(index) {
    if (this.props.unbindWechat || this.state.activatedTeacher) return;

    this.clearQrCodeTimer();
    this.setState({
      currentTab: index,
    });
  }

  clearQrCodeTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  onGetWechatQrcode(typeIndex) {
    return UserData.getWechatQrcode()
      .then(data => {
        this.setState(
          {
            url: data.url,
            ticket: data.ticket,
          },
          () => {
            this.setState({
              isLoading: false,
            });
          },
        );
        return data;
      })
      .catch(() => {
        this.timer = setTimeout(() => {
          this.props.showAccount
            && this.state.currentTab === typeIndex
            && this.onGetWechatQrcode(typeIndex);
        }, 1000);
      });
  }

  onWechatSignInQrcode = () => {
    this.onGetWechatQrcode(TAB_INDEX.QRCODE);
  };

  onNormalSignInQrcode = () => this.onGetWechatQrcode(TAB_INDEX.NORMAL);

  handleShowOrgTeacherSignIn = data => {
    this.setState({
      isShowOrgTeacherSignIn: true,
      orgTeacherSignInData: data,
    });
  };

  handleSignInSucceed = () => {
    UserData.emitter.emit('update_profile');
    window.location.reload();
  };

  handleClosePopup = () => {
    this.props.onClose();
    this.setState({
      isShowOrgTeacherSignIn: false,
    });
  };

  lockedWechatLogin = () => {
    this.setCurrentTab(TAB_INDEX.QRCODE);
    this.setState({
      activatedTeacher: true,
    });
  };

  renderContent() {
    return this.state.currentTab === TAB_INDEX.QRCODE ? (
      <WechatSignIn
        data={this.state}
        onGetWechatQrcode={this.onWechatSignInQrcode}
        showOrgTeacherSignIn={this.handleShowOrgTeacherSignIn}
        onWechatSignIn={this.props.onWechatSignIn}
        activatedTeacher={this.state.activatedTeacher}
      />
    ) : (
      <NormalSignIn
        data={this.state}
        onClose={this.props.onClose}
        onSetUnbindUrl={this.handleSetUnbindUrl}
        onToggleAccountState={this.props.onToggleAccountState}
        unbindWechat={this.props.unbindWechat}
        onGetWechatQrcode={this.onNormalSignInQrcode}
        onSignIn={this.props.onSignIn}
        onLoginSuccess={this.props.onLoginSuccess}
        onWechatSignIn={this.props.onWechatSignIn}
        onLockedWechatLogin={this.lockedWechatLogin}
      />
    );
  }

  render() {
    return (
      <div>
        <div className="dialog-log-in">
          {this.props.showCloseIcon && (
            <div className="close-button" onClick={this.props.onClose} />
          )}
          <div className="tab-ct">
            <div
              className={`tab-item ${
                this.state.currentTab === TAB_INDEX.QRCODE ? 'currentTab' : ''
              }`}
              onClick={() => this.setCurrentTab(TAB_INDEX.QRCODE)}
            >
              {i18n.get('Login-Scan to login')}
            </div>
            <div
              className={`tab-item ${
                this.state.currentTab === TAB_INDEX.NORMAL ? 'currentTab' : ''
              }`}
              onClick={() => this.setCurrentTab(TAB_INDEX.NORMAL)}
            >
              {i18n.get('Login-Normal login')}
            </div>
          </div>
          {this.renderContent()}
        </div>
        {this.state.isShowOrgTeacherSignIn && (
          <OrgTeacherSignIn
            orgTeacherSignInData={this.state.orgTeacherSignInData}
            signInSucceed={this.handleSignInSucceed}
            closePopup={this.handleClosePopup}
          />
        )}
      </div>
    );
  }
}

export default SignIn;
