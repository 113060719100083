import GeneralConfig from '../utility/generalConfig';
import Fetch from '../lib/fetch';

const {
  getClassInfo,
  getLessonInfo,
  getPackageInfo,
  getClassListByTemporaryToken,
  getStudentListByTemporaryToken,
  getUserTokenByTemporaryToken,
} = GeneralConfig.serverIo;

const classRelatedInfo = {
  getClassInfo: classId => Fetch({
      url: getClassInfo,
      method: 'get',
      params: {
        id: classId,
      },
    }),
  getLessonInfo: lessonId => Fetch({
      url: getLessonInfo,
      method: 'get',
      params: {
        lesson_id: lessonId,
      },
    }),
  getPackageInfo: packageId => Fetch({
      url: getPackageInfo,
      method: 'get',
      params: {
        package_id: packageId,
      },
    }),
  // 通过临时token获取班级列表
  getClassListByTemporaryToken: (
    authorization_token,
    page,
    page_size,
    class_name,
  ) => Fetch({
      url: getClassListByTemporaryToken,
      method: 'get',
      params: {
        authorization_token,
        page,
        page_size,
        class_name,
      },
    }),
  // 通过临时token获取学生列表
  getStudentListByTemporaryToken: (
    authorization_token,
    class_id,
    page,
    page_size,
  ) => Fetch({
      url: getStudentListByTemporaryToken,
      method: 'get',
      params: {
        authorization_token,
        class_id,
        page,
        page_size,
      },
    }),
  // 通过临时token获取指定学生/老师的登录token
  getUserTokenByTemporaryToken: (authorization_token, user_id, class_id = '') => Fetch({
      url: getUserTokenByTemporaryToken,
      method: 'get',
      params: {
        authorization_token,
        user_id,
        class_id,
      },
    }),
};
export default classRelatedInfo;
