import {loadExtension} from '../../cm/lib/blocks/blocks';

/**
 * Get global env ref.
 * @return {any} Global env ref.
 */
const getGlobalEnv = () => {
    const isInBrowser = typeof window !== 'undefined';
    const isInNode = typeof global !== 'undefined';

    return isInBrowser ? window : isInNode ? global : null;
};

/**
 * Inject var in global env.
 * @param {VirtualMachine} vm VirtualMachine instance.
 */
export default function injectGlobalVar ({vm}) {
    const env = getGlobalEnv();
    if (!env) return;

    env.vm = vm;
    env.loadExtension = loadExtension;
}
