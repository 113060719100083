import juniorStyle from './style';
import juniorColour from './colour';
import juniorCustom from './custom';
import juniorExtensionCustom from './extension-custom';

export const extension = {
    extensionCustom: juniorExtensionCustom
};

export default {
    style: juniorStyle,
    colour: juniorColour,
    custom: juniorCustom
};
