import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserData from 'PublicRepo/utility/userDataManager';
import i18n from 'PublicRepo/i18n/common';
import MDCircularProgress from 'PublicRepo/material_components/loading';
import SnackBar from 'PublicRepo/components/Material/snackBar';
import Config from 'PublicRepo/utility/generalConfig';

class NormalSignIn extends Component {
  static propTypes = {
    onSignIn: PropTypes.func.isRequired,
    onLoginSuccess: PropTypes.func,
    onClose: PropTypes.func,
    onLockedWechatLogin: PropTypes.func,
    unbindWechat: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isUsernameErr: false,
      isPasswordErr: false,
      isAuthFailed: false,
      errText: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.normalDom = this.normalDom.bind(this);
    this.qrcodeDom = this.qrcodeDom.bind(this);
    this.ToggleAccountState = this.ToggleAccountState.bind(this);
  }

  componentDidMount() {
    this.usernameInput.focus();
  }

  componentWillUnmount() {
    clearInterval(this.checkClock);
    clearTimeout(this.timeClock);
  }

  handleSignIn() {
    this.props
      .onSignIn({
        username: this.usernameInput.value,
        passwd: this.passwordInput.value,
      })
      .then(data => {
        if (!data) return;

        switch (data.loginType) {
          case Config.loginState.ACTIVATED_TEACHER: {
            return this.props.onLockedWechatLogin();
          }

          case Config.loginState.ACCOUNT_ERROR: {
            return this.setState({
              errText: i18n.get('Username or password is wrong'),
            });
          }

          case Config.loginState.GENERAL_ERROR: {
            return this.setState({
              errText: i18n.get('Username or password is wrong'),
            });
          }
          case Config.loginState.NOTBELONG: {
            // 此用户不是该域名下的用户，使用临时token再次登录
            const temporary_token = data.info && data.info.token;
            UserData.loginByToken(temporary_token).then(() => {
              UserData.initUserInfo().then(userInfo => this.props.onLoginSuccess(userInfo));
            });
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  handleValidInput() {
    const username = this.usernameInput.value;
    const password = this.usernameInput.value;

    if (!username) {
      this.setState({
        isUsernameErr: true,
        errText: i18n.get('Username should not be null'),
      });
      return false;
    }

    if (!password) {
      this.setState({
        isPasswordErr: true,
        errText: i18n.get('Password should not be null'),
      });
      return false;
    }

    return true;
  }

  handleChange() {
    if (this.state.isUsernameErr || this.state.isPasswordErr) {
      this.setState({
        isUsernameErr: false,
        isPasswordErr: false,
        errText: '',
      });
    }
  }

  handleKeyPress(e) {
    const key = e.which || e.keyCode;

    if (key === 13) {
      if (this.handleValidInput) {
        this.handleSignIn();
      }
    }
  }

  ToggleAccountState() {
    window.location.reload();
  }

  snackerBarDom() {
    return <SnackBar ref={mdc => (this.mdc = mdc)} />;
  }

  normalDom() {
    return (
      <div className="con">
        <p className="tip error-tip">{this.state.errText}</p>
        <div
          className={`username  ${
            this.state.isUsernameErr ? 'error-input' : ''
          }`}
        >
          <input
            ref={input => (this.usernameInput = input)}
            type="text"
            className="input-username"
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            placeholder={i18n.get('Username')}
          />
        </div>
        <div
          className={`pwd  ${this.state.isPasswordErr ? 'error-input' : ''}`}
        >
          <input
            ref={input => (this.passwordInput = input)}
            type="password"
            className="input-pwd"
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            placeholder={i18n.get('Password')}
          />
        </div>
        <a
          role="button"
          tabIndex="0"
          className="btn-enter"
          onClick={this.handleSignIn}
        >
          {i18n.get('Enter')}
        </a>
      </div>
    );
  }

  qrcodeDom() {
    return (
      <div className="con">
        <div className="unbind-wechat-qrcode-ct">
          <div className="unbind-wechat-qrcode">
            {this.props.data.unbindUrl ? (
              <img
                className="wechat-qrcode-img"
                src={this.props.data.unbindUrl}
                alt=""
              />
            ) : (
              <div className="qrcode-loading-ct">
                <MDCircularProgress size={60} style={{ color: '#8a53aa' }} />
              </div>
            )}
          </div>
          <p className="unbind-wechat-text">
            {i18n.get('Login-Welcome to using bellcode')}
          </p>
          <p className="unbind-wechat-text">
            {i18n.get('Login-Use WeChat scan to bind bellcode')}
          </p>
          <div className="unbind-wechat-skip" onClick={this.props.onClose}>
            {i18n.get('Login-Skip')}
            &#62;
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div className="normal-content">
        {this.props.unbindWechat ? this.qrcodeDom() : this.normalDom()}
        {this.snackerBarDom()}
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

export default NormalSignIn;
