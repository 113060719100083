import React from 'react';
import PropTypes from 'prop-types';
import {intlShape, injectIntl} from 'react-intl';
import bindAll from 'lodash.bindall';
import {connect} from 'react-redux';
import VM from 'scratch-vm';

import {setProjectUnchanged} from '../reducers/project-changed';
import {
    defaultProjectId,
    LoadingStates,
    getIsCreatingNew,
    getIsFetchingWithId,
    getIsLoading,
    getIsShowingProject,
    onFetchedProjectData,
    projectError,
    setProjectId
} from '../reducers/project-state';
import {
    updateProjectOwnerId
} from '../reducers/project-meta';
import {
    activateTab,
    BLOCKS_TAB_INDEX
} from '../reducers/editor-tab';

import log from './log';
import storage from './storage';
import {setProjectTitle} from '../reducers/project-title';
import {getProjectInfo} from '../lib/project-utils';

/* Higher Order Component to provide behavior for loading projects by id. If
 * there's no id, the default project is loaded.
 * @param {React.Component} WrappedComponent component to receive projectData prop
 * @returns {React.Component} component with project loading behavior
 */
const ProjectFetcherHOC = function (WrappedComponent) {
    class ProjectFetcherComponent extends React.Component {
        constructor (props) {
            super(props);
            bindAll(this, [
                'fetchProject'
            ]);
            storage.setProjectHost(props.projectHost);
            storage.setAssetHost(props.assetHost);
            storage.setTranslatorFunction(props.intl.formatMessage);
            // props.projectId might be unset, in which case we use our default;
            // or it may be set by an even higher HOC, and passed to us.
            // Either way, we now know what the initial projectId should be, so
            // set it in the redux store.
            if (
                props.projectId !== '' &&
                props.projectId !== null &&
                typeof props.projectId !== 'undefined'
            ) {
                this.props.setProjectId(props.projectId.toString());
            }
        }
        componentDidUpdate (prevProps) {
            if (prevProps.projectHost !== this.props.projectHost) {
                storage.setProjectHost(this.props.projectHost);
            }
            if (prevProps.assetHost !== this.props.assetHost) {
                storage.setAssetHost(this.props.assetHost);
            }
            if (this.props.isFetchingWithId && !prevProps.isFetchingWithId) {
                this.fetchProject(this.props.reduxProjectId, this.props.loadingState);
            }
            if (this.props.isShowingProject && !prevProps.isShowingProject) {
                this.props.onProjectUnchanged();
            }
            if (this.props.isShowingProject && (prevProps.isLoadingProject || prevProps.isCreatingNew)) {
                this.props.onActivateTab(BLOCKS_TAB_INDEX);
            }
        }
        fetchProject (projectId, loadingState) {
            // TODO: wait for backend
            // START
            window._cm_isUsingJSON = false;
            // END

            const _getProjectInfo = () => {
                if (projectId === defaultProjectId) {
                    return Promise.resolve();
                }

                return getProjectInfo(projectId)
                    .then(projectInfo => {
                        if (projectInfo && projectInfo.asset_id) {
                            projectId = projectInfo.asset_id;
                        }

                        return projectInfo;
                    });
            };

            return _getProjectInfo()
                .then(projectInfo => {
                    // TODO: wait for backend
                    // START
                    if (projectInfo && projectInfo.asset_id) {
                        window._cm_isUsingJSON = true;
                    }
                    if (projectInfo && !projectInfo.asset_id) {
                        return storage
                            .load(storage.AssetType.Project, projectId, storage.DataFormat.JSON)
                            .then(projectAsset => {
                                if (projectAsset) {
                                    let projectEnv2 = {};

                                    if (typeof projectAsset.data !== 'string') {
                                        // compact with remote project
                                        const remoteProjectAsset = JSON.parse(projectAsset.decodeText()).data;
                                        const {params, proName: proName2, ...config} = remoteProjectAsset;

                                        projectEnv2 = config;

                                        // FIXME This might be the dirty data bug.
                                        // Need checkout it in SQL.
                                        // 1. Empty targets
                                        // 2. costume without bitmapResolution [cd21514d0531fdffb22204e0ec5ed84a.svg]
                                        {
                                            const parsedParams = JSON.parse(params);
                                            const {targets} = parsedParams;
                                            if (!targets.length) {
                                                throw new Error('Empty Target.');
                                            }

                                            targets.forEach(target => {
                                                if (target.currentCostume >= target.costumes.length) {
                                                    target.currentCostume = target.costumes.length - 1;
                                                }

                                                target.costumes.forEach(costume => {
                                                    if (costume.bitmapResolution) return;

                                                    if (!costume.dataFormat) {
                                                        costume.dataFormat =
                                                            (costume.md5ext || costume.md5).split('.')[1];
                                                    }
                                                    costume.bitmapResolution = costume.dataFormat === 'svg' ? 1 : 2;
                                                });
                                            });

                                            projectAsset.data = JSON.stringify(parsedParams);
                                        }

                                        this.props.onUpdateReduxProjectTitle(proName2);
                                        this.props.onUpdateProjectOwnerId(projectEnv2.owner_id);
                                    }

                                    this.props.onFetchedProjectData(projectAsset.data, loadingState);
                                } else {
                                    // Treat failure to load as an error
                                    // Throw to be caught by catch later on
                                    throw new Error('Could not find project');
                                }
                            });
                    }
                    // END

                    return storage
                        .load(storage.AssetType.Project, projectId, storage.DataFormat.JSON)
                        .then(projectAsset => {
                            // For remote project
                            if (projectAsset && projectId !== defaultProjectId) {
                                const projectData = JSON.parse(projectAsset.decodeText());

                                // FIXME This might be the dirty data bug.
                                // Need checkout it in SQL.
                                // 1. Empty targets
                                // 2. costume without bitmapResolution [cd21514d0531fdffb22204e0ec5ed84a.svg]
                                {
                                    const {targets} = projectData;
                                    if (!targets.length) {
                                        throw new Error('Empty Target.');
                                    }

                                    targets.forEach(target => {
                                        if (target.currentCostume >= target.costumes.length) {
                                            target.currentCostume = target.costumes.length - 1;
                                        }

                                        target.costumes.forEach(costume => {
                                            if (costume.bitmapResolution) return;

                                            if (!costume.dataFormat) {
                                                costume.dataFormat = (costume.md5ext || costume.md5).split('.')[1];
                                            }
                                            costume.bitmapResolution = costume.dataFormat === 'svg' ? 1 : 2;
                                        });
                                    });

                                    projectAsset.data = JSON.stringify(projectData);
                                }

                                this.props.onUpdateReduxProjectTitle(projectInfo.proName);
                                this.props.onUpdateProjectOwnerId(projectInfo.owner_id);
                            }

                            return projectAsset;
                        })
                        .then(projectAsset => {
                            if (projectAsset) {
                                this.props.onFetchedProjectData(projectAsset.data, loadingState);
                            } else {
                                // Treat failure to load as an error
                                // Throw to be caught by catch later on
                                throw new Error('Could not find project');
                            }
                        });
                })
                .catch(err => {
                    // this.props.onError(err);
                    this.props.onFetchedProjectData(
                        this.props.vm.runtime.storage.builtinHelper.get('0').data, loadingState
                    );
                    log.error(err);
                    err.text && alert(`项目载入错误。请联系老师. 复制信息-> ${err}`); // eslint-disable-line
                });
        }
        render () {
            const {
                /* eslint-disable no-unused-vars */
                assetHost,
                intl,
                isLoadingProject: isLoadingProjectProp,
                loadingState,
                onActivateTab,
                onError: onErrorProp,
                onFetchedProjectData: onFetchedProjectDataProp,
                onProjectUnchanged,
                onUpdateProjectOwnerId,
                projectHost,
                projectId,
                reduxProjectId,
                setProjectId: setProjectIdProp,
                /* eslint-enable no-unused-vars */
                isFetchingWithId: isFetchingWithIdProp,
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    fetchingProject={isFetchingWithIdProp}
                    {...componentProps}
                />
            );
        }
    }
    ProjectFetcherComponent.propTypes = {
        assetHost: PropTypes.string,
        canSave: PropTypes.bool,
        intl: intlShape.isRequired,
        isCreatingNew: PropTypes.bool,
        isFetchingWithId: PropTypes.bool,
        isLoadingProject: PropTypes.bool,
        isShowingProject: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        onActivateTab: PropTypes.func,
        onError: PropTypes.func,
        onFetchedProjectData: PropTypes.func,
        onProjectUnchanged: PropTypes.func,
        onUpdateProjectOwnerId: PropTypes.func,
        onUpdateReduxProjectTitle: PropTypes.func,
        projectHost: PropTypes.string,
        projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        reduxProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        setProjectId: PropTypes.func,
        vm: PropTypes.instanceOf(VM)
    };
    ProjectFetcherComponent.defaultProps = {
        assetHost: 'https://assets.scratch.mit.edu',
        projectHost: 'https://projects.scratch.mit.edu'
    };

    const mapStateToProps = state => ({
        isCreatingNew: getIsCreatingNew(state.scratchGui.projectState.loadingState),
        isFetchingWithId: getIsFetchingWithId(state.scratchGui.projectState.loadingState),
        isLoadingProject: getIsLoading(state.scratchGui.projectState.loadingState),
        isShowingProject: getIsShowingProject(state.scratchGui.projectState.loadingState),
        loadingState: state.scratchGui.projectState.loadingState,
        vm: state.scratchGui.vm,
        reduxProjectId: state.scratchGui.projectState.projectId
    });
    const mapDispatchToProps = dispatch => ({
        onActivateTab: tab => dispatch(activateTab(tab)),
        onError: error => dispatch(projectError(error)),
        onFetchedProjectData: (projectData, loadingState) =>
            dispatch(onFetchedProjectData(projectData, loadingState)),
        onUpdateReduxProjectTitle: title => dispatch(setProjectTitle(title)),
        onUpdateProjectOwnerId: ownerId => dispatch(updateProjectOwnerId(ownerId)),
        setProjectId: projectId => dispatch(setProjectId(projectId)),
        onProjectUnchanged: () => dispatch(setProjectUnchanged())
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );
    return injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ProjectFetcherComponent));
};

export {
    ProjectFetcherHOC as default
};
