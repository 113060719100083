/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-bind */
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import {getParams} from '../../lib/env-utils';

import InlineMessages from '../../containers/inline-messages.jsx';

import {
    manualUpdateProject,
    createCloudHomeworkProject,
    changeHomeworkStatus
} from '../../reducers/project-state';

import {
    filterInlineAlerts
} from '../../reducers/alerts';

import styles from './save-status.css';

// Wrapper for inline messages in the nav bar, which are all related to saving.
// Show any inline messages if present, else show the "Save Now" button if the
// project has changed.
// We decided to not use an inline message for "Save Now" because it is a reflection
// of the project state, rather than an event.
const SaveStatus = ({
    alertsList,
    projectChanged,
    onClickSave,
    onCloudHomeworkCreateProject,
    onChangeHomeworkStatus
}) => (
    filterInlineAlerts(alertsList).length > 0 ? (
        <InlineMessages />
    ) : projectChanged && (
        <div
            className={styles.saveNow}
            onClick={() => {
                const {cloud_homework_id} = getParams();
                if (cloud_homework_id) {
                    onChangeHomeworkStatus(true);
                    onCloudHomeworkCreateProject();
                } else {
                    onClickSave();
                }
            }}
        >
            <FormattedMessage
                defaultMessage="Save Now"
                description="Title bar link for saving now"
                id="gui.menuBar.saveNowLink"
            />
        </div>
    ));

SaveStatus.propTypes = {
    alertsList: PropTypes.arrayOf(PropTypes.object),
    onClickSave: PropTypes.func,
    projectChanged: PropTypes.bool,
    onCloudHomeworkCreateProject: PropTypes.func,
    onChangeHomeworkStatus: PropTypes.func
};

const mapStateToProps = state => ({
    alertsList: state.scratchGui.alerts.alertsList,
    projectChanged: state.scratchGui.projectChanged
});

const mapDispatchToProps = dispatch => ({
    onClickSave: () => dispatch(manualUpdateProject()),
    onCloudHomeworkCreateProject: () => dispatch(createCloudHomeworkProject()),
    onChangeHomeworkStatus: status => dispatch(changeHomeworkStatus(status))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaveStatus);
