export default {
    motion: {
        primary: '#4C97FF',
        secondary: '#427EF5',
        tertiary: '#427EF5'
    },
    looks: {
        primary: '#9966FF',
        secondary: '#8855EE',
        tertiary: '#8855EE'
    },
    sounds: {
        primary: '#CF63CF',
        secondary: '#B046B0',
        tertiary: '#B046B0'
    },
    control: {
        primary: '#FF8C1A',
        secondary: '#E77421',
        tertiary: '#E77421'
    },
    event: {
        primary: '#FFBF00',
        secondary: '#EA8F14',
        tertiary: '#EA8F14'
    },
    sensing: {
        primary: '#5CB1D6',
        secondary: '#2497C3',
        tertiary: '#2497C3'
    },
    pen: {
        primary: '#0FBD8C',
        secondary: '#038E67',
        tertiary: '#038E67'
    },
    operators: {
        primary: '#59C059',
        secondary: '#308E46',
        tertiary: '#308E46'
    },
    data: {
        primary: '#FFAB19',
        secondary: '#F28300',
        tertiary: '#F28300'
    }
};
