import React, { Component } from 'react';
// import i18n from 'PublicRepo/i18n/www';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Config from 'PublicRepo/utility/generalConfig';
// import UserData from 'PublicRepo/utility/userDataManager';
import CModel from 'PublicRepo/models/classRelatedInfo';
import Page from '../../Paging';
import UniversalClassList from './universalClassList';
import './assistStuLogin.less';

class AssistStuLogin extends Component {
  static propTypes = {
    stuList: PropTypes.array,
    backToTeacher: PropTypes.func,
    teacherSignIn: PropTypes.func,
  };

  state = {
    isShowConfirm: false,
    popupData: {},
  };

  constructor(props) {
    super(props);
  }

  handleAssistStuLogin = item => {
    this.setState({
      isShowConfirm: true,
      popupData: item,
    });
  };

  hideConfirm = () => {
    this.setState({
      isShowConfirm: false,
      popupData: {},
    });
  };

  confirm = () => {
    const { temporaryToken, stuClassId, handleTokenExpire } = this.props;
    const { popupData } = this.state;
    CModel.getUserTokenByTemporaryToken(
      temporaryToken,
      popupData.id,
      stuClassId,
    )
      .then(data => {
        const { token } = data;
        this.props.teacherSignIn(token);
        this.hideConfirm();
      })
      .catch(err => {
        handleTokenExpire(err);
      });
  };

  render() {
    const {
      stuList,
      backToTeacher,
      stuPageData,
      getSpecifyStuList,
      isLoading,
      customBgColor = 'rgba(0, 174, 246, 0.87)',
      customTextColor = '#FFFFFF',
      customTitleColor = 'rgba(0, 174, 246, 0.87)',
    } = this.props;
    const { popupData, isShowConfirm } = this.state;

    return (
      <div className="assist-stu-login">
        <div className="assist-stu-login-title">
          登录学生账号
          <div
            className="assist-stu-login-title-back"
            onClick={backToTeacher}
          />
        </div>
        <UniversalClassList
          list={stuList}
          isLoading={isLoading}
          listType={Config.loginListTypeMap.STUDENT}
          onAssistStuLogin={this.handleAssistStuLogin}
          customBgColor={customBgColor}
        />
        <Page
          totalPage={get(stuPageData, 'totalPage', 0)}
          curPage={get(stuPageData, 'curPage', 1)}
          goPage={getSpecifyStuList}
          className="pageBottom"
        />

        <div
          className={`assist-stu-login-popup ${isShowConfirm ? 'show' : ''}`}
        >
          <div className="assist-stu-login-popup-content">
            <div
              style={{ color: customTitleColor }}
              className="popup-content-title"
            >
              确认登录学生账号
            </div>
            <ul className="popup-content-list">
              <li>
                姓名：
                {popupData.real_name}
              </li>
              <li>
                账号：
                {popupData.user_name}
              </li>
              <li>
                昵称：
                {popupData.nick_name}
              </li>
            </ul>
            <div className="popup-content-mark">
              *确认后，将向家长发送登录通知
            </div>
            <div className="popup-content-list-button-wrapper">
              <div
                style={{
                  borderColor: customBgColor,
                  color: customBgColor || '#00aef6',
                }}
                className="popup-content-list-button-cancel"
                onClick={this.hideConfirm}
              >
                取消
              </div>
              <div
                style={{
                  backgroundColor: customBgColor,
                  color: customTextColor,
                }}
                className="popup-content-list-button-confirm"
                onClick={this.confirm}
              >
                完成
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssistStuLogin;
