import {parseQueryString, checkOriginIllegal, buildQueryString} from '../public-repo/lib/utilities';
import {getHttpURL} from 'PublicRepo/utility/locationBuilder';

const SearchObjectKeys = [
    'pid',
    'fork',
    'actid',
    'task_id',
    'classid',
    'lessonid',
    'package_id',
    'cburl',
    'teacher_diy_project_id',
    'homework_id',
    'diy_id',
    'diypre',
    'diysub',
    'diyinh',
    'junior',
    'edit',
    'from',
    'cloud_homework_id'
];

const ParamsObjectKeys = [
    'proId',
    'isFork',
    'actId',
    'taskId',
    'classId',
    'lessonId',
    'packageId',
    'cbUrl',
    'teacherDiyProjectId',
    'homeworkId',
    'diyId',
    'diypre',
    'diysub',
    'diyinh',
    'junior',
    'edit',
    'from',
    'cloud_homework_id'
];

const formatSearchObjectToParams = searchObj => {
    const newObj = {};

    // if (!searchObj.pid) return;
    SearchObjectKeys.forEach((key, index) => {
        if (!searchObj[key]) return;

        switch (key) {
        case 'fork':
            newObj[ParamsObjectKeys[index]] = !!searchObj[key];
            break;
        case 'cburl':
            newObj[ParamsObjectKeys[index]] = checkOriginIllegal(searchObj[key]) ?
                searchObj[key] :
                getHttpURL(process.env.HOME_URL);
            break;
        default:
            newObj[ParamsObjectKeys[index]] = searchObj[key];
        }
    });

    return newObj;
};

const formatParamsToSearchObject = paramsObj => {
    const newObj = {};

    ParamsObjectKeys.forEach((key, index) => {
        if (!paramsObj[key]) return;

        switch (key) {
        case 'isFork':
            newObj[SearchObjectKeys[index]] = paramsObj[key] ? '1' : '0';
            break;
        default:
            newObj[SearchObjectKeys[index]] = paramsObj[key];
        }
    });

    return newObj;
};

let isParamsDirty = false;
const params = {};

const getParams = () => Object.assign({}, params);

const updateUrl = e => {
    const hash = location.hash.replace(/^#/, '');
    const queryString = buildQueryString(formatParamsToSearchObject(params));

    history.replaceState(
        (e || history).state,
        '',
        `${queryString.length ? `?${queryString}` : ''}${hash ? `#${hash}` : ''}`
    );
};

/**
 * @param {object} newParams Params is based on search object.
 * @param {boolean} isDirty Normally invoke this func is bcz the url changed.
 *     For syncing the modification to all history url, default is set to true.
 * @return {object} Return params.
 */
const updateParams = (newParams, isDirty = true) => {
    // Object.entries(newParams)
    //     .forEach(([key, value]) => {
    //         if (Reflect.has(params, key)) {
    //             Object.assign(params, {
    //                 [key]: value
    //             });
    //         }
    //     });

    // This would add new param 'proId' in homework url which is not needed.
    // But at the same time, saving a new project, saving diy, need to add new param 'proId'.
    // It seems that homework backend request param should make a modification.
    Object.assign(params, newParams);

    if (isDirty) {
        isParamsDirty = isDirty;
        updateUrl();
    }

    return params;
};

window.addEventListener('popstate', e => {
    if (!isParamsDirty) return;

    updateUrl(e);
});

// Init params
updateParams(formatSearchObjectToParams(parseQueryString(location.search)), false);

export {getParams, updateParams};
