import ScratchBlocks from 'scratch-blocks';

import applyColour from './colour';
import applyStyle from './style';
import applyCustom from './custom';
import applyExtensionCustom from './extension-custom';

const mergeAndApplyColour = (mainDefine, additionalDefine) => {
    const colours = Object.assign({}, mainDefine.colour || {});
    const additionalColours = additionalDefine.colour;

    if (additionalColours) {
        Object.keys(additionalColours).forEach(key => {
            if (!colours[key]) {
                colours[key] = additionalColours[key];
            }
        });
    }

    applyColour(colours);
};

const mergeAndApplyStyle = (mainDefine, additionalDefine) => {
    const styles = Object.assign({}, mainDefine.style || {});
    const additionalStyles = additionalDefine.style;

    if (additionalStyles) {
        Object.keys(additionalStyles).forEach(key => {
            if (!styles[key]) {
                styles[key] = additionalStyles[key];
            }
        });
    }

    applyStyle(styles);
};

const mergeAndApplyCustom = (mainDefine, additionalDefine, vm) => {
    const customs = Object.assign(
        {},
        mainDefine.custom ? mainDefine.custom(vm) : {}
    );

    if (additionalDefine.custom) {
        const additionalCustoms = additionalDefine.custom(vm);

        Object.keys(additionalCustoms).forEach(key => {
            if (!ScratchBlocks.Blocks[key] && !customs[key]) {
                customs[key] = additionalCustoms[key];
            }
        });
    }

    applyCustom(customs, vm);
};

const mergeAndApplyExtensionCustom = (
    mainDefine,
    additionalDefine,
    vm,
    blocksInfo
) => {
    const extensionCustoms = Object.assign(
        {},
        mainDefine.extensionCustom || {}
    );

    Object.keys(extensionCustoms).forEach(name => {
        extensionCustoms[name] = extensionCustoms[name](vm);
    });

    if (additionalDefine.extensionCustom) {
        Object.keys(additionalDefine.extensionCustom).forEach(name => {
            const additionalNameCustoms = additionalDefine.extensionCustom[
                name
            ](vm);

            if (!extensionCustoms[name]) {
                extensionCustoms[name] = additionalNameCustoms;
                return;
            }

            Object.keys(additionalNameCustoms).forEach(key => {
                if (
                    !ScratchBlocks.Blocks[key] &&
                    !extensionCustoms[name][key]
                ) {
                    extensionCustoms[name][key] = additionalNameCustoms[key];
                }
            });
        });
    }

    applyExtensionCustom(extensionCustoms, vm, blocksInfo);
};

/**
 * Merge blocks definitions of different mode then
 * different projectData can run in different mode.
 * @param {[object, object]} args The latter definition would overwrite former if duplicated.
 */
export default function(...args) {
    const [mainDefine, additionalDefine] = args;

    if (mainDefine.colour || additionalDefine.colour) {
        mergeAndApplyColour(...args);
    }

    if (mainDefine.style || additionalDefine.style) {
        mergeAndApplyStyle(...args);
    }

    if (mainDefine.custom || additionalDefine.custom) {
        mergeAndApplyCustom(...args);
    }

    if (mainDefine.extensionCustom || additionalDefine.extensionCustom) {
        mergeAndApplyExtensionCustom(...args);
    }
}
