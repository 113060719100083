/* eslint-disable import/no-mutable-exports */
import { buildJumpURL } from '../locationBuilder';

const IPC_EVENT = {
  enterVideoFullscreen: 'enter-video-fullscreen', // 视频全屏
  exitVideoFullscreen: 'exit-video-fullscreen', // 视频退出全屏
  enterEditor: 'enter-editor', // 进入编辑器
  enterPuzzle: 'enter-puzzle', // 进入闯关
  enterBellPlanet: 'enter-bellplanet', // 进入小贝
  enterTeachingSite: 'enter-teaching-site', // 进入官网
  stopVideo: 'pause-video', // 视频暂停
  playVideo: 'play-video', // 视频播放
  exitVideo: 'stop-video', // 退出视频
  startVideo: 'start-video', // 开始视频
  sendVideoOperation: 'send-video-operation', // 向云课堂发送消息的channel
  listenVideoOperation: 'listen-video-operation', // 监听来自云课堂消息的channel
  sendOperationType: 'send-operation-type', // 向贝尔星球发送操作类型的channel
  clientProcessMessage: 'CLIENT_PROCESS_MESSAGE', // 向贝尔星球发送消息
};

let ipcRenderer = {
  send(...args) {
    console.log('invode mock ipc send', ...args);
  },

  sendSync(...args) {
    console.log('invode mock ipc send', ...args);
  },

  sendToHost(...args) {
    // / 如果是ipad环境则模拟ipad发送消息.
    // / src/PublicRepo/utility/webview-bridge.js
    if (window.wkbridge) {
      window.wkbridge.snedToHost(...args);
      console.log('send message to ipad', ...args);
      return;
    }
    console.log('invode mock ipc send', ...args);
  },

  on(...args) {
    console.log('listen from colud', ...args);
  },

  removeAllListeners(...args) {
    console.log('remove specify listener', ...args);
  },
};

// 对接云课堂ipc
// 这里如果是云课堂内部, 是可以注入elctron的
// web端无法引入。所以使用mock
try {
  /* eslint-disable */
  ipcRenderer = require('electron').ipcRenderer;
} catch (error) {
  try {
    if (require instanceof Function) {
      console.warn(error, 'import electorn error');
    }
  } catch (error) {
    console.warn(error);
  }
}

/**
 *
 * @param {valueof IPC_EVENT} messgae
 */
const PostMessageToHost = messgae => {
  console.log(`send ipc message: ${messgae}`);
  ipcRenderer.sendToHost(messgae, '1');
};

/**
 * 向云课堂发送教师操作消息
 * @param messgae
 */
const SendMessageToCloud = messgae => {
  console.log(`send ipc message to cloud: ${messgae}`);
  ipcRenderer.sendToHost(IPC_EVENT.sendVideoOperation, messgae);
};

/**
 * 向贝尔星球发送操作消息
 * @param messgae
 */
const SendMessageToBellPlanet = message => {
  if (message.url) {
    message.url = buildJumpURL(message.url);
  }
  console.log(`send ipc message to bellplanet: ${message} ${message.url}`);
  ipcRenderer.sendToHost(IPC_EVENT.sendOperationType, message);
};

// 关闭银幕分享
function closeBellCloudShare() {
  ipcRenderer.sendToHost('closeShare', '1');
}

// 开启银幕
function openBellCloudShare() {
  ipcRenderer.sendToHost('startShare', '1');
}

// 开启音频共享
function openVoice() {
  ipcRenderer.sendToHost('openVoice', '1');
}

// 关闭音频共享
function closeVoice() {
  ipcRenderer.sendToHost('closeVoice', '1');
}

/**
 * 窗口是否是全屏
 * @param {boolean} value  //false:窗口模式，true:全屏模式
 */
const switchFullScreen = value => {
  ipcRenderer.send(IPC_EVENT.clientProcessMessage, 'SWITCH_FULL_SCREEN', value);
};

/**
 * 退出客户端
 */
const sendQuitNative = () => {
  ipcRenderer.send(IPC_EVENT.clientProcessMessage, 'QUIT_NATIVE');
};

export {
  IPC_EVENT,
  PostMessageToHost,
  closeBellCloudShare,
  openBellCloudShare,
  openVoice,
  closeVoice,
  ipcRenderer,
  SendMessageToCloud,
  SendMessageToBellPlanet,
  switchFullScreen,
  sendQuitNative,
};
