import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import {connect} from 'react-redux';
import {DETECT} from 'public-repo/lib/config.js';
import projectModeWrapper from '../cm/lib/puzzle/helpers/project-mode';
import ControlsComponent from '../components/controls/controls.jsx';

let isUpdated = false;
class Controls extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleGreenFlagClick',
            'handleStopAllClick',
            'handleEraseClick'
        ]);
        this.state = {
            isTouchedGreenFlag: false,
            greenflagDisabled: false
        };
        this.runtimeManage = projectModeWrapper(props.vm);
        this.isTouchedGreenFlag = false;
    }
    componentDidUpdate () {
        if (this.props.vm.runtime.targets.length && !isUpdated) {
            isUpdated = true;
            this.runtimeManage.saveRuntimeData();
        }
    }
    handleGreenFlagClick (e) {
        e.preventDefault();
        const {isTouchedGreenFlag, greenflagDisabled} = this.state;
        if (e.shiftKey) {
            this.props.vm.setTurboMode(!this.props.turbo);
            return;
        }
        if (isTouchedGreenFlag) {
            return;
        }
        if (greenflagDisabled) {
            return;
        }
        this.props.vm.start();
        let readyGreenflagDisabled = false;
        if (DETECT.isJunior) {
            readyGreenflagDisabled = true;
        }
        this.runtimeManage.isPlaying = false;
        if (!isTouchedGreenFlag) {
            this.setState({
                isTouchedGreenFlag: true,
                greenflagDisabled: readyGreenflagDisabled
            });
        }
        if (this.runtimeManage.isFreezing) {
            return this.runtimeManage.goonProject(isTouchedGreenFlag);
        }
        this.runtimeManage.startProject();
    }
    handleStopAllClick (e) {
        e.preventDefault();
        this.runtimeManage.isPlaying = this.props.projectRunning;
        this.runtimeManage.freezeProject();
        this.setState({
            isTouchedGreenFlag: false
        });
    }
    handleEraseClick (e) {
        e.preventDefault();
        this.runtimeManage.isPlaying = this.props.projectRunning;
        this.setState({
            isTouchedGreenFlag: false,
            greenflagDisabled: false
        });
        this.runtimeManage.eraseProject(true);
    }
    render () {
        this.runtimeManage.isPlaying = this.props.projectRunning;
        if (this.runtimeManage.isPlaying) {
            this.runtimeManage.isFreezing = false; // 代码块自动触发运行状态，冻结状态需要重置
        }
        const {greenflagDisabled, isTouchedGreenFlag} = this.state;
        const {
            vm, // eslint-disable-line no-unused-vars
            isStarted, // eslint-disable-line no-unused-vars
            turbo,
            ...props
        } = this.props;
        return (
            <ControlsComponent
                {...props}
                active={isTouchedGreenFlag}
                greenflagDisabled={greenflagDisabled}
                isTouchedGreenFlag={isTouchedGreenFlag}
                turbo={turbo}
                onGreenFlagClick={this.handleGreenFlagClick}
                onStopAllClick={this.handleStopAllClick}
                onEraseClick={this.handleEraseClick}
            />
        );
    }
}

Controls.propTypes = {
    isStarted: PropTypes.bool.isRequired,
    projectRunning: PropTypes.bool.isRequired,
    turbo: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM)
};

const mapStateToProps = state => ({
    isStarted: state.scratchGui.vmStatus.running,
    projectRunning: state.scratchGui.vmStatus.running,
    turbo: state.scratchGui.vmStatus.turbo
});
// no-op function to prevent dispatch prop being passed to component
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
