import GeneralConfig from '../public-repo/utility/generalConfig';
import Fetch, {baseFetch} from '../public-repo/lib/fetch';
import {DETECT} from '../public-repo/lib/config';
import {getParams} from './env-utils';
import Track from 'PublicRepo/components/Track/Track';

import {
    uploadImage,
    uploadJSON,
    uploadAudio
} from '../cm/lib/upload-utils';

const IDEType = GeneralConfig.ideType;
const IDEScreenType = {
    LANDSCAPE: 1,
    PORTRAIT: 2
};

const trackFirstCreation = args => {
    // 用户第一次创作作品时上报这个事件
    const {proId, classId, lessonId, teacherDiyProjectId} = getParams();
    // 不是第一次提交作品则不上报事件
    if (proId && proId !== teacherDiyProjectId) {
        return Promise.resolve(args);
    }
    const trackParam = {};
    if (classId) {
        return Fetch({
            url: GeneralConfig.serverIo.getClassInfo,
            method: 'get',
            params: {
                id: getParams().classId
            }
        }).then(classInfo => new Promise(resolve => {
            trackParam.school_id = classInfo.school && classInfo.school.id;
            trackParam.school_name = classInfo.school && classInfo.school.name;
            trackParam.school_type = classInfo.school && classInfo.school.school_type;
            trackParam.lesson_id = lessonId;
            Track.track('Creations_generateCreations', trackParam, () => resolve(args));
        }))
            .catch(() => args);
    }
    return new Promise(resolve => {
        Track.track('Creations_generateCreations', trackParam, () => resolve(args));
    }).catch(() => args);
};

const uploadProjectCover = dataURI => uploadImage(dataURI, 'cover');
const uploadProjectAsset = (assetType, dataFormat, data, assetId) => {
    switch (dataFormat) {
    case 'wav':
    case 'mp3':
        return uploadAudio(data, null, assetId);
    case 'json':
        return uploadJSON(data, null, assetId);
    default:
        return uploadImage(data, null, assetId);
    }
};

const uploadProject = (projectId, {assetId, cover, title, isPortrait}, extraRequestParams) => {
    let ProType = DETECT.isJunior ? IDEType.JUNIOR : IDEType.NORMAL;
    if (DETECT.isPythonScratch){
        ProType = IDEType.PY_SCRATCH;
    }
    const params = {
        proid: projectId,
        proName: title,
        // TODO waiting for be
        // START
        // param: vmState,
        // END
        asset_id: assetId,
        proCover: cover,
        pro_type: ProType,
        screen_type: isPortrait ? IDEScreenType.PORTRAIT : IDEScreenType.LANDSCAPE,
        // new version
        version: 3,
        ...extraRequestParams
    };

    if (getParams().isFork) {
        params.is_fork = 1;
    }

    return Fetch({
        url: GeneralConfig.serverIo.saveProject,
        method: 'post',
        params
    }).then(args => trackFirstCreation(args));
};

const getProjectInfo = projectId => Fetch({
    url: GeneralConfig.serverIo.getProjectById(projectId),
    method: 'get'
});

const getProjectJson = assetId => baseFetch({
    url: `${GeneralConfig.projectResourceOrigin}${assetId}`,
    method: 'get'
}).then(data => data.json());

const saveProjectName = (id, name) => Fetch({
    url: GeneralConfig.serverIo.saveProjectName,
    method: 'post',
    params: {
        id,
        name
    }
});

const saveProjectDesc = ({id, text}) => Fetch({
    url: GeneralConfig.serverIo.saveShareDesc,
    method: 'post',
    params: {id, text}
});

const publishProject = ({id, name, description, tags, share}) => Fetch({
    url: GeneralConfig.serverIo.publish_production,
    method: 'post',
    true_array: true,
    params: {
        id,
        name,
        description,
        tags,
        share
    }
});

const getProjectCategoryWithoutDispose = () => Fetch({
    url: GeneralConfig.serverIo.getProjectTags,
    method: 'get'
});

export {
    getProjectCategoryWithoutDispose,
    getProjectInfo,
    getProjectJson,
    uploadProjectCover,
    uploadProjectAsset,
    uploadProject,
    saveProjectName,
    saveProjectDesc,
    IDEType,
    IDEScreenType,
    publishProject
};
