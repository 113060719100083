import {getParams} from '../../lib/env-utils';

const params = getParams();

export default {
    ...params,

    stageScale: 0.5,
    curWinWidth: () => document.body.clientWidth,
    curWinHeight: () => document.body.clientHeight || window.innerHeight
};
