const Types = {
    ENLIGHTENMENT: 'enlightenment'
};

// ! worker extension code !
// let extensionURL;
// extensionURL = require(`file-loader!./${type}/task-${options.taskId}`);
// if (!extensionURL) {
//     return Promise.reject();
// }
// return vm.extensionManager.loadExtensionURL(extensionURL).catch(err => {
//     console.log(err);
// });
// Scratch.extensions.register(new EnlightenmentBlocks());

let isInject = false;
const loadedExtensionUrls = new Set();

const loadExtension = (vm, {type, ...options}, ...args) => {
    if (!isInject) {
        isInject = true;

        // hack to prevent load un-built-in extension auto.
        vm.extensionManager.constructor.prototype._originLoadExtensionURL =
            vm.extensionManager.loadExtensionURL;
        vm.extensionManager.constructor.prototype.loadExtensionURL = function(
            extensionURL
        ) {
            if (loadedExtensionUrls.has(extensionURL)) {
                return Promise.resolve();
            }

            return this._originLoadExtensionURL(extensionURL);
        };
    }

    let extensionURL;
    let promise;

    switch (type) {
        case Types.ENLIGHTENMENT:
            extensionURL = `${type}-task-${options.taskId}`;
            promise = import(`./${type}/task-${options.taskId}`);
            break;
        default:
            promise = Promise.reject();
    }

    if (loadedExtensionUrls.has(extensionURL)) {
        return Promise.resolve();
    }

    return promise.then(data => {
        const Extension = data.default;
        const extensionInstance = new Extension(vm.runtime, args);
        const serviceName = vm.extensionManager._registerInternalExtension(
            extensionInstance
        );
        loadedExtensionUrls.add(Extension.EXTENSION_ID);
        vm.extensionManager._loadedExtensions.set(extensionURL, serviceName);
    });
};

const tryPreloadExtensions = (vm, projectJson) => {
    const enlmRegExp = /"opcode":"enlm(\d*)_/;
    const result = projectJson.match(enlmRegExp);

    if (result) {
        return loadExtension(vm, {
            type: Types.ENLIGHTENMENT,
            taskId: result[1] || 1
        });
    }

    return Promise.resolve();
};

export {loadExtension as default, tryPreloadExtensions};
