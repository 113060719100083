import {STAGE_DISPLAY_SIZES} from '../lib/layout-constants.js';

const SET_STAGE_SIZE = 'scratch-gui/StageSize/SET_STAGE_SIZE';
const SET_CUSTOM_STAGE_DIMENSIONS = 'scratch-gui/StageSize/SET_CUSTOM_STAGE_DIMENSIONS';

const initialState = {
    customStageDimensions: null,
    stageSize: STAGE_DISPLAY_SIZES.large
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_STAGE_SIZE:
        /*
        return {
            stageSize: action.stageSize
        };
        */
        return Object.assign({}, state, {
            stageSize: action.stageSize
        });
    case SET_CUSTOM_STAGE_DIMENSIONS:
        return Object.assign({}, state, {
            customStageDimensions: action.stageDimensions
        });
    default:
        return state;
    }
};

const setStageSize = function (stageSize) {
    return {
        type: SET_STAGE_SIZE,
        stageSize: stageSize
    };
};

const setCustomStageDimensions = function (stageDimensions) {
    return {
        type: SET_CUSTOM_STAGE_DIMENSIONS,
        stageDimensions: stageDimensions
    };
};

export {
    reducer as default,
    initialState as stageSizeInitialState,
    setCustomStageDimensions,
    setStageSize
};
