export default class Injector {
    constructor() {
        this.type = null;
        this.hijackedComponents = {};

        this._hijackBehaviors = {};
        this._reducersEnhancers = [];
        this._initialStateEnhancers = [];

        this._reducersEnhancers.push(reducers => {
            reducers.injector = () => this;
        });
    }

    registerHijackBehavior(key, behavior) {
        this._hijackBehaviors[key] = behavior;
    }

    hijack(key, Component) {
        if (!this._hijackBehaviors[key]) return Component;

        this.hijackedComponents[key] = Component;
        return this._hijackBehaviors[key](Component);
    }

    registerReducersEnhancer(enhancer) {
        if (enhancer) {
            this._reducersEnhancers.push(enhancer);
        }
    }

    registerInitialStateEnhancer(enhancer) {
        if (enhancer) {
            this._initialStateEnhancers.push(enhancer);
        }
    }

    enhanceReducers(reducers) {
        this._reducersEnhancers.forEach(enhancer => enhancer(reducers));

        return reducers;
    }

    enhanceInitialState(initialState) {
        this._initialStateEnhancers.forEach(enhancer => enhancer(initialState));

        return initialState;
    }

    inject() {
        return Promise.reject('Need define inject.');
    }
}

import React from 'react';
import {connect} from 'react-redux';
import {setPlayer} from './../../../index';
import {LoadingState} from './../../../reducers/project-state';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
    loading:
        state.scratchGui.projectState.loadingState !==
        LoadingState.SHOWING_WITH_ID
});
const mapDispatchToProps = dispatch => ({
    setPlayerOnly: () => dispatch(setPlayer(true))
});

const JuniorPlayerOnlyHOC = C => {
    class JuniorPlayerOnlyWrapper extends React.Component {
        componentWillReceiveProps(newProps) {
            if (this.props.loading && !newProps.loading) {
                this.props.setPlayerOnly();
            }
        }
        render() {
            return <C />;
        }
    }
    JuniorPlayerOnlyWrapper.propTypes = {
        loading: PropTypes.bool,
        setPlayerOnly: PropTypes.func
    };
    const Component = connect(
        mapStateToProps,
        mapDispatchToProps
    )(JuniorPlayerOnlyWrapper);
    return <Component />;
};

export {JuniorPlayerOnlyHOC};
