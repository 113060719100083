import analytics from '../../../lib/analytics';
import decks from '../../../lib/libraries/decks/index.jsx';

const CLOSE_CARDS = 'scratch-gui/cm/custom-guide/CLOSE_CARDS';
const VIEW_CARDS = 'scratch-gui/cm/custom-guide/VIEW_CARDS';
const ACTIVATE_DECK = 'scratch-gui/cm/custom-guide/ACTIVATE_DECK';
const NEXT_STEP = 'scratch-gui/cm/custom-guide/NEXT_STEP';
const PREV_STEP = 'scratch-gui/cm/custom-guide/PREV_STEP';
const GOTO_STEP = 'scratch-gui/cm/custom-guide/GOTO_STEP';
const DRAG_CARD = 'scratch-gui/cm/custom-guide/DRAG_CARD';
const START_DRAG = 'scratch-gui/cm/custom-guide/START_DRAG';
const END_DRAG = 'scratch-gui/cm/custom-guide/END_DRAG';
const OPEN_CUSTOM_GUIDE = 'scratch-gui/cm/custom-guide/OPEN_CUSTOM_GUIDE';
const ADD_ACTOR_HIGHLIGHT = 'scratch-gui/cm/custom-guide/ADD_ACTOR_HIGHLIGHT';
const REMOVE_ACTOR_HIGHLIGHT =
    'scratch-gui/cm/custom-guide/REMOVE_ACTOR_HIGHLIGHT';

const initialState = {
    visible: false,
    content: decks,
    activeDeckId: null,
    step: 0,
    x: 0,
    y: 0,
    dragging: false,
    actorHighlight: -1
};

const reducer = function(state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case CLOSE_CARDS:
            return Object.assign({}, state, {
                visible: false,
                actorHighlight: -1
            });
        case VIEW_CARDS:
            return Object.assign({}, state, {
                visible: true
            });
        case ACTIVATE_DECK:
            return Object.assign({}, state, {
                activeDeckId: action.activeDeckId,
                step: 0,
                visible: true
            });
        case OPEN_CUSTOM_GUIDE:
            return Object.assign({}, state, {
                activeDeckId: action.activeDeckId,
                step: 0,
                visible: true
            });
        case NEXT_STEP:
            if (state.activeDeckId !== null) {
                analytics.event({
                    category: 'how-to',
                    action: 'next step',
                    label: `${state.activeDeckId} - ${state.step}`
                });
                return Object.assign({}, state, {
                    step: state.step + 1
                });
            }
            return state;
        case PREV_STEP:
            if (state.activeDeckId !== null) {
                if (state.step > 0) {
                    return Object.assign({}, state, {
                        step: state.step - 1
                    });
                }
            }
            return state;
        case GOTO_STEP:
            if (state.activeDeckId !== null) {
                return Object.assign({}, state, {
                    step: action.step
                });
            }
            return state;
        case DRAG_CARD:
            return Object.assign({}, state, {
                x: action.x,
                y: action.y
            });
        case START_DRAG:
            return Object.assign({}, state, {
                dragging: true
            });
        case END_DRAG:
            return Object.assign({}, state, {
                dragging: false
            });
        case ADD_ACTOR_HIGHLIGHT:
            return Object.assign({}, state, {
                actorHighlight: action.actorIndex,
                actorName: action.actorName
            });
        case REMOVE_ACTOR_HIGHLIGHT:
            return Object.assign({}, state, {
                actorHighlight: -1
            });
        default:
            return state;
    }
};

const activateDeck = function(activeDeckId) {
    return {
        type: ACTIVATE_DECK,
        activeDeckId
    };
};

const viewCards = function() {
    return {type: VIEW_CARDS};
};

const closeCards = function() {
    return {type: CLOSE_CARDS};
};

const nextStep = function() {
    return {type: NEXT_STEP};
};

const prevStep = function() {
    return {type: PREV_STEP};
};

const gotoStep = function(step) {
    return {type: GOTO_STEP, step};
};

const dragCard = function(x, y) {
    return {type: DRAG_CARD, x, y};
};

const startDrag = function() {
    return {type: START_DRAG};
};

const endDrag = function() {
    return {type: END_DRAG};
};

const openCustomGuide = (activeDeckId = 'intro-move-sayhello') => ({
    type: OPEN_CUSTOM_GUIDE,
    payload: activeDeckId
});

const addActorHighlight = (actorIndex, actorName) => ({
    type: ADD_ACTOR_HIGHLIGHT,
    actorIndex,
    actorName
});

const removeActorHighlight = () => ({
    type: REMOVE_ACTOR_HIGHLIGHT
});

export {
    reducer as default,
    initialState as customGuideInitialState,
    activateDeck,
    viewCards,
    closeCards,
    nextStep,
    prevStep,
    gotoStep,
    dragCard,
    startDrag,
    endDrag,
    openCustomGuide,
    addActorHighlight,
    removeActorHighlight
};
