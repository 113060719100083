// todo Remove below
/* eslint-disable no-mixed-operators */

import ScratchBlocks from 'scratch-blocks';

export default () => {
    const BlockSvg = ScratchBlocks.BlockSvg;

    const SIN_30 = Math.sin(Math.PI / 6);
    const SIN_60 = Math.sin(Math.PI / 3);
    const TAN_60 = Math.tan(Math.PI / 3);

    ScratchBlocks.Flyout.prototype.MARGIN = 28;

    BlockSvg.NOTCH_START_PADDING = 6 * BlockSvg.GRID_UNIT;
    BlockSvg.STATEMENT_INPUT_INNER_SPACE = 5 * BlockSvg.GRID_UNIT;
    BlockSvg.MIN_BLOCK_X = 18 * BlockSvg.GRID_UNIT;
    BlockSvg.MIN_BLOCK_Y = 14 * BlockSvg.GRID_UNIT;

    BlockSvg.NOTCH_WIDTH = 7 * BlockSvg.GRID_UNIT;
    BlockSvg.NOTCH_PATH_LEFT = 'l 8,8 h 16 l 8,-8';

    BlockSvg.NOTCH_PATH_RIGHT = 'l -8,8 h -16 l -8,-8';

    BlockSvg.SEP_SPACE_X = 3 * BlockSvg.GRID_UNIT;

    BlockSvg.TOP_LEFT_CORNER = `L ${BlockSvg.CORNER_RADIUS},0`;

    BlockSvg.TOP_RIGHT_CORNER = `l ${BlockSvg.CORNER_RADIUS},${
        BlockSvg.CORNER_RADIUS
    }`;

    BlockSvg.BOTTOM_RIGHT_CORNER = ` l -${BlockSvg.CORNER_RADIUS},${
        BlockSvg.CORNER_RADIUS
    }`;

    BlockSvg.BOTTOM_LEFT_CORNER = `l -${BlockSvg.CORNER_RADIUS},-${
        BlockSvg.CORNER_RADIUS
    }`;

    BlockSvg.INNER_TOP_LEFT_CORNER = `l -${BlockSvg.CORNER_RADIUS},${
        BlockSvg.CORNER_RADIUS
    }`;

    BlockSvg.INNER_BOTTOM_LEFT_CORNER = `l ${BlockSvg.CORNER_RADIUS},${
        BlockSvg.CORNER_RADIUS
    }`;

    BlockSvg._NORTH_HEIGHT = BlockSvg.GRID_UNIT * 2;
    BlockSvg._SHADOW_HEIGHT = BlockSvg.GRID_UNIT / 2;
    BlockSvg._SHADOW_NOTCH_PATH_LEFT = 'l 8,8 h 12 l 8,-8';

    BlockSvg._HEXAGON_SIDE_LENGTH = 34;
    BlockSvg._INNER_HEXAGON_SIDE_LENGTH = 22;
    BlockSvg._ROUND_TRIANGLE_SIDE_LENGTH = 12;
    BlockSvg._HAT_HEIGHT = 64;

    BlockSvg._BOTTOM_BRANCH_OFFSET_X =
        BlockSvg.CORNER_RADIUS +
        BlockSvg.NOTCH_START_PADDING +
        BlockSvg.NOTCH_WIDTH +
        (3 * BlockSvg.GRID_UNIT);
    BlockSvg._SHAPE_END_BOTTOM_BRANCH_OFFSET_X =
        BlockSvg.CORNER_RADIUS + (8 * BlockSvg.GRID_UNIT);

    BlockSvg._ROUND_RADIUS = 2;

    BlockSvg.START_HAT_PATH = `
        M ${BlockSvg.CORNER_RADIUS} ${BlockSvg._HAT_HEIGHT -
        BlockSvg._NORTH_HEIGHT}
        l -${BlockSvg._HEXAGON_SIDE_LENGTH *
            SIN_60} -${BlockSvg._HEXAGON_SIDE_LENGTH * SIN_30}
        v -${BlockSvg._HEXAGON_SIDE_LENGTH}
        l ${BlockSvg._HEXAGON_SIDE_LENGTH *
            SIN_60} -${BlockSvg._HEXAGON_SIDE_LENGTH * SIN_30}
        L ${((BlockSvg._HEXAGON_SIDE_LENGTH +
            BlockSvg._HEXAGON_SIDE_LENGTH -
            (BlockSvg._HAT_HEIGHT - BlockSvg._NORTH_HEIGHT)) *
            TAN_60) +
            BlockSvg.CORNER_RADIUS} 0
    `;

    return {
        getRegularHexagonPath(sideLength) {
            return `
                l -${sideLength * SIN_60} -${sideLength * SIN_30}
                v -${sideLength}
                l ${sideLength * SIN_60} -${sideLength * SIN_30}
                l ${sideLength * SIN_60} ${sideLength * SIN_30}
                v ${sideLength}
                z
            `;
        },
        getRoundTrianglePath(sideLength) {
            return `
                a 2 2 0 0 0 ${2 * SIN_60} ${2 * SIN_30}
                l ${sideLength * SIN_60} -${sideLength * SIN_30}
                a 2 2 0 0 0 0 -2
                l -${sideLength * SIN_60} -${sideLength * SIN_30}
                a 2 2 0 0 0 -${2 * SIN_60} ${2 * SIN_30}
                z
            `;
        },
        getHatRegularHexagonBranchPath() {
            return `
                M ${BlockSvg.CORNER_RADIUS +
                    (BlockSvg._INNER_HEXAGON_SIDE_LENGTH *
                        SIN_60)} ${BlockSvg._HAT_HEIGHT -
                BlockSvg._NORTH_HEIGHT -
                (BlockSvg._HEXAGON_SIDE_LENGTH -
                    BlockSvg._INNER_HEXAGON_SIDE_LENGTH) -
                BlockSvg._INNER_HEXAGON_SIDE_LENGTH -
                (BlockSvg._INNER_HEXAGON_SIDE_LENGTH * SIN_30)}
                l ${BlockSvg.CORNER_RADIUS} -${BlockSvg.CORNER_RADIUS}
                h 20
                L ${BlockSvg.CORNER_RADIUS +
                    (BlockSvg._INNER_HEXAGON_SIDE_LENGTH * SIN_60) +
                    (BlockSvg.CORNER_RADIUS * 2) +
                    20} 0
            `;
        },
        getHatRegularHexagonPath() {
            return `
                M ${BlockSvg.CORNER_RADIUS} ${BlockSvg._HAT_HEIGHT -
                BlockSvg._NORTH_HEIGHT -
                (BlockSvg._HEXAGON_SIDE_LENGTH -
                    BlockSvg._INNER_HEXAGON_SIDE_LENGTH)}
                ${this.getRegularHexagonPath(
                    BlockSvg._INNER_HEXAGON_SIDE_LENGTH
                )}
            `;
        },
        getHatRoundTrianglePath() {
            return `
                M ${((-BlockSvg._ROUND_TRIANGLE_SIDE_LENGTH * SIN_60) / 2) +
                    BlockSvg.CORNER_RADIUS} ${BlockSvg._HAT_HEIGHT -
                BlockSvg._NORTH_HEIGHT -
                (BlockSvg._HEXAGON_SIDE_LENGTH -
                    (BlockSvg._ROUND_TRIANGLE_SIDE_LENGTH / 2))}
                ${this.getRoundTrianglePath(
                    BlockSvg._ROUND_TRIANGLE_SIDE_LENGTH
                )}
            `;
        },
        getHatBottomPath(target, width, height) {
            return `
                M ${BlockSvg.CORNER_RADIUS},${height - BlockSvg._NORTH_HEIGHT}
                l ${BlockSvg._SHADOW_HEIGHT}, -${BlockSvg._SHADOW_HEIGHT}
                h ${BlockSvg.NOTCH_START_PADDING - BlockSvg.GRID_UNIT}
                ${BlockSvg._SHADOW_NOTCH_PATH_LEFT}
                H ${width - BlockSvg.CORNER_RADIUS}
                l ${BlockSvg.CORNER_RADIUS}, -${BlockSvg.CORNER_RADIUS}
                v ${BlockSvg._SHADOW_HEIGHT}
                l -${BlockSvg.CORNER_RADIUS}, ${BlockSvg.CORNER_RADIUS}
                H ${BlockSvg.CORNER_RADIUS +
                    BlockSvg.NOTCH_START_PADDING +
                    BlockSvg.NOTCH_WIDTH}
                ${BlockSvg.NOTCH_PATH_RIGHT}
                H ${BlockSvg.CORNER_RADIUS}
                z
            `;
        },
        getHatBottomBranchPath(target, width, height) {
            return `
                M ${BlockSvg._BOTTOM_BRANCH_OFFSET_X}, ${height -
                BlockSvg._NORTH_HEIGHT -
                BlockSvg._SHADOW_HEIGHT}
                l ${BlockSvg.GRID_UNIT}, -${BlockSvg.GRID_UNIT}
                H ${width}
            `;
        },
        getRoundPath(target, width) {
            // eslint-disable-line no-unused-vars
            return `
                M ${width - (BlockSvg.GRID_UNIT * 2)}, ${(BlockSvg.GRID_UNIT * 2) -
                BlockSvg._ROUND_RADIUS}
                a ${BlockSvg._ROUND_RADIUS}, ${
                BlockSvg._ROUND_RADIUS
            } 0 1,1 4,0 a ${BlockSvg._ROUND_RADIUS}, ${
                BlockSvg._ROUND_RADIUS
            } 0 1,1 -4,0 z
            `;
        },
        getBottomPath(target, width, height) {
            return `
                M 0,${height -
                    BlockSvg._NORTH_HEIGHT -
                    BlockSvg.CORNER_RADIUS -
                    BlockSvg._SHADOW_HEIGHT}
                l ${BlockSvg.CORNER_RADIUS}, ${BlockSvg.CORNER_RADIUS}
                h ${BlockSvg.NOTCH_START_PADDING - (BlockSvg.GRID_UNIT / 2)}
                ${BlockSvg._SHADOW_NOTCH_PATH_LEFT}
                H ${width - BlockSvg.CORNER_RADIUS}
                l ${BlockSvg.CORNER_RADIUS}, -${BlockSvg.CORNER_RADIUS}
                v ${BlockSvg._SHADOW_HEIGHT}
                l -${BlockSvg.CORNER_RADIUS}, ${BlockSvg.CORNER_RADIUS}
                H ${BlockSvg.CORNER_RADIUS +
                    BlockSvg.NOTCH_START_PADDING +
                    BlockSvg.NOTCH_WIDTH}
                ${BlockSvg.NOTCH_PATH_RIGHT}
                H ${BlockSvg.CORNER_RADIUS}
                l -${BlockSvg.CORNER_RADIUS}, -${BlockSvg.CORNER_RADIUS}
                z
            `;
        },
        getBottomBranchPath(target, width, height) {
            return width >
                BlockSvg._BOTTOM_BRANCH_OFFSET_X + (BlockSvg.CORNER_RADIUS * 3)
                ? `
                M ${BlockSvg._BOTTOM_BRANCH_OFFSET_X}, ${height -
                      BlockSvg._NORTH_HEIGHT -
                      BlockSvg._SHADOW_HEIGHT}
                l ${BlockSvg.GRID_UNIT}, -${BlockSvg.GRID_UNIT}
                H ${width - (BlockSvg.GRID_UNIT * 1.5)}
                l ${BlockSvg.GRID_UNIT * 1.5}, -${BlockSvg.GRID_UNIT * 1.5}
            `
                : '';
        },
        getLeftBranchPath(target, width, height) {
            return `
                M 0,${BlockSvg.CORNER_RADIUS + BlockSvg.GRID_UNIT}
                l ${BlockSvg.GRID_UNIT}, ${BlockSvg.GRID_UNIT}
                V ${height -
                    BlockSvg._NORTH_HEIGHT -
                    BlockSvg._SHADOW_HEIGHT -
                    BlockSvg.CORNER_RADIUS -
                    (BlockSvg.GRID_UNIT * 1.5)}
                l -${BlockSvg.GRID_UNIT}, ${BlockSvg.GRID_UNIT}
            `;
        },
        getBottomPolygonPath(target, width) {
            // eslint-disable-line no-unused-vars
            return `
                M ${BlockSvg._SHADOW_HEIGHT}, ${target.edgeShapeWidth_ -
                BlockSvg._SHADOW_HEIGHT}
                l ${target.edgeShapeWidth_} ${target.edgeShapeWidth_}
                H ${width - target.edgeShapeWidth_ - BlockSvg._SHADOW_HEIGHT}
                l ${target.edgeShapeWidth_} -${target.edgeShapeWidth_}
                l ${BlockSvg._SHADOW_HEIGHT} ${BlockSvg._SHADOW_HEIGHT}
                l -${target.edgeShapeWidth_} ${target.edgeShapeWidth_}
                H ${target.edgeShapeWidth_}
                l -${target.edgeShapeWidth_} -${target.edgeShapeWidth_}
                z
            `;
        },
        getBottomRoundPath(target, width) {
            // eslint-disable-line no-unused-vars
            return `
                M 0,${target.edgeShapeWidth_}
                a ${target.edgeShapeWidth_} ${target.edgeShapeWidth_} 0 0 0 ${
                target.edgeShapeWidth_
            } ${target.edgeShapeWidth_ - (BlockSvg.GRID_UNIT / 2)}
                H ${width - target.edgeShapeWidth_}
                A ${target.edgeShapeWidth_} ${
                target.edgeShapeWidth_
            } 0 0 0 ${width} ${target.edgeShapeWidth_}
                a ${target.edgeShapeWidth_} ${target.edgeShapeWidth_} 0 0 1 -${
                target.edgeShapeWidth_
            } ${target.edgeShapeWidth_}
                H ${target.edgeShapeWidth_}
                a ${target.edgeShapeWidth_} ${target.edgeShapeWidth_} 0 0 1 -${
                target.edgeShapeWidth_
            } -${target.edgeShapeWidth_}
                z
            `;
        },
        getShapeEndBottomPath(target, width, height) {
            return `
                M 0,${height - BlockSvg.CORNER_RADIUS - BlockSvg._SHADOW_HEIGHT}
                l ${BlockSvg.CORNER_RADIUS}, ${BlockSvg.CORNER_RADIUS}
                h ${BlockSvg.NOTCH_START_PADDING - (BlockSvg.GRID_UNIT / 2)}
                H ${width - BlockSvg.CORNER_RADIUS}
                l ${BlockSvg.CORNER_RADIUS}, -${BlockSvg.CORNER_RADIUS}
                v ${BlockSvg._SHADOW_HEIGHT}
                l -${BlockSvg.CORNER_RADIUS}, ${BlockSvg.CORNER_RADIUS}
                H ${BlockSvg.CORNER_RADIUS +
                    BlockSvg.NOTCH_START_PADDING +
                    BlockSvg.NOTCH_WIDTH}
                H ${BlockSvg.CORNER_RADIUS}
                l -${BlockSvg.CORNER_RADIUS}, -${BlockSvg.CORNER_RADIUS}
                z
            `;
        },
        getShapeEndBottomBranchPath(target, width, height) {
            return width >
                BlockSvg._SHAPE_END_BOTTOM_BRANCH_OFFSET_X +
                    BlockSvg.CORNER_RADIUS +
                    (BlockSvg.GRID_UNIT * 2)
                ? `
                    M ${BlockSvg._SHAPE_END_BOTTOM_BRANCH_OFFSET_X}, ${height -
                      BlockSvg._SHADOW_HEIGHT}
                    l ${BlockSvg.GRID_UNIT}, -${BlockSvg.GRID_UNIT}
                    H ${width - (BlockSvg.GRID_UNIT * 2) - BlockSvg.CORNER_RADIUS}
                    l ${BlockSvg.GRID_UNIT}, ${BlockSvg.GRID_UNIT}
                `
                : '';
        },
        getShapeEndLeftBranchPath(target, width, height) {
            return `
                M 0,${BlockSvg.CORNER_RADIUS + BlockSvg.GRID_UNIT}
                l ${BlockSvg.GRID_UNIT}, ${BlockSvg.GRID_UNIT}
                V ${height -
                    BlockSvg._SHADOW_HEIGHT -
                    BlockSvg.CORNER_RADIUS -
                    (BlockSvg.GRID_UNIT / 2)}
                l ${BlockSvg._SHADOW_HEIGHT +
                    BlockSvg.CORNER_RADIUS +
                    (BlockSvg.GRID_UNIT / 2)},
                    ${BlockSvg._SHADOW_HEIGHT +
                        BlockSvg.CORNER_RADIUS +
                        (BlockSvg.GRID_UNIT / 2)}
            `;
        },
        getHatImageTranslate(target, width, height) {
            return [
                (-width / 2) + BlockSvg.CORNER_RADIUS,
                BlockSvg._HAT_HEIGHT -
                    BlockSvg.NOTCH_HEIGHT -
                    height -
                    (BlockSvg._HEXAGON_SIDE_LENGTH - (height / 2))
            ];
        }
    };
};
