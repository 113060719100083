import Blockly from 'scratch-blocks';
import _create from 'lodash/create';
import '!style-loader!css-loader!./style.css';
import goog from 'scratch-blocks/shim/blockly_compressed_vertical.goog.js';

const FIXED_WIDTH = 64;
const FIXED_HEIGHT = 56;
let init = false;

export const reigsterScratchBlocksFieldImageDropdown = () => {
    if (init) {
        return;
    }
    // Blockly.FieldImageDropdown = FieldImageDropdown;

    Blockly.FieldImageDropdown = function(menuGenerator, opt_validator) {
        this.menuGenerator_ = menuGenerator;
        this.trimOptions_();
        const firstTuple = this.getOptions()[0];

        // Call parent's constructor.
        Blockly.FieldDropdown.superClass_.constructor.call(
            this,
            firstTuple[1],
            opt_validator
        );
        this.addArgType('dropdown');
    };

    Blockly.FieldImageDropdown.fromJson = function(element) {
        return new Blockly.FieldImageDropdown(element.options);
    };

    Blockly.FieldImageDropdown.CHECKMARK_OVERHANG = 25;

    Blockly.FieldImageDropdown.prototype = _create(
        Blockly.FieldDropdown.prototype,
        {
            constructor: Blockly.FieldImageDropdown
        }
    );

    /**
     * Horizontal distance that a checkmark overhangs the dropdown.
     */
    // Blockly.FieldDropdown.CHECKMARK_OVERHANG = 25;

    Blockly.FieldImageDropdown.prototype.init = function() {
        if (this.fieldGroup_) {
            // Dropdown has already been initialized once.
            return;
        }

        this.updateWidth();

        // Add dropdown arrow: "option ▾" (LTR) or "▾ אופציה" (RTL)
        // Positioned on render, after text size is calculated.
        /** @type {Number} */
        this.arrowSize_ = 12;
        /** @type {Number} */
        this.arrowX_ = 0;
        /** @type {Number} */
        this.arrowY_ = 11;
        this.arrow_ = Blockly.utils.createSvgElement('image', {
            height: `${this.arrowSize_}px`,
            width: `${this.arrowSize_}px`
        });
        this.arrow_.setAttributeNS(
            'http://www.w3.org/1999/xlink',
            'xlink:href',
            `${Blockly.mainWorkspace.options.pathToMedia}dropdown-arrow.svg`
        );
        this.className_ += ' cmblocklyImageDropdownText';

        Blockly.FieldDropdown.superClass_.init.call(this);
        // If not in a shadow block, draw a box.
        if (!this.sourceBlock_.isShadow()) {
            this.box_ = Blockly.utils.createSvgElement(
                'rect',
                {
                    'rx': FIXED_HEIGHT / 2,
                    'ry': FIXED_HEIGHT / 2,
                    'x': 0,
                    'y': 0,
                    'width': this.size_.width,
                    'height': this.size_.height,
                    'stroke': this.sourceBlock_.getColourTertiary(),
                    'fill': this.sourceBlock_.getColour(),
                    'class': 'cmblocklyImageBlockBackground',
                    'fill-opacity': 1
                },
                null
            );
            this.fieldGroup_.insertBefore(this.box_, this.textElement_);
        }
        // Force a reset of the text to add the arrow.
        const text = this.text_;
        this.text_ = null;
        this.setText(text);
    };

    Blockly.FieldImageDropdown.prototype.setText = function(text) {
        if (text === null || text === this.text_) {
            // No change if null.
            return;
        }

        this.text_ = text;
        this.renderCmDropDownImage(text);

        this.updateTextNode_();

        if (this.textElement_) {
            this.textElement_.parentNode.appendChild(this.arrow_);
            this.textElement_.parentNode.appendChild(this.cmImageBg_);
            this.textElement_.parentNode.appendChild(this.cmImage_);
        }
        if (this.sourceBlock_ && this.sourceBlock_.rendered) {
            this.sourceBlock_.render();
            this.sourceBlock_.bumpNeighbours_();
        }
    };
    Blockly.FieldImageDropdown.prototype.renderCmDropDownImage = function(
        text
    ) {
        if (!this.cmImage_) {
            this.cmImage_ = Blockly.utils.createSvgElement('image', {
                height: `${FIXED_HEIGHT - 4}px`,
                width: `${FIXED_HEIGHT - 4}px`
            });

            this.cmImage_.setAttribute(
                'transform',
                `translate(${FIXED_HEIGHT / 3},${(FIXED_HEIGHT / 2) -
                    ((FIXED_HEIGHT - 4) / 2)})`
            );

            this.cmImageBg_ = Blockly.utils.createSvgElement('rect', {
                height: `${FIXED_HEIGHT - 4}px`,
                width: `${FIXED_HEIGHT - 4}px`
            });
            this.cmImageBg_.setAttribute(
                'transform',
                `translate(${FIXED_HEIGHT / 3},${(FIXED_HEIGHT / 2) -
                    ((FIXED_HEIGHT - 4) / 2)})`
            );

            this.cmImageBg_.setAttribute('fill', 'white');
            this.cmImageBg_.setAttribute('rx', '4');
            this.cmImageBg_.setAttribute('ry', '4');
        }
        const options = this.getOptions();
        const selectOption = options.filter(e => e[1] === text);
        this.cmImage_.setAttributeNS(
            'http://www.w3.org/1999/xlink',
            'xlink:href',
            `${selectOption[0]?.[0]?.src || ''}`
        );
        this.cmImageBg_.setAttribute(
            'fill-opacity',
            `${selectOption[0]?.[0]?.src ? 1 : 0}`
        );
    };
    Blockly.FieldImageDropdown.prototype.setValue = function(newValue) {
        if (newValue === null || newValue === this.value_) {
            return; // No change if null.
        }
        if (this.sourceBlock_ && Blockly.Events.isEnabled()) {
            Blockly.Events.fire(
                new Blockly.Events.BlockChange(
                    this.sourceBlock_,
                    'field',
                    this.name,
                    this.value_,
                    newValue
                )
            );
        }
        // Clear menu item for old value.
        if (this.selectedItem) {
            this.selectedItem.setChecked(false);
            this.selectedItem = null;
        }
        this.value_ = newValue;
        this.renderCmDropDownImage(newValue);
        // Look up and display the human-readable text.
        const options = this.getOptions();
        for (let i = 0; i < options.length; i++) {
            // Options are tuples of human-readable text and language-neutral values.
            if (options[i][1] === newValue) {
                const content = options[i][0];
                if (typeof content === 'object') {
                    this.imageJson_ = content;
                    this.text_ = content.alt;
                } else {
                    this.imageJson_ = null;
                    this.text_ = content;
                }
                // Always rerender if either the value or the text has changed.
                this.forceRerender();
                return;
            }
        }
        // Value not found.  Add it, maybe it will become valid once set
        // (like variable names).
        this.text_ = newValue;
        this.forceRerender();
    };
    Blockly.FieldImageDropdown.prototype.updateWidth = function() {
        let width = FIXED_WIDTH;

        // Add padding to left and right of text.
        if (this.EDITABLE) {
            width += Blockly.BlockSvg.EDITABLE_FIELD_PADDING;
        }

        // Adjust width for drop-down arrows.
        this.arrowWidth_ = 0;
        if (this.positionArrow) {
            this.arrowWidth_ = this.positionArrow(width);
            width += this.arrowWidth_;
        }

        // Add padding to any drawn box.
        if (this.box_) {
            width += 2 * Blockly.BlockSvg.BOX_FIELD_PADDING;
        }

        // Set width of the field.
        this.size_.width = width;
        this.size_.height = FIXED_HEIGHT;
    };
    Blockly.FieldImageDropdown.prototype.positionArrow = function(x) {
        if (!this.arrow_) {
            return 0;
        }

        let addedWidth = 0;
        if (this.sourceBlock_.RTL) {
            this.arrowX_ =
                this.arrowSize_ - Blockly.BlockSvg.DROPDOWN_ARROW_PADDING;
            addedWidth =
                this.arrowSize_ + Blockly.BlockSvg.DROPDOWN_ARROW_PADDING;
        } else {
            this.arrowX_ = x + (Blockly.BlockSvg.DROPDOWN_ARROW_PADDING / 2);
            addedWidth =
                this.arrowSize_ + Blockly.BlockSvg.DROPDOWN_ARROW_PADDING;
        }
        if (this.box_) {
            // Bump positioning to the right for a box-type drop-down.
            this.arrowX_ += Blockly.BlockSvg.BOX_FIELD_PADDING;
        }
        this.arrow_.setAttribute(
            'transform',
            `translate(${this.arrowX_},${(FIXED_HEIGHT / 2) -
                (this.arrowSize_ / 2)})`
        );
        return addedWidth;
    };
    Blockly.FieldImageDropdown.prototype.showEditor_ = function() {
        const options = this.getOptions() || [];
        if (options.length === 0) return;

        this.dropDownOpen_ = true;
        // If there is an existing drop-down someone else owns, hide it immediately and clear it.
        Blockly.DropDownDiv.hideWithoutAnimation();
        Blockly.DropDownDiv.clearContent();
        Blockly.utils.addClass(Blockly.DropDownDiv.DIV_, 'data-cm-dropdown');

        const contentDiv = Blockly.DropDownDiv.getContentDiv();

        const thisField = this;
        // eslint-disable-next-line
        function callback(e) {
            const menu = this;
            const menuItem = e.target;
            if (menuItem) {
                thisField.onItemSelected(menu, menuItem);
            }
            Blockly.DropDownDiv.hide();
            Blockly.Events.setGroup(false);
        }

        const menu = new goog.ui.Menu();
        menu.setRightToLeft(this.sourceBlock_.RTL);
        let index = 0;
        for (let i = 0; i < options.length; i++) {
            let content = options[i][0]; // Human-readable text or image.
            const value = options[i][1]; // Language-neutral value.
            let isImg = false;
            if (typeof content === 'object') {
                // An image, not text.
                const image = new Image(32, 32);
                image.src = content.src;
                image.alt = content.alt || '';
                content = image;
                isImg = true;
            }
            const menuItem = new goog.ui.MenuItem(content);
            menuItem.setRightToLeft(this.sourceBlock_.RTL);
            menuItem.setValue(value);
            menuItem.setCheckable(true);
            if (isImg) {
                menuItem.addClassName('cm-image');
                menuItem.addClassName(`cm-image-${index}`);
                index = index >= 2 ? 0 : index + 1;
            } else {
                menuItem.addClassName('no-cm-image');
            }
            menu.addChild(menuItem, true);
            const checked = `${value}` === `${this.value_}`;
            menuItem.setChecked(checked);
            if (checked) {
                this.selectedItem = menuItem;
            }
        }
        // Listen for mouse/keyboard events.
        goog.events.listen(menu, goog.ui.Component.EventType.ACTION, callback);

        // Record windowSize and scrollOffset before adding menu.
        menu.render(contentDiv);
        const menuDom = menu.getElement();
        Blockly.utils.addClass(menuDom, 'blocklyCmDropdownMenu');
        // Record menuSize after adding menu.
        const menuSize = goog.style.getSize(menuDom);
        // Recalculate height for the total content, not only box height.
        menuSize.height = menuDom.scrollHeight;

        const primaryColour = this.sourceBlock_.isShadow()
            ? this.sourceBlock_.parentBlock_.getColour()
            : this.sourceBlock_.getColour();

        Blockly.DropDownDiv.setColour(
            primaryColour,
            this.sourceBlock_.getColourTertiary()
        );

        const category = this.sourceBlock_.isShadow()
            ? this.sourceBlock_.parentBlock_.getCategory()
            : this.sourceBlock_.getCategory();
        Blockly.DropDownDiv.setCategory(category);

        // Calculate positioning based on the field position.
        const scale = this.sourceBlock_.workspace.scale;
        const bBox = {width: this.size_.width, height: this.size_.height};
        bBox.width *= scale;
        bBox.height *= scale;
        const position = this.fieldGroup_.getBoundingClientRect();
        const primaryX = position.left + (bBox.width / 2);
        const primaryY = position.top + bBox.height;
        const secondaryX = primaryX;
        const secondaryY = position.top;
        // Set bounds to workspace; show the drop-down.
        Blockly.DropDownDiv.setBoundsElement(
            this.sourceBlock_.workspace.getParentSvg().parentNode
        );
        Blockly.DropDownDiv.show(
            this,
            primaryX,
            primaryY,
            secondaryX,
            secondaryY,
            this.onHide.bind(this)
        );

        menu.setAllowAutoFocus(true);
        menuDom.focus();

        // Update colour to look selected.
        if (!this.disableColourChange_) {
            if (this.sourceBlock_.isShadow()) {
                this.sourceBlock_.setShadowColour(
                    this.sourceBlock_.getColourTertiary()
                );
            } else if (this.box_) {
                this.box_.setAttribute(
                    'fill',
                    this.sourceBlock_.getColourTertiary()
                );
            }
        }
    };
    Blockly.FieldImageDropdown.prototype.onHide = function() {
        this.dropDownOpen_ = false;
        // Update colour to look selected.
        if (!this.disableColourChange_ && this.sourceBlock_) {
            if (this.sourceBlock_.isShadow()) {
                this.sourceBlock_.clearShadowColour();
            } else if (this.box_) {
                this.box_.setAttribute('fill', this.sourceBlock_.getColour());
            }
        }
        setTimeout(() => {
            Blockly.utils.removeClass(
                Blockly.DropDownDiv.DIV_,
                'data-cm-dropdown'
            );
        }, 0);
    };
    Blockly.Field.register('field_image_dropdown', Blockly.FieldImageDropdown);
    init = true;
};
