import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import VM from 'scratch-vm';
import cookie from 'js-cookie';

import injectGlobalVar from './inject-global-var';

const SdkStateHOC = function (WrappedComponent) {
    class SdkStateWrapper extends React.Component {
        constructor (props) {
            super(props);

            this.sdkTarget = null;
            this.state = {
                Component: null
            };
        }

        componentDidMount () {
            if (
                localStorage.getItem('cm_scratch_dev') === 'true' ||
                // Deprecated
                localStorage.getItem('scratch_cm_dev') === 'true'
            ) {
                injectGlobalVar({
                    vm: this.props.vm
                });

                if (cookie.get('admin_role') === '1') {
                    const sdkTarget = document.createElement('div');
                    document.body.appendChild(sdkTarget);
                    sdkTarget.id = 'sdk';
                    this.sdkTarget = sdkTarget;

                    import('../containers/sdk.jsx').then(
                        ({default: Container}) => {
                            this.setState({
                                Component: Container
                            });
                        }
                    );
                }
            }
        }

        render () {
            return (
                <React.Fragment>
                    <WrappedComponent {...this.props} />
                    {this.state.Component &&
                        ReactDOM.createPortal(
                            <this.state.Component />,
                            this.sdkTarget
                        )}
                </React.Fragment>
            );
        }
    }

    SdkStateWrapper.propTypes = {
        vm: PropTypes.instanceOf(VM).isRequired
    };
    const mapStateToProps = state => ({
        vm: state.scratchGui.vm
    });
    return connect(mapStateToProps)(SdkStateWrapper);
};

export default SdkStateHOC;
