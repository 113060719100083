import getEventEmitterSingleton from 'PublicRepo/utility/getEventEmitterSingleton';
// import UserData from 'PublicRepo/utility/userDataManager';
// import once from 'lodash/once';

// let hasRegister = false;

const BellCodeTracker = {
  track(eventName, properties, callback) {
    if (!eventName) return;
    if (typeof callback === 'function') {
      callback();
    }
    if (!__PRO__) {
      console.log(`[Track事件][${eventName}]`, properties);
    }
  },
  // (function () {
  //   const brandName = window.location.href.indexOf('bellcode') >= 0
  //       ? 'bellcode'
  //       : 'codingmonkey';

  //   return function (eventName, properties, callback) {
  //     if (!eventName) return;

  //     const cb = once(() => {
  //       callback && callback();
  //     });
  //     setTimeout(cb, 300); // NOTE: 保证 callback 会被执行到

  //     if (!hasRegister && UserData.userInfo) {
  //       BellCodeTracker.register(UserData.userInfo);
  //     }
  //     const props = Object.assign({}, properties);
  //     props.brandName = brandName;
  //     eventName = eventName
  //       .replace(/ /g, '_')
  //       .replace(/\[/g, '')
  //       .replace(/\]/g, '_');
  //     // sensordata 会过滤部分关键字
  //     if (props.id) {
  //       props._id = props.id;
  //       delete props.id;
  //     }
  //     sa.track(eventName, props);
  //   };
  // }()),

  register(userInfo) {
    // try {
    //   if (window.sa) {
    //     if (userInfo) {
    //       sa.login(userInfo.userid);
    //       hasRegister = true;
    //     } else {
    //       sa.logout();
    //     }
    //   }
    // } catch (err) {
    //   throw err;
    // }
    if (!__PRO__) {
      console.log('[Track注册]', userInfo);
    }
  },
};

getEventEmitterSingleton().on('register_tracker', BellCodeTracker.register);

export default BellCodeTracker;
