import React from 'react';
import PropTypes from 'prop-types';

import ReactModal from 'react-modal';
import Account from '../../public-repo/components/Account/index.jsx';
import '!style-loader!css-loader!../../public-repo/components/Account/index.css'; // eslint-disable-line
import '!style-loader!css-loader!material-components-web/dist/material-components-web.min.css'; // eslint-disable-line

import styles from './account-modal.css';

const AccountModal = ({title, id, onRequestClose, ...props}) => (
    <ReactModal
        isOpen
        className={styles.modalContent}
        contentLabel={title}
        // contentLabel={intl.formatMessage({...messages.label})}
        id={id}
        overlayClassName={styles.modalOverlay}
    >
        <span
            className={styles.iconCloseButton}
            onClick={onRequestClose}
        />
        <Account {...props} />
    </ReactModal>
);

AccountModal.propTypes = {
    id: PropTypes.string.isRequired,
    onRequestClose: PropTypes.func,
    title: PropTypes.string.isRequired
};

export default AccountModal;
