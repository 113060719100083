/**
 * 登录页面通用列表组件
 */
/* eslint-disable no-case-declarations */
import React from 'react';
import Config from 'PublicRepo/utility/generalConfig';
import MDCircularProgress from 'PublicRepo/material_components/loading';
import './universalClassList.less';

const listHeaderMap = {
  0: ['班级名称', '备注', '学生人数', '操作'],
  1: ['姓名', '账号', '昵称', '备注', '操作'],
};

const universalClassList = props => {
  const {
    listType,
    list = [],
    getClassStudent = () => {},
    onAssistStuLogin = () => {},
    isLoading = false,
    customBgColor = '#1fa1ff',
  } = props;

  const renderHeaderItem = item => (
    <div className="header-item" key={item}>
      {item}
    </div>
  );

  // 渲染列表内容
  const renderListItem = (item, index) => {
    switch (listType) {
      case Config.loginListTypeMap.TEACHER:
        const {
          ClassName,
          remark,
          TotalStudent,
          max_user_count,
          ID: classId,
        } = item;
        return (
          <div key={index} className="class-list-item">
            <div className="item name">{ClassName}</div>
            <div className="item name">{remark}</div>
            <div className="item">{`${TotalStudent}/${max_user_count}`}</div>
            <div className="item">
              <div
                style={{ backgroundColor: customBgColor }}
                className="item-button"
                onClick={() => {
                  TotalStudent && getClassStudent(classId);
                }}
              >
                选择
              </div>
            </div>
          </div>
        );
      case Config.loginListTypeMap.STUDENT:
        const {
 real_name, user_name, nick_name, remark: studentRemark,
} = item;
        return (
          <div key={index} className="class-list-item">
            <div className="item name">{real_name}</div>
            <div className="item name">{user_name}</div>
            <div className="item name">{nick_name}</div>
            <div className="item name">{studentRemark}</div>
            <div className="item">
              <div
                style={{ backgroundColor: customBgColor }}
                className="item-button"
                onClick={() => {
                  onAssistStuLogin(item);
                }}
              >
                登录
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="class-list-ct">
      <header className="class-list-header">
        {listHeaderMap[listType].map(item => renderHeaderItem(item))}
      </header>
      <div className="class-list-content">
        {!isLoading ? (
          list.map((item, index) => renderListItem(item, index))
        ) : (
          <div className="loadingCon">
            <MDCircularProgress size={60} style={{ color: '#8a53aa' }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default universalClassList;
