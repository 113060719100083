import React, { Component } from 'react';
import PropTypes from 'prop-types';

import i18n from 'PublicRepo/i18n/common';
import UserData from 'PublicRepo/utility/userDataManager';
import MDCircularProgress from 'PublicRepo/material_components/loading';
import SnackBar from 'PublicRepo/components/Material/snackBar';
import Config from 'PublicRepo/utility/generalConfig';

class WechatSignIn extends Component {
  static propTypes = {
    activatedTeacher: PropTypes.bool,
    onGetWechatQrcode: PropTypes.func,
    onWechatSignIn: PropTypes.func,
    showOrgTeacherSignIn: PropTypes.func,
    data: PropTypes.shape({
      ticket: PropTypes.string,
      url: PropTypes.string,
      isLoading: PropTypes.bool,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      ticket: '',
      qrCodeExpired: false, // 二维码是否过期
    };
  }

  componentDidMount() {
    this.setTimeRun();
  }

  componentWillUnmount() {
    this.setTimeEnd();
  }

  setTimeRun() {
    this.setState({
      qrCodeExpired: false,
    });
    this.setTimeEnd();
    this.props.onGetWechatQrcode();
    this.checkClock = setInterval(() => {
      this.checkQrLogin();
    }, 1000);
    this.timeClock = setTimeout(() => {
      clearInterval(this.checkClock);
      this.setState({
        qrCodeExpired: true,
      });
    }, 60000);
  }

  setTimeEnd() {
    clearInterval(this.checkClock);
    clearTimeout(this.timeClock);
  }

  checkQrLogin() {
    const { ticket } = this.state;
    if (!ticket && !this.props.data.ticket) return;
    UserData.checkQrLogin(ticket || this.props.data.ticket).then(data => {
      switch (data && data.loginType) {
        case Config.wechatQrState.SUCCESS: {
          this.props.onWechatSignIn && this.props.onWechatSignIn();

          break;
        }

        case Config.wechatQrState.UNBIND: {
          const bindedData = {
            message: i18n.get('Login-This WeChat ID has been bound'),
            actionText: i18n.get('Close'),
            timeout: 2000,
            actionHandler() {},
          };
          this.mdc.snackbar.show(bindedData);

          break;
        }

        case Config.wechatQrState.USED: {
          const usedData = {
            message: i18n.get('Login-This WeChat ID has been used'),
            actionText: i18n.get('Close'),
            timeout: 2000,
            actionHandler() {},
          };
          this.mdc.snackbar.show(usedData);

          break;
        }

        case Config.wechatQrState.ORG_TEACHER: {
          this.props.showOrgTeacherSignIn(data.info);

          break;
        }
        case Config.wechatQrState.NOTBELONG: {
          const ticketValue = data.info && data.info.target_ticket;
          this.setState({ ticket: ticketValue });

          break;
        }
      }
    });
  }

  render() {
    const { qrCodeExpired } = this.state;
    return (
      <div className="con">
        <div className="wechat-login">
          {this.props.data.isLoading ? (
            <div className="qrcode-loading-ct">
              <MDCircularProgress size={60} style={{ color: '#8a53aa' }} />
            </div>
          ) : (
            <div className="wechat-qrcode-ct">
              <img
                className="wechat-qrcode-img"
                src={this.props.data.url}
                alt=""
              />
            </div>
          )}

          {this.props.activatedTeacher ? (
            <p className="wechat-text">
              已激活的教师账号只能用【微信扫码】登录哦~
            </p>
          ) : !qrCodeExpired ? (
            <div>
              <p className="wechat-text">
                {i18n.get('Login-Login or register using WeChat scan')}
              </p>
              <p className="wechat-text-p">
                {i18n.get(
                  "Login-If you don't have WeChat, ask your mom or dad for help",
                )}
              </p>
            </div>
          ) : (
            <div>
              <p className="wechat-text">
                {i18n.get('Login-QR code has expired')}
              </p>
              <p className="wechat-text">
                {i18n.get('Login-Please click')}
                <span
                  className="wechat-refresh"
                  onClick={() => this.setTimeRun()}
                >
                  {i18n.get('Login-refresh')}
                </span>
              </p>
            </div>
          )}
        </div>
        <SnackBar ref={mdc => (this.mdc = mdc)} />
      </div>
    );
  }
}

export default WechatSignIn;
