import commonStyleDefine from '../common/style';

const definedStyle = commonStyleDefine();

export default {
    ...definedStyle,
    init() {},
    buildStatementTexturePath(target, width, height) {
        return `
            <path fill="${target.getColourTertiary()}" d="${this.getBottomPath(
            target,
            width,
            height
        ) + this.getRoundPath(target, width, height)}"/>
            <path fill="none" stroke="${target.getColourTertiary()}" stroke-width="1" d="${this.getBottomBranchPath(
            target,
            width,
            height
        ) + this.getLeftBranchPath(target, width, height)}" />
        `;
    },
    buildHatTexturePath(target, width, height) {
        return `
            <path fill="${target.getColourTertiary()}" d="${`${this.getHatRegularHexagonPath()}`}" />
            <path fill="none" stroke="${target.getColourTertiary()}" 
                stroke-width="1" d="${this.getHatRegularHexagonBranchPath()}" />
            <path fill="#FFFFFF" d="${this.getHatRoundTrianglePath()}" />
            <path fill="${target.getColourTertiary()}" d="${this.getHatBottomPath(
            target,
            width,
            height
        )}" />
            <path fill="none" stroke="${target.getColourTertiary()}" stroke-width="1" d="${this.getHatBottomBranchPath(
            target,
            width,
            height
        )}" />
        `;
    },
    buildBooleanTexturePath(target, width, height) {
        return `
            <path fill="${target.getColourTertiary()}" d="${this.getBottomPolygonPath(
            target,
            width,
            height
        )}" />
        `;
    },
    buildNumberStringTexturePath(target, width, height) {
        return `
            <path fill="${target.getColourTertiary()}" d="${this.getBottomRoundPath(
            target,
            width,
            height
        )}" />
        `;
    },
    buildEndTexturePath(target, width, height) {
        return `
            <path fill="${target.getColourTertiary()}" d="${this.getShapeEndBottomPath(
            target,
            width,
            height
        ) + this.getRoundPath(target, width, height)}" />
            <path fill="none" stroke="${target.getColourTertiary()}" 
                stroke-width="1" d="${this.getShapeEndBottomBranchPath(
                    target,
                    width,
                    height
                ) + this.getShapeEndLeftBranchPath(target, width, height)}" />
        `;
    }
};
