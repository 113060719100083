import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'PublicRepo/i18n/common';
import { USER } from 'PublicRepo/lib/config';

const UserType = props => (
  <div className="dialog-create-free-account">
    <div className="con">
      <div className="create-account-head">
        <div className="create-account-title">
          {i18n.get('Create Your FREE Account')}
        </div>
        <div className="create-account-tip">
          {i18n.get(
            "You can't change the account type later, please choose carefully!",
          )}
        </div>
      </div>
      <div className="create-account-body">
        <div
          role="button"
          tabIndex="0"
          className="create-account-stu"
          onClick={() => {
            props.onSelect(USER.TYPE.STUDENT);
          }}
        >
          <div className="create-account-stu-img" />
          <div className="create-account-stu-title">{i18n.get('Student')}</div>
          <div className="create-account-stu-tip">
            {i18n.get('Work on school/home courses and build cool projects')}
          </div>
        </div>
        <div
          role="button"
          tabIndex="0"
          className="create-account-teacher"
          onClick={() => {
            props.onSelect(USER.TYPE.TEACHER);
          }}
        >
          <div className="create-account-teacher-img" />
          <div className="create-account-teacher-title">
            {i18n.get('Teacher')}
          </div>
          <div className="create-account-teacher-tip">
            {i18n.get('Manage classrooms,lesson and student')}
          </div>
        </div>
        <span
          role="button"
          tabIndex="0"
          className="btn-have-account"
          onClick={props.routeToSignIn}
        >
          {i18n.get('I have a bellcode account')}
        </span>
      </div>
    </div>
  </div>
);

UserType.propTypes = {
  onSelect: PropTypes.func.isRequired,
  routeToSignIn: PropTypes.func.isRequired,
};

export default UserType;
