import log from '../../../../lib/log';

/**
 * Transform vm execution mechanism into project mode with "start,stop,freeze" method.
 * @param {VirtualMachine} vm Instance of VirtualMachine.
 * @return {{
 *      isFreezing: boolean,
 *      isPlaying: boolean,
 *      startProject: function,
 *      stopProject: function,
 *      freezeProject: function,
 *      eraseProject: function
 * }} Return project mode controller.
 */
export default function projectModeWrapper(vm) {
    const PROJECT_RUNTIME_KEYS = [
        'x',
        'y',
        'direction',
        'size',
        'draggable',
        'visible',
        'currentCostume',
        'effects',
        'rotationStyle',
        'variables',
        'lists',
        '_customState'
    ];

    const _startTimer = () => {
        vm.runtime.ioDevices.clock._pausedTime = vm.runtime.ioDevices.clock._projectTimer.timeElapsed();
        vm.runtime.ioDevices.clock.resume();
    };

    // hack to disable block 'event_whengreaterthan' until start project by keeping timer be '-Infinity'.
    const _stopTimer = () => {
        vm.runtime.ioDevices.clock.pause();
        vm.runtime.ioDevices.clock._pausedTime = Number.NEGATIVE_INFINITY;
    };

    let _runtimeDataCache = null;
    const saveRuntimeData = () => {
        const data = {};

        vm.runtime.targets.forEach(target => {
            const objTemp = {};
            PROJECT_RUNTIME_KEYS.forEach(key => {
                objTemp[key] = target[key];
            });

            data[target.id] = JSON.stringify(objTemp);
        });

        _runtimeDataCache = data;
    };

    const restoreRuntimeData = () => {
        const setVariable = vm.runtime.getOpcodeFunction('data_setvariableto');
        const getListLength = vm.runtime.getOpcodeFunction('data_lengthoflist');
        const deleteList = vm.runtime.getOpcodeFunction('data_deleteoflist');
        const addList = vm.runtime.getOpcodeFunction('data_addtolist');
        const restoreRuntimeVariable = (target, cachedVariables) => {
            // variable should be a instance of Variable.
            // The data saved for recover is just a object.
            if (cachedVariables) {
                Object.keys(cachedVariables).forEach(variableId => {
                    switch (cachedVariables[variableId].type) {
                        case 'broadcast_msg':
                            // do nothing
                            break;
                        case '':
                            setVariable(
                                {
                                    VARIABLE: {
                                        id: cachedVariables[variableId].id,
                                        name: cachedVariables[variableId].name
                                    },
                                    VALUE: cachedVariables[variableId].value
                                },
                                {target}
                            );
                            break;
                        case 'list':
                            {
                                const listLength = getListLength(
                                    {
                                        LIST: {
                                            id: cachedVariables[variableId].id,
                                            name:
                                                cachedVariables[variableId].name
                                        }
                                    },
                                    {target}
                                );

                                // delete all exist lists
                                for (let i = listLength; i > 0; i--) {
                                    deleteList(
                                        {
                                            LIST: {
                                                id:
                                                    cachedVariables[variableId]
                                                        .id,
                                                name:
                                                    cachedVariables[variableId]
                                                        .name
                                            },
                                            INDEX: i
                                        },
                                        {target}
                                    );
                                }

                                cachedVariables[variableId].value.forEach(
                                    value => {
                                        addList(
                                            {
                                                LIST: {
                                                    id:
                                                        cachedVariables[
                                                            variableId
                                                        ].id,
                                                    name:
                                                        cachedVariables[
                                                            variableId
                                                        ].name
                                                },
                                                ITEM: value
                                            },
                                            {target}
                                        );
                                    }
                                );
                            }
                            break;
                        default:
                            log.warn(
                                `Unknown variable type "${cachedVariables[variableId].type}"`
                            );
                    }
                });
            }
        };

        const penUp = vm.runtime.getOpcodeFunction('pen_penUp');
        const isExtensionPenLoaded = vm.extensionManager.isExtensionLoaded(
            'pen'
        );
        const restoreRuntimeCustomState = (target, cachedCustomState) => {
            if (isExtensionPenLoaded) {
                const penState = target.getCustomState('Scratch.pen') || {};
                const cachedPenState = cachedCustomState['Scratch.pen'] || {};

                if (penState.penDown && !cachedPenState.penDown) {
                    penUp(null, {target});
                }
            }

            delete cachedCustomState['Scratch.looks'];
            target._customState = cachedCustomState;
        };

        vm.runtime.targets.forEach(target => {
            const cachedData = JSON.parse(_runtimeDataCache[target.id]);

            if (!cachedData) return;

            const {variables, _customState, ...baseCachedData} = cachedData;

            Object.assign(target, baseCachedData);
            restoreRuntimeCustomState(target, _customState);
            restoreRuntimeVariable(target, variables);

            target.updateAllDrawableProperties();
        });

        if (isExtensionPenLoaded) {
            vm.runtime.getOpcodeFunction('pen_clear')({isDispose: true});
        }
    };

    return {
        isFreezing: false,
        isPlaying: false,
        startProject() {
            if (this.isPlaying) return;
            if (this.isFreezing) {
                this.stopProject();
            }

            this.isPlaying = true;

            saveRuntimeData();

            vm.greenFlag();
            _startTimer();
        },
        stopProject() {
            if (!this.isFreezing) {
                if (!this.isPlaying) return;

                vm.stopAll();
                _stopTimer();
            }

            this.isPlaying = false;
            this.isFreezing = false;
            restoreRuntimeData();
        },
        eraseProject(isTouchedGreenFlag) {
            if (!isTouchedGreenFlag) {
                vm.stopAll();
                _stopTimer();
                return;
            }
            if (!this.isFreezing) {
                if (this.isPlaying) {
                    vm.stopAll();
                    _stopTimer();
                }
            }
            this.isPlaying = false;
            this.isFreezing = false;
            restoreRuntimeData();
        },
        freezeProject() {
            if (!this.isPlaying) return;

            this.isFreezing = true;
            vm.stopAll();
            _stopTimer();
        },
        goonProject(isTouchedGreenFlag) {
            if (!this.isFreezing) return;

            this.isPlaying = true;
            this.isFreezing = false;
            if (!isTouchedGreenFlag) {
                saveRuntimeData();
            }
            vm.greenFlag();
            _startTimer();
        },
        saveRuntimeData
    };
}
