exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".guide-bubble_guide-bubble_15Ngr {\n    position: absolute;\n    top: -1000px;\n    left: -1000px;\n    z-index: 300;\n    white-space: nowrap;\n    background-color: #fff5d9;\n    border-radius: 8px;\n    color: #9f5f1e;\n    padding: 10px 20px;\n    font-size: 16px;\n    font-weight: normal;\n    border: 2px solid #ffa62b;\n}\n.guide-bubble_guide-bubble_15Ngr .guide-bubble_triangle_MJQkn {\n    position: absolute;\n    display: block;\n    width: 10px;\n    height: 10px;\n    -webkit-transform: rotate(45deg);\n        -ms-transform: rotate(45deg);\n            transform: rotate(45deg);\n    border: 2px solid #ffa62b;\n    background-color: #fff5d9;\n}\n.guide-bubble_guide-bubble_15Ngr.guide-bubble_arrow-left_3_2Sz .guide-bubble_triangle_MJQkn {\n    left: 12px;\n}\n.guide-bubble_guide-bubble_15Ngr.guide-bubble_bottom_1sq-r .guide-bubble_triangle_MJQkn {\n    top: -7px;\n    border-width: 2px 0 0 2px;\n}\n.guide-bubble_guide-bubble_15Ngr.guide-bubble_arrow-center_lHh2v .guide-bubble_triangle_MJQkn {\n    left: calc(50% - 5px);\n}\n", ""]);

// exports
exports.locals = {
	"guide-bubble": "guide-bubble_guide-bubble_15Ngr",
	"guideBubble": "guide-bubble_guide-bubble_15Ngr",
	"triangle": "guide-bubble_triangle_MJQkn",
	"arrow-left": "guide-bubble_arrow-left_3_2Sz",
	"arrowLeft": "guide-bubble_arrow-left_3_2Sz",
	"bottom": "guide-bubble_bottom_1sq-r",
	"arrow-center": "guide-bubble_arrow-center_lHh2v",
	"arrowCenter": "guide-bubble_arrow-center_lHh2v"
};