import ScratchBlocks from 'scratch-blocks';

/**
 * Apply custom blocks definitions.
 * @param {object} customs Custom blocks definitions.
 * @param {VirtualMachine}vm Instance of VirtualMachine.
 */
export default function applyCustom(customs, vm) {
    Object.keys(customs).forEach(opCode => {
        const custom = customs[opCode];

        if (custom.hasOwnProperty('json')) {
            ScratchBlocks.Blocks[opCode] = {
                init() {
                    this.jsonInit(
                        (typeof custom.json).toLowerCase() === 'function'
                            ? custom.json()
                            : custom.json
                    );
                }
            };
        }

        if (custom.hasOwnProperty('primitive')) {
            vm.runtime._primitives[opCode] = custom.primitive;
        }
    });
}
