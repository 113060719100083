/* eslint-disable */

export default {
    en: {
        'gui.alerts.lostPeripheralConnection':
            'Scratch lost connection to {extensionName}.',
        'gui.alerts.tryAgain': 'Try Again',
        'gui.alerts.download': 'Download',
        'gui.connection.reconnect': 'Reconnect',
        'gui.backpack.header': 'Backpack',
        'gui.backpack.errorBackpack': 'Error loading backpack',
        'gui.backpack.loadingBackpack': 'Loading...',
        'gui.backpack.more': 'More',
        'gui.backpack.emptyBackpack': 'Backpack is empty',
        'gui.unsupportedBrowser.label': 'Browser is not supported',
        'gui.unsupportedBrowser.errorLabel': 'An Error Occurred',
        'gui.unsupportedBrowser.notRecommended':
            'We are very sorry, but it looks like you are using a browser version that Scratch does not support. We recommend updating to the latest version of a supported browser such as Google Chrome, Mozilla Firefox, Microsoft Edge, or Apple Safari.',
        'gui.unsupportedBrowser.description':
            'We are very sorry, but Scratch does not support this browser. We recommend updating to the latest version of a supported browser such as Google Chrome, Mozilla Firefox, Microsoft Edge, or Apple Safari.',
        'gui.unsupportedBrowser.back': 'Back',
        'gui.unsupportedBrowser.previewfaq':
            'To learn more, go to the {previewFaqLink}.',
        'gui.unsupportedBrowser.previewfaqlinktext': 'FAQ',
        'gui.cameraModal.cameraModalTitle': 'Take a Photo',
        'gui.cameraModal.loadingCameraMessage': 'Loading Camera...',
        'gui.cameraModal.permissionRequest':
            'We need your permission to use your camera',
        'gui.cameraModal.retakePhoto': 'Retake Photo',
        'gui.cameraModal.save': 'Save',
        'gui.cameraModal.takePhoto': 'Take Photo',
        'gui.cameraModal.loadingCaption': 'Loading...',
        'gui.cameraModal.enableCameraCaption': 'Enable Camera',
        'gui.cards.all-tutorials': 'Tutorials',
        'gui.cards.shrink': 'Shrink',
        'gui.cards.expand': 'Expand',
        'gui.cards.close': 'Close',
        'gui.cards.more-things-to-try': 'More things to try!',
        'gui.cards.see-more': 'See more',
        'gui.comingSoon.message1': "Don't worry, we're on it {emoji}",
        'gui.comingSoon.message2': 'Coming Soon...',
        'gui.comingSoon.message3': "We're working on it {emoji}",
        'gui.connection.auto-scanning.noPeripheralsFound': 'No devices found',
        'gui.connection.auto-scanning.prescan':
            'Have your device nearby, then begin searching.',
        'gui.connection.auto-scanning.pressbutton':
            'Press the button on your device.',
        'gui.connection.auto-scanning.start-search': 'Start Searching',
        'gui.connection.connecting-searchbutton': 'Searching...',
        'gui.connection.auto-scanning.try-again': 'Try again',
        'gui.connection.connected': 'Connected',
        'gui.connection.disconnect': 'Disconnect',
        'gui.connection.go-to-editor': 'Go to Editor',
        'gui.connection.connecting-cancelbutton': 'Connecting...',
        'gui.connection.error.errorMessage':
            'Oops, looks like something went wrong.',
        'gui.connection.error.tryagainbutton': 'Try again',
        'gui.connection.error.helpbutton': 'Help',
        'gui.connection.peripheral-name-label': 'Device name',
        'gui.connection.connect': 'Connect',
        'gui.connection.scanning.lookingforperipherals': 'Looking for devices',
        'gui.connection.scanning.noPeripheralsFound': 'No devices found',
        'gui.connection.scanning.instructions':
            'Select your device in the list above.',
        'gui.connection.search': 'Refresh',
        'gui.connection.unavailable.installscratchlink':
            'Make sure you have Scratch Link installed and running',
        'gui.connection.unavailable.enablebluetooth':
            'Check that Bluetooth is enabled',
        'gui.connection.unavailable.tryagainbutton': 'Try again',
        'gui.connection.unavailable.helpbutton': 'Help',
        'gui.controls.go': 'Go',
        'gui.controls.stop': 'Stop',
        'gui.controls.erase': 'Erase',
        'gui.crashMessage.label': 'Oops! Something went wrong.',
        'gui.crashMessage.description':
            'We are so sorry, but it looks like Scratch has crashed. This bug has been automatically reported to the Scratch Team. Please refresh your page to try again.',
        'gui.crashMessage.errorNumber':
            'Your error was logged with id {errorId}',
        'gui.crashMessage.reload': 'Reload',
        'gui.customProcedures.myblockModalTitle': 'Make a Block',
        'gui.customProcedures.addAnInputNumberText': 'Add an input',
        'gui.customProcedures.numberTextType': 'number or text',
        'gui.customProcedures.addAnInputBoolean': 'Add an input',
        'gui.customProcedures.booleanType': 'boolean',
        'gui.customProcedures.addALabel': 'Add a label',
        'gui.customProcedures.runWithoutScreenRefresh':
            'Run without screen refresh',
        'gui.customProcedures.cancel': 'Cancel',
        'gui.customProcedures.ok': 'OK',
        'gui.SpriteInfo.direction': 'Direction',
        'gui.directionPicker.rotationStyles.allAround': 'All Around',
        'gui.directionPicker.rotationStyles.leftRight': 'Left/Right',
        'gui.directionPicker.rotationStyles.dontRotate': 'Do not rotate',
        'gui.gui.addExtension': 'Add Extension',
        'gui.gui.codeTab': 'Code',
        'gui.gui.backdropsTab': 'Backdrops',
        'gui.gui.costumesTab': 'Costumes',
        'gui.gui.soundsTab': 'Sounds',
        'gui.extensionLibrary.comingSoon': 'Coming Soon',
        'gui.extensionLibrary.requires': 'Requires',
        'gui.extensionLibrary.collaboration': 'Collaboration with',
        'gui.library.filterPlaceholder': 'Search',
        'gui.library.allTag': 'All',
        'gui.loader.message1': 'Creating blocks …',
        'gui.loader.message2': 'Loading sprites …',
        'gui.loader.message3': 'Loading sounds …',
        'gui.loader.message4': 'Loading extensions …',
        'gui.loader.message5': 'Herding cats …',
        'gui.loader.message6': 'Transmitting nanos …',
        'gui.loader.message7': 'Inflating gobos …',
        'gui.loader.message8': 'Preparing emojis …',
        'gui.loader.headline': 'Loading Project',
        'gui.loader.creating': 'Creating Project',
        'gui.accountMenu.profile': 'Profile',
        'gui.accountMenu.myStuff': 'My Stuff',
        'gui.accountMenu.myClasses': 'My Classes',
        'gui.accountMenu.myClass': 'My Class',
        'gui.accountMenu.accountSettings': 'Account settings',
        'gui.accountMenu.signOut': 'Sign out',
        'gui.authorInfo.byUser': 'by {username}',
        'gui.menuBar.seeProjectPage': 'See Project Page',
        'general.username': 'Username',
        'general.password': 'Password',
        'general.signIn': 'Sign in',
        'login.needHelp': 'Need Help?',
        'form.validationRequired': 'This field is required',
        'gui.menuBar.LanguageSelector': 'language selector',
        'gui.menuBar.tutorialsLibrary': 'Tutorials',
        'gui.menuBar.restoreSprite': 'Restore Sprite',
        'gui.menuBar.restoreSound': 'Restore Sound',
        'gui.menuBar.restoreCostume': 'Restore Costume',
        'gui.menuBar.restore': 'Restore',
        'gui.menuBar.saveNow': 'Save now',
        'gui.menuBar.saveAsCopy': 'Save as a copy',
        'gui.menuBar.remix': 'Remix',
        'gui.menuBar.new': 'New',
        'gui.menuBar.file': 'File',
        'gui.menuBar.downloadToComputer': 'Save to your computer',
        'gui.menuBar.edit': 'Edit',
        'gui.menuBar.turboModeOff': 'Turn off Turbo Mode',
        'gui.menuBar.turboModeOn': 'Turn on Turbo Mode',
        'gui.menuBar.joinScratch': 'Join Scratch',
        'gui.menuBar.signIn': 'Sign in',
        'gui.gui.projectTitlePlaceholder': 'Project title here',
        'gui.menuBar.saveNowLink': 'Save Now',
        'gui.menuBar.isShared': 'Shared',
        'gui.menuBar.share': 'Share',
        'gui.modal.help': 'Help',
        'gui.modal.back': 'Back',
        'gui.monitor.listMonitor.empty': '(empty)',
        'gui.monitor.listMonitor.listLength': 'length {length}',
        'gui.monitor.contextMenu.default': 'normal readout',
        'gui.monitor.contextMenu.large': 'large readout',
        'gui.monitor.contextMenu.slider': 'slider',
        'gui.monitor.contextMenu.sliderRange': 'change slider range',
        'gui.monitor.contextMenu.import': 'import',
        'gui.monitor.contextMenu.export': 'export',
        'gui.playButton.play': 'Play',
        'gui.playButton.stop': 'Stop',
        'gui.gui.variableScopeOptionAllSprites': 'For all sprites',
        'gui.gui.variableScopeOptionSpriteOnly': 'For this sprite only',
        'gui.gui.cloudVariableOption': 'Cloud variable (stored on server)',
        'gui.gui.variablePromptAllSpritesMessage':
            'This variable will be available to all sprites.',
        'gui.prompt.cancel': 'Cancel',
        'gui.prompt.ok': 'OK',
        'gui.playbackStep.stopMsg': 'Stop',
        'gui.playbackStep.playMsg': 'Play',
        'gui.playbackStep.loadingMsg': 'Loading...',
        'gui.playbackStep.saveMsg': 'Save',
        'gui.playbackStep.reRecordMsg': 'Re-record',
        'gui.recordModal.title': 'Record Sound',
        'gui.recordingStep.beginRecord':
            'Begin recording by clicking the button below',
        'gui.recordingStep.permission':
            '{arrow}We need your permission to use your microphone',
        'gui.recordingStep.stop': 'Stop recording',
        'gui.recordingStep.record': 'Record',
        'gui.sliderModal.min': 'Minimum value',
        'gui.sliderModal.max': 'Maximum value',
        'gui.sliderModal.title': 'Change slider range',
        'gui.sliderPrompt.cancel': 'Cancel',
        'gui.sliderPrompt.ok': 'OK',
        'gui.soundEditor.sound': 'Sound',
        'gui.soundEditor.play': 'Play',
        'gui.soundEditor.stop': 'Stop',
        'gui.soundEditor.copy': 'Copy',
        'gui.soundEditor.paste': 'Paste',
        'gui.soundEditor.copyToNew': 'Copy to New',
        'gui.soundEditor.delete': 'Delete',
        'gui.soundEditor.save': 'Save',
        'gui.soundEditor.undo': 'Undo',
        'gui.soundEditor.redo': 'Redo',
        'gui.soundEditor.faster': 'Faster',
        'gui.soundEditor.slower': 'Slower',
        'gui.soundEditor.echo': 'Echo',
        'gui.soundEditor.robot': 'Robot',
        'gui.soundEditor.louder': 'Louder',
        'gui.soundEditor.softer': 'Softer',
        'gui.soundEditor.reverse': 'Reverse',
        'gui.soundEditor.fadeOut': 'Fade out',
        'gui.soundEditor.fadeIn': 'Fade in',
        'gui.soundEditor.mute': 'Mute',
        'gui.SpriteInfo.spritePlaceholder': 'Name',
        'gui.SpriteInfo.sprite': 'Sprite',
        'gui.SpriteInfo.show': 'Show',
        'gui.SpriteInfo.size': 'Size',
        'gui.spriteSelectorItem.contextMenuDuplicate': 'duplicate',
        'gui.spriteSelectorItem.contextMenuExport': 'export',
        'gui.spriteSelectorItem.contextMenuDelete': 'delete',
        'gui.spriteSelector.addSpriteFromLibrary': 'Choose a Sprite',
        'gui.spriteSelector.addSpriteFromPaint': 'Paint',
        'gui.spriteSelector.addSpriteFromSurprise': 'Surprise',
        'gui.spriteSelector.addSpriteFromFile': 'Upload Sprite',
        'gui.stageHeader.stageSizeLarge': 'Switch to large stage',
        'gui.stageHeader.stageSizeSmall': 'Switch to small stage',
        'gui.stageHeader.stageSizeFull': 'Enter full screen mode',
        'gui.stageHeader.stageSizeUnFull': 'Exit full screen mode',
        'gui.stageHeader.fullscreenControl': 'Full Screen Control',
        'gui.spriteSelector.addBackdropFromLibrary': 'Choose a Backdrop',
        'gui.stageSelector.addBackdropFromPaint': 'Paint',
        'gui.stageSelector.addBackdropFromSurprise': 'Surprise',
        'gui.stageSelector.addBackdropFromFile': 'Upload Backdrop',
        'gui.stageSelector.stage': 'Stage',
        'gui.stageSelector.backdrops': 'Backdrops',
        'gui.telemetryOptIn.label': 'Report statistics to improve Scratch',
        'gui.telemetryOptIn.body1':
            'The Scratch Team is always looking to better understand how Scratch is used around the world. To help support this effort, you can allow Scratch to automatically send usage information to the Scratch Team.',
        'gui.telemetryOptIn.body2':
            'The information we collect includes language selection, blocks usage, and some events like saving, loading, and uploading a project. We DO NOT collect any personal information. Please see our {privacyPolicyLink} for more information.',
        'gui.telemetryOptIn.privacyPolicyLink': 'Privacy Policy',
        'gui.telemetryOptIn.buttonTextNo': 'No, thanks',
        'gui.telemetryOptIn.buttonTooltipNo': 'Disable telemetry',
        'gui.telemetryOptIn.buttonTextYes':
            "Yes, I'd like to help improve Scratch",
        'gui.telemetryOptIn.buttonTooltipYes': 'Enable telemetry',
        'gui.turboMode.active': 'Turbo Mode',
        'gui.webglModal.label': 'Your Browser Does Not Support WebGL',
        'gui.webglModal.description':
            'Unfortunately it looks like your browser or computer {webGlLink}. This technology is needed for Scratch 3.0 to run.',
        'gui.webglModal.webgllink': 'does not support WebGL',
        'gui.webglModal.back': 'Back',
        'gui.webglModal.previewfaq':
            'To learn more, go to the {previewFaqLink}.',
        'gui.webglModal.previewfaqlinktext': 'FAQ',
        'gui.costumeLibrary.chooseABackdrop': 'Choose a Backdrop',
        'gui.costumeLibrary.chooseACostume': 'Choose a Costume',
        'gui.costumeTab.addBackdropFromLibrary': 'Choose a Backdrop',
        'gui.costumeTab.addCostumeFromLibrary': 'Choose a Costume',
        'gui.costumeTab.addBlankCostume': 'Paint',
        'gui.costumeTab.addSurpriseCostume': 'Surprise',
        'gui.costumeTab.addFileBackdrop': 'Upload Backdrop',
        'gui.costumeTab.addFileCostume': 'Upload Costume',
        'gui.costumeTab.addCameraCostume': 'Camera',
        'gui.extensionLibrary.chooseAnExtension': 'Choose an Extension',
        'gui.extensionLibrary.extensionUrl': 'Enter the URL of the extension',
        'gui.gui.defaultProjectTitle': 'Scratch Project',
        'gui.monitors.importListColumnPrompt':
            'Which column should be used (1-{numberOfColumns})?',
        'gui.recordingStep.alertMsg': 'Could not start recording',
        'gui.projectLoader.loadError':
            'The project file that was selected failed to load.',
        'gui.soundLibrary.chooseASound': 'Choose a Sound',
        'gui.soundTab.fileUploadSound': 'Upload Sound',
        'gui.soundTab.surpriseSound': 'Surprise',
        'gui.soundTab.recordSound': 'Record',
        'gui.soundTab.addSoundFromLibrary': 'Choose a Sound',
        'gui.spriteLibrary.chooseASprite': 'Choose a Sprite',
        'gui.tipsLibrary.tutorials': 'Choose a Tutorial',
        'gui.alerts.createsuccess': 'New project created.',
        'gui.alerts.createcopysuccess': 'Project saved as a copy.',
        'gui.alerts.createremixsuccess': 'Project saved as a remix.',
        'gui.alerts.creating': 'Creating new…',
        'gui.alerts.creatingCopy': 'Copying project…',
        'gui.alerts.creatingRemix': 'Remixing project…',
        'gui.alerts.creatingError':
            'Could not create the project. Please try again!',
        'gui.alerts.savingError': 'Project could not save.',
        'gui.alerts.savesuccess': 'Project saved.',
        'gui.alerts.saving': 'Saving project…',
        'gui.alerts.cloudInfo':
            'Please note, cloud variables only support numbers, not letters or symbols. {learnMoreLink}',
        'gui.alerts.cloudInfoLearnMore': 'Learn more.',
        'gui.alerts.importing': 'Importing…',
        'gui.defaultProject.meow': 'Meow',
        'gui.defaultProject.variable': 'my variable',
        'gui.howtos.intro-move-sayhello-hat.name': 'Getting Started',
        'gui.howtos.intro-move.step_stepMove': 'Add a move block',
        'gui.howtos.add-a-move-block.step_stepMoveSayHello':
            'Click the green flag to start',
        'gui.howtos.animate-a-name.name': 'Animate a Name',
        'gui.howtos.animate-a-name.step_AnimatePickLetter':
            'Pick a Letter Sprite',
        'gui.howtos.animate-a-name.step_AnimatePlaySound':
            'Play a Sound When Clicked',
        'gui.howtos.animate-a-name.step_AnimatePickLetter2':
            'Pick Another Letter Sprite',
        'gui.howtos.animate-a-name.step_AnimateChangeColor': 'Change color',
        'gui.howtos.animate-a-name.step_AnimateSpin':
            'Pick Another Letter Sprite & Make It Spin',
        'gui.howtos.animate-a-name.step_AnimateGrow':
            'Pick Another Letter Sprite & Make It Grow',
        'gui.howtos.imagine-if': 'Imagine If',
        'gui.howtos.imagine-if.step_imagineTypeWhatYouWant':
            'Type What You Want to Say',
        'gui.howtos.imagine-if.step_imagineClickGreenFlag':
            'Click the Green Flag to Start',
        'gui.howtos.imagine-if.step_imagineChooseBackdrop':
            'Choose Any Backdrop',
        'gui.howtos.imagine-if.step_imagineChooseSprite': 'Choose Any Sprite',
        'gui.howtos.imagine-if.step_imagineFlyAround':
            'Press the Space Key to Glide',
        'gui.howtos.imagine-if.step_imagineChooseAnotherSprite':
            'Choose Another Sprite',
        'gui.howtos.imagine-if.step_imagineLeftRight': 'Move Left-Right',
        'gui.howtos.imagine-if.step_imagineUpDown': 'Move Up-Down',
        'gui.howtos.imagine-if.step_imagineChangeCostumes': 'Change Costumes',
        'gui.howtos.imagine-if.step_imagineGlideToPoint': 'Glide to a Point',
        'gui.howtos.imagine-if.step_imagineGrowShrink': 'Grow and Shrink',
        'gui.howtos.imagine-if.step_imagineChooseAnotherBackdrop':
            'Choose Another Backdrop',
        'gui.howtos.imagine-if.step_imagineSwitchBackdrops': 'Switch Backdrops',
        'gui.howtos.imagine-if.step_imagineRecordASound': 'Add a Sound',
        'gui.howtos.imagine-if.step_imagineChooseSound': 'Choose Your Sound',
        'gui.howtos.make-music.name': 'Make Music',
        'gui.howtos.Make-Music.step_PickInstrument':
            'Pick an Instrument Sprite',
        'gui.howtos.Make-Music.step_PlaySoundClick': 'Play Sound When Clicked',
        'gui.howtos.Make-Music.step_MakeSong': 'Create a Song',
        'gui.howtos.make-music.step_MakeBeat': 'Choose a Drum & Make a Beat',
        'gui.howtos.make-music.step_MakeBeatBox':
            'Choose the Microphone Sprite & Surprise Beatbox',
        'gui.howtos.story.name': 'Create A Story',
        'gui.howtos.story.step_addbg': 'Add a Backdrop',
        'gui.howtos.story.step_addsprite': 'Add a Character',
        'gui.howtos.story.step_saysomething': 'Say Something',
        'gui.howtos.story.step_addanothersprite': 'Add Another Character',
        'gui.howtos.story.step_flip': 'Flip Direction',
        'gui.howtos.story.step_conversation': 'Have A Conversation',
        'gui.howtos.story.addanotherbg': 'Add Another Backdrop',
        'gui.howtos.story.step_swithbg': 'Switch Backdrops',
        'gui.howtos.story.step_hidewizard': 'Hide a Character',
        'gui.howtos.story.step_showwizard': 'Show a Character',
        'gui.howtos.make-a-chase-game.name': 'Make a Chase Game',
        'gui.howtos.Chase-Game.step_BG': 'Add a Backdrop',
        'gui.howtos.chase-game.step_AddOcto': 'Add a Sprite',
        'gui.howtos.make-music.step_LeftRight':
            'Move Right & Left With Arrow Keys',
        'gui.howtos.Chase-Game.step_UpDown': 'Move Up & Down With Arrow Keys',
        'gui.howtos.Chase-Game.step_AddStar': 'Add Another Sprite',
        'gui.howtos.Chase-Game.step_MoveRandom': 'Move Randomly',
        'gui.howtos.Chase-Game.step_WhenTouch':
            'In Octopus Sprite, When Touching Play Sound',
        'gui.howtos.Chase-Game.step_ScoreVariable': 'Create Score Variable',
        'gui.howtos.Chase-Game.ScoreWhenTouch':
            'In Octopus Sprite, When Touching Add Score',
        'gui.howtos.animate-char.name': 'Animate A Character',
        'gui.howtos.animate-char.step_addbg': 'Add a Backdrop',
        'gui.howtos.animate-char.step_addsprite': 'Add a Sprite',
        'gui.howtos.animate-char.step_saysomething': 'Say Something',
        'gui.howtos.animate-char.step_addsound': 'Add Sound',
        'gui.howtos.animate-char.step_animatetalking': 'Animate Talking',
        'gui.howtos.animate-char.step_arrowkeys': 'Move Using Arrow Keys',
        'gui.howtos.animate-char.step_jump': 'Jump',
        'gui.howtos.animate-char.step_changecolor': 'Change Color',
        'gui.howtos.make-a-game.name': 'Make a Clicker Game',
        'gui.howtos.Make-A-Game.step_GamePickSprite': 'Pick A Sprite',
        'gui.howtos.make-a-game.step_GamePlaySound': 'Play Sound When Clicked',
        'gui.howtos.make-a-game.step_GameAddScore': 'Create Score Variable',
        'gui.howtos.make-a-game.step_GameChangeScore':
            'When Clicked Increase Score',
        'gui.howtos.make-a-game.step_Random': 'Go to a random position',
        'gui.howtos.make-music.step_GameChangeColor': 'Change Color',
        'gui.howtos.make-music.step_ResetScore': 'Reset Score',
        'gui.howtos.make-it-fly.name': 'Make it Fly',
        'gui.howtos.fly.step_stepflyChooseBackdrop': 'Choose a Sky Background',
        'gui.howtos.add-a-move-block.step_stepflyChooseCharacter':
            'Choose a Character',
        'gui.howtos.fly.step_stepflySaySomething': 'Say Something',
        'gui.howtos.add-a-move-block.step_stepflyMoveArrows':
            'Move With Arrow Keys',
        'gui.howtos.fly.step_stepflyChooseObject':
            'Choose an Object to Collect',
        'gui.howtos.add-a-move-block.step_stepflyFlyingObject':
            'Make the Object Move',
        'gui.howtos.add-a-move-block.step_stepflySelectFlyingSprite':
            'Select Your Character',
        'gui.howtos.add-a-move-block.step_stepflyAddScore': 'Add a Score',
        'gui.howtos.add-a-move-block.step_stepflyKeepScore': 'Keep Score',
        'gui.howtos.add-a-move-block.step_stepflyAddScenery': 'Add Scenery',
        'gui.howtos.add-a-move-block.step_stepflyMoveScenery':
            'Move the Scenery',
        'gui.howtos.add-a-move-block.step_stepflySwitchLooks': 'Next Costume',
        'gui.howtos.pong': 'Pong Game',
        'gui.howtos.pong.step_pongAddBackdrop': 'Add a Backdrop',
        'gui.howtos.pong.step_pongAddBallSprite': 'Add a Ball Sprite',
        'gui.howtos.pong.step_pongBounceAround': 'Bounce Around',
        'gui.howtos.pong.step_pongAddPaddle': 'Add a Paddle',
        'gui.howtos.pong.step_pongMoveThePaddle': 'Move the Paddle',
        'gui.howtos.pong.step_pongSelectBallSprite': 'Select the Ball Sprite',
        'gui.howtos.pong.step_pongAddMoreCodeToBall':
            'Add Code to Bounce the Ball Off the Paddle',
        'gui.howtos.pong.step_pongAddAScore': 'Add a Score',
        'gui.howtos.pong.step_pongChooseScoreFromMenu':
            "Choose 'Score' from the Menu",
        'gui.howtos.pong.step_pongInsertChangeScoreBlock':
            "Insert the 'Change Score' Block",
        'gui.howtos.pong.step_pongResetScore': 'Reset Score',
        'gui.howtos.pong.step_pongAddLineSprite': 'Add the Line Sprite',
        'gui.howtos.pong.step_pongGameOver': 'Game Over',
        'gui.howtos.say-it-out-loud': 'Create Animations That Talk',
        'gui.howtos.say-it-out-loud.step_AddTXTextension':
            'Add the Text to Speech blocks',
        'gui.howtos.say-it-out-loud.step_TXTSpeech': 'Say Something',
        'gui.howtos.say-it-out-loud_TXTSetVoice': 'Set a Voice',
        'gui.howtos.say-it-out-loud.step_TXTMove': 'Move Around',
        'gui.howtos.say-it-out-loud.step_TXTBackdrop': 'Add a Backdrop',
        'gui.howtos.say-it-out-loud.step_TXTAddSprite': 'Add Another Character',
        'gui.howtos.say-it-out-loud.step_TXTSong': 'Perform a Song',
        'gui.howtos.say-it-out-loud.step_TXTColor': 'Change Color',
        'gui.howtos.say-it-out-loud.step_TXTSpin': 'Spin Around',
        'gui.howtos.say-it-out-loud.step_TXTGrow': 'Grow and Shrink',
        'gui.howtos.videosens.name': 'Video Sensing',
        'gui.howtos.videosens.step_addextension': 'Add Extension',
        'gui.howtos.videosens.step_pet': 'Pet the Cat',
        'gui.howtos.videosens.step_animate': 'Animate',
        'gui.howtos.videosens.step_pop': 'Pop a Balloon',
        'gui.howtos.cartoon-network': 'Animate an Adventure Game',
        'gui.howtos.cartoon-network.step_CNcharacter':
            'Choose a Character to Show',
        'gui.howtos.cartoon-network.step_CNsay': 'Say Something',
        'gui.howtos.cartoon-network.step_CNglide': 'Glide Around',
        'gui.howtos.cartoon-network.step_CNpicksprite':
            'Choose an Object to Chase',
        'gui.howtos.cartoon-network.step_CNcollect': 'Collect Objects',
        'gui.howtos.cartoon-network.step_CNvariable': 'Make a Score Variable',
        'gui.howtos.cartoon-network.step_CNscore': 'Keep Score',
        'gui.howtos.cartoon-network.step_CNbackdrop':
            'Level Up: Change Backdrop',
        'gui.howtos.add-sprite.name': 'Add a Sprite',
        'gui.howtos.add-sprite.step_addSprite': 'Add a Sprite',
        'gui.howtos.add-a-backdrop.name': 'Add a Backdrop',
        'gui.howtos.add-a-backdrop.step_addBackdrop': 'Add a Backdrop',
        'gui.howtos.change-size.name': 'Change Size',
        'gui.howtos.change-size.step_changeSize': 'Change Size',
        'gui.howtos.glide-around.name': 'Glide Around',
        'gui.howtos.change-size.step_glideAroundBackAndForth': 'Glide Around',
        'gui.howtos.change-size.step_glideAroundPoint': 'Glide to a Point',
        'gui.howtos.record-a-sound.name': 'Record a Sound',
        'gui.howtos.change-size.step_recordASoundSoundsTab':
            "Click on the 'Sounds' Tab",
        'gui.howtos.change-size.step_recordASoundClickRecord': "Click 'Record'",
        'gui.howtos.change-size.step_recordASoundPressRecordButton':
            'Press the Record Button',
        'gui.howtos.change-size.step_recordASoundChooseSound':
            'Choose Your Sound',
        'gui.howtos.change-size.step_recordASoundPlayYourSound':
            'Play Your Sound',
        'gui.howtos.spin-video.name': 'Make It Spin',
        'gui.howtos.change-size.step_spinTurn': 'Turn',
        'gui.howtos.change-size.step_spinPointInDirection': 'Set Direction',
        'gui.howtos.hide-and-show.name': 'Hide and Show',
        'gui.howtos.change-size.step_hideAndShow': 'Hide and Show',
        'gui.howtos.switch-costume.name': 'Animate a Sprite',
        'gui.howtos.change-size.step_switchCostumes': 'Animate a Sprite',
        'gui.howtos.move-around-with-arrow-keys.name': 'Use Arrow Keys',
        'gui.howtos.add-a-backdrop.step_moveArrowKeysLeftRight':
            'Move Left and Right',
        'gui.howtos.add-a-backdrop.step_moveArrowKeysUpDown':
            'Move Up and Down',
        'gui.howtos.add-effects.name': 'Add Effects',
        'gui.howtos.videosens.step_addEffects': 'Add Effects',
        'gui.extension.music.name': 'Music',
        'gui.extension.music.description': 'Play instruments and drums.',
        'gui.extension.pen.name': 'Pen',
        'gui.extension.pen.description': 'Draw with your sprites.',
        'gui.extension.videosensing.name': 'Video Sensing',
        'gui.extension.videosensing.description':
            'Sense motion with the camera.',
        'gui.extension.text2speech.name': 'Text to Speech',
        'gui.extension.text2speech.description': 'Make your projects talk.',
        'gui.extension.translate.name': 'Translate',
        'gui.extension.translate.description':
            'Translate text into many languages.',
        'gui.extension.makeymakey.description': 'Make anything into a key.',
        'gui.extension.microbit.description':
            'Connect your projects with the world.',
        'gui.extension.microbit.connectingMessage': 'Connecting',
        'gui.extension.ev3.description': 'Build interactive robots and more.',
        'gui.extension.ev3.connectingMessage':
            'Connecting. Make sure the pin on your EV3 is set to 1234.',
        'gui.extension.boost.description': 'Bring robotic creations to life.',
        'gui.extension.boost.connectingMessage': 'Connecting',
        'gui.extension.wedo2.description': 'Build with motors and sensors.',
        'gui.extension.wedo2.connectingMessage': 'Connecting',
        'gui.extension.gdxfor.description':
            'Sense push, pull, motion, and spin.',
        'gui.extension.gdxfor.connectingMessage': 'Connecting',
        'gui.libraryTags.all': 'All',
        'gui.libraryTags.animals': 'Animals',
        'gui.libraryTags.dance': 'Dance',
        'gui.libraryTags.effects': 'Effects',
        'gui.libraryTags.fantasy': 'Fantasy',
        'gui.libraryTags.fashion': 'Fashion',
        'gui.libraryTags.food': 'Food',
        'gui.libraryTags.indoors': 'Indoors',
        'gui.libraryTags.loops': 'Loops',
        'gui.libraryTags.music': 'Music',
        'gui.libraryTags.notes': 'Notes',
        'gui.libraryTags.outdoors': 'Outdoors',
        'gui.libraryTags.patterns': 'Patterns',
        'gui.libraryTags.people': 'People',
        'gui.libraryTags.percussion': 'Percussion',
        'gui.libraryTags.space': 'Space',
        'gui.libraryTags.sports': 'Sports',
        'gui.libraryTags.underwater': 'Underwater',
        'gui.libraryTags.voice': 'Voice',
        'gui.libraryTags.wacky': 'Wacky',
        'gui.libraryTags.animation': 'Animation',
        'gui.libraryTags.art': 'Art',
        'gui.libraryTags.games': 'Games',
        'gui.libraryTags.stories': 'Stories',
        'gui.libraryTags.letters': 'Letters',
        'gui.opcodeLabels.direction': 'direction',
        'gui.opcodeLabels.xposition': 'x position',
        'gui.opcodeLabels.yposition': 'y position',
        'gui.opcodeLabels.size': 'size',
        'gui.opcodeLabels.costumename': 'costume name',
        'gui.opcodeLabels.costumenumber': 'costume number',
        'gui.opcodeLabels.backdropname': 'backdrop name',
        'gui.opcodeLabels.backdropnumber': 'backdrop number',
        'gui.opcodeLabels.volume': 'volume',
        'gui.opcodeLabels.tempo': 'tempo',
        'gui.opcodeLabels.answer': 'answer',
        'gui.opcodeLabels.loudness': 'loudness',
        'gui.opcodeLabels.username': 'username',
        'gui.opcodeLabels.year': 'year',
        'gui.opcodeLabels.month': 'month',
        'gui.opcodeLabels.date': 'date',
        'gui.opcodeLabels.dayofweek': 'day of week',
        'gui.opcodeLabels.hour': 'hour',
        'gui.opcodeLabels.minute': 'minute',
        'gui.opcodeLabels.second': 'second',
        'gui.opcodeLabels.timer': 'timer',
        'gui.sharedMessages.backdrop': 'backdrop{index}',
        'gui.sharedMessages.costume': 'costume{index}',
        'gui.sharedMessages.sprite': 'Sprite{index}',
        'gui.sharedMessages.pop': 'pop',
        'gui.sharedMessages.replaceProjectWarning':
            'Replace contents of the current project?',
        'gui.sharedMessages.loadFromComputerTitle': 'Load from your computer',
        'boost.color.any': 'any color',
        'boost.color.black': 'black',
        'boost.color.blue': 'blue',
        'boost.color.green': 'green',
        'boost.color.red': 'red',
        'boost.color.white': 'white',
        'boost.color.yellow': 'yellow',
        'boost.getMotorPosition': 'motor [MOTOR_REPORTER_ID] position',
        'boost.getTiltAngle': 'tilt angle [TILT_DIRECTION]',
        'boost.motorDirection.backward': 'that way',
        'boost.motorDirection.forward': 'this way',
        'boost.motorDirection.reverse': 'reverse',
        'boost.motorOff': 'turn motor [MOTOR_ID] off',
        'boost.motorOn': 'turn motor [MOTOR_ID] on',
        'boost.motorOnFor': 'turn motor [MOTOR_ID] for [DURATION] seconds',
        'boost.motorOnForRotation':
            'turn motor [MOTOR_ID] for [ROTATION] rotations',
        'boost.seeingColor': 'seeing [COLOR] brick?',
        'boost.setLightHue': 'set light color to [HUE]',
        'boost.setMotorDirection':
            'set motor [MOTOR_ID] direction [MOTOR_DIRECTION]',
        'boost.setMotorPower': 'set motor [MOTOR_ID] speed to [POWER] %',
        'boost.tiltDirection.any': 'any',
        'boost.tiltDirection.down': 'down',
        'boost.tiltDirection.left': 'left',
        'boost.tiltDirection.right': 'right',
        'boost.tiltDirection.up': 'up',
        'boost.whenColor': 'when [COLOR] brick seen',
        'boost.whenTilted': 'when tilted [TILT_DIRECTION_ANY]',
        'ev3.beepNote': 'beep note [NOTE] for [TIME] secs',
        'ev3.buttonPressed': 'button [PORT] pressed?',
        'ev3.getBrightness': 'brightness',
        'ev3.getDistance': 'distance',
        'ev3.getMotorPosition': 'motor [PORT] position',
        'ev3.motorSetPower': 'motor [PORT] set power [POWER] %',
        'ev3.motorTurnClockwise':
            'motor [PORT] turn this way for [TIME] seconds',
        'ev3.motorTurnCounterClockwise':
            'motor [PORT] turn that way for [TIME] seconds',
        'ev3.whenBrightnessLessThan': 'when brightness < [DISTANCE]',
        'ev3.whenButtonPressed': 'when button [PORT] pressed',
        'ev3.whenDistanceLessThan': 'when distance < [DISTANCE]',
        'gdxfor.getAcceleration': 'acceleration [DIRECTION]',
        'gdxfor.getForce': 'force',
        'gdxfor.getSpin': 'spin speed [DIRECTION]',
        'gdxfor.getTilt': 'tilt angle [TILT]',
        'gdxfor.isFreeFalling': 'falling?',
        'gdxfor.isTilted': 'tilted [TILT]?',
        'gdxfor.pulled': 'pulled',
        'gdxfor.pushed': 'pushed',
        'gdxfor.shaken': 'shaken',
        'gdxfor.startedFalling': 'started falling',
        'gdxfor.tiltDirectionMenu.any': 'any',
        'gdxfor.tiltDirectionMenu.back': 'back',
        'gdxfor.tiltDirectionMenu.front': 'front',
        'gdxfor.tiltDirectionMenu.left': 'left',
        'gdxfor.tiltDirectionMenu.right': 'right',
        'gdxfor.turnedFaceDown': 'turned face down',
        'gdxfor.turnedFaceUp': 'turned face up',
        'gdxfor.whenForcePushedOrPulled': 'when force sensor [PUSH_PULL]',
        'gdxfor.whenGesture': 'when [GESTURE]',
        'gdxfor.whenTilted': 'when tilted [TILT]',
        'makeymakey.downArrow': 'down arrow',
        'makeymakey.downArrowShort': 'down',
        'makeymakey.leftArrow': 'left arrow',
        'makeymakey.leftArrowShort': 'left',
        'makeymakey.rightArrow': 'right arrow',
        'makeymakey.rightArrowShort': 'right',
        'makeymakey.spaceKey': 'space',
        'makeymakey.upArrow': 'up arrow',
        'makeymakey.upArrowShort': 'up',
        'makeymakey.whenKeyPressed': 'when [KEY] key pressed',
        'makeymakey.whenKeysPressedInOrder': 'when [SEQUENCE] pressed in order',
        'microbit.buttonsMenu.any': 'any',
        'microbit.clearDisplay': 'clear display',
        'microbit.defaultTextToDisplay': 'Hello!',
        'microbit.displaySymbol': 'display [MATRIX]',
        'microbit.displayText': 'display text [TEXT]',
        'microbit.gesturesMenu.jumped': 'jumped',
        'microbit.gesturesMenu.moved': 'moved',
        'microbit.gesturesMenu.shaken': 'shaken',
        'microbit.isButtonPressed': '[BTN] button pressed?',
        'microbit.isTilted': 'tilted [DIRECTION]?',
        'microbit.pinStateMenu.off': 'off',
        'microbit.pinStateMenu.on': 'on',
        'microbit.tiltAngle': 'tilt angle [DIRECTION]',
        'microbit.tiltDirectionMenu.any': 'any',
        'microbit.tiltDirectionMenu.back': 'back',
        'microbit.tiltDirectionMenu.front': 'front',
        'microbit.tiltDirectionMenu.left': 'left',
        'microbit.tiltDirectionMenu.right': 'right',
        'microbit.whenButtonPressed': 'when [BTN] button pressed',
        'microbit.whenGesture': 'when [GESTURE]',
        'microbit.whenPinConnected': 'when pin [PIN] connected',
        'microbit.whenTilted': 'when tilted [DIRECTION]',
        'music.categoryName': 'Music',
        'music.changeTempo': 'change tempo by [TEMPO]',
        'music.drumBass': '(2) Bass Drum',
        'music.drumBongo': '(13) Bongo',
        'music.drumCabasa': '(15) Cabasa',
        'music.drumClaves': '(9) Claves',
        'music.drumClosedHiHat': '(6) Closed Hi-Hat',
        'music.drumConga': '(14) Conga',
        'music.drumCowbell': '(11) Cowbell',
        'music.drumCrashCymbal': '(4) Crash Cymbal',
        'music.drumCuica': '(18) Cuica',
        'music.drumGuiro': '(16) Guiro',
        'music.drumHandClap': '(8) Hand Clap',
        'music.drumOpenHiHat': '(5) Open Hi-Hat',
        'music.drumSideStick': '(3) Side Stick',
        'music.drumSnare': '(1) Snare Drum',
        'music.drumTambourine': '(7) Tambourine',
        'music.drumTriangle': '(12) Triangle',
        'music.drumVibraslap': '(17) Vibraslap',
        'music.drumWoodBlock': '(10) Wood Block',
        'music.getTempo': 'tempo',
        'music.instrumentBass': '(6) Bass',
        'music.instrumentBassoon': '(14) Bassoon',
        'music.instrumentCello': '(8) Cello',
        'music.instrumentChoir': '(15) Choir',
        'music.instrumentClarinet': '(10) Clarinet',
        'music.instrumentElectricGuitar': '(5) Electric Guitar',
        'music.instrumentElectricPiano': '(2) Electric Piano',
        'music.instrumentFlute': '(12) Flute',
        'music.instrumentGuitar': '(4) Guitar',
        'music.instrumentMarimba': '(19) Marimba',
        'music.instrumentMusicBox': '(17) Music Box',
        'music.instrumentOrgan': '(3) Organ',
        'music.instrumentPiano': '(1) Piano',
        'music.instrumentPizzicato': '(7) Pizzicato',
        'music.instrumentSaxophone': '(11) Saxophone',
        'music.instrumentSteelDrum': '(18) Steel Drum',
        'music.instrumentSynthLead': '(20) Synth Lead',
        'music.instrumentSynthPad': '(21) Synth Pad',
        'music.instrumentTrombone': '(9) Trombone',
        'music.instrumentVibraphone': '(16) Vibraphone',
        'music.instrumentWoodenFlute': '(13) Wooden Flute',
        'music.midiPlayDrumForBeats': 'play drum [DRUM] for [BEATS] beats',
        'music.midiSetInstrument': 'set instrument to [INSTRUMENT]',
        'music.playDrumForBeats': 'play drum [DRUM] for [BEATS] beats',
        'music.playNoteForBeats': 'play note [NOTE] for [BEATS] beats',
        'music.restForBeats': 'rest for [BEATS] beats',
        'music.setInstrument': 'set instrument to [INSTRUMENT]',
        'music.setTempo': 'set tempo to [TEMPO]',
        'pen.categoryName': 'Pen',
        'pen.changeColorParam': 'change pen [COLOR_PARAM] by [VALUE]',
        'pen.changeHue': 'change pen color by [HUE]',
        'pen.changeShade': 'change pen shade by [SHADE]',
        'pen.changeSize': 'change pen size by [SIZE]',
        'pen.clear': 'erase all',
        'pen.colorMenu.brightness': 'brightness',
        'pen.colorMenu.color': 'color',
        'pen.colorMenu.saturation': 'saturation',
        'pen.colorMenu.transparency': 'transparency',
        'pen.penDown': 'pen down',
        'pen.penUp': 'pen up',
        'pen.setColor': 'set pen color to [COLOR]',
        'pen.setColorParam': 'set pen [COLOR_PARAM] to [VALUE]',
        'pen.setHue': 'set pen color to [HUE]',
        'pen.setShade': 'set pen shade to [SHADE]',
        'pen.setSize': 'set pen size to [SIZE]',
        'pen.stamp': 'stamp',
        'speech.defaultWhenIHearValue': "let''s go",
        'speech.extensionName': 'Speech to Text',
        'speech.listenAndWait': 'listen and wait',
        'speech.speechReporter': 'speech',
        'speech.whenIHear': 'when I hear [PHRASE]',
        'text2speech.alto': 'alto',
        'text2speech.categoryName': 'Text to Speech',
        'text2speech.defaultTextToSpeak': 'hello',
        'text2speech.giant': 'giant',
        'text2speech.kitten': 'kitten',
        'text2speech.setLanguageBlock': 'set language to [LANGUAGE]',
        'text2speech.setVoiceBlock': 'set voice to [VOICE]',
        'text2speech.speakAndWaitBlock': 'speak [WORDS]',
        'text2speech.squeak': 'squeak',
        'text2speech.tenor': 'tenor',
        'translate.categoryName': 'Translate',
        'translate.defaultTextToTranslate': 'hello',
        'translate.translateBlock': 'translate [WORDS] to [LANGUAGE]',
        'translate.viewerLanguage': 'language',
        'videoSensing.categoryName': 'Video Sensing',
        'videoSensing.direction': 'direction',
        'videoSensing.motion': 'motion',
        'videoSensing.off': 'off',
        'videoSensing.on': 'on',
        'videoSensing.onFlipped': 'on flipped',
        'videoSensing.setVideoTransparency':
            'set video transparency to [TRANSPARENCY]',
        'videoSensing.sprite': 'sprite',
        'videoSensing.stage': 'stage',
        'videoSensing.videoOn': 'video [ATTRIBUTE] on [SUBJECT]',
        'videoSensing.videoToggle': 'turn video [VIDEO_STATE]',
        'videoSensing.whenMotionGreaterThan': 'when video motion > [REFERENCE]',
        'wedo2.getDistance': 'distance',
        'wedo2.getTiltAngle': 'tilt angle [TILT_DIRECTION]',
        'wedo2.isTilted': 'tilted [TILT_DIRECTION_ANY]?',
        'wedo2.motorDirection.backward': 'that way',
        'wedo2.motorDirection.forward': 'this way',
        'wedo2.motorDirection.reverse': 'reverse',
        'wedo2.motorId.a': 'motor A',
        'wedo2.motorId.all': 'all motors',
        'wedo2.motorId.b': 'motor B',
        'wedo2.motorId.default': 'motor',
        'wedo2.motorOff': 'turn [MOTOR_ID] off',
        'wedo2.motorOn': 'turn [MOTOR_ID] on',
        'wedo2.motorOnFor': 'turn [MOTOR_ID] on for [DURATION] seconds',
        'wedo2.playNoteFor': 'play note [NOTE] for [DURATION] seconds',
        'wedo2.setLightHue': 'set light color to [HUE]',
        'wedo2.setMotorDirection':
            'set [MOTOR_ID] direction to [MOTOR_DIRECTION]',
        'wedo2.startMotorPower': 'set [MOTOR_ID] power to [POWER]',
        'wedo2.tiltDirection.any': 'any',
        'wedo2.tiltDirection.down': 'down',
        'wedo2.tiltDirection.left': 'left',
        'wedo2.tiltDirection.right': 'right',
        'wedo2.tiltDirection.up': 'up',
        'wedo2.whenDistance': 'when distance [OP] [REFERENCE]',
        'wedo2.whenTilted': 'when tilted [TILT_DIRECTION_ANY]',
        'paint.paintEditor.hue': 'Color',
        'paint.paintEditor.saturation': 'Saturation',
        'paint.paintEditor.brightness': 'Brightness',
        'paint.paintEditor.fill': 'Fill',
        'paint.paintEditor.costume': 'Costume',
        'paint.paintEditor.group': 'Group',
        'paint.paintEditor.ungroup': 'Ungroup',
        'paint.paintEditor.undo': 'Undo',
        'paint.paintEditor.redo': 'Redo',
        'paint.paintEditor.forward': 'Forward',
        'paint.paintEditor.backward': 'Backward',
        'paint.paintEditor.front': 'Front',
        'paint.paintEditor.back': 'Back',
        'paint.paintEditor.more': 'More',
        'paint.modeTools.brushSize': 'Size',
        'paint.modeTools.eraserSize': 'Eraser size',
        'paint.modeTools.copy': 'Copy',
        'paint.modeTools.paste': 'Paste',
        'paint.modeTools.delete': 'Delete',
        'paint.modeTools.curved': 'Curved',
        'paint.modeTools.pointed': 'Pointed',
        'paint.modeTools.thickness': 'Thickness',
        'paint.modeTools.flipHorizontal': 'Flip Horizontal',
        'paint.modeTools.flipVertical': 'Flip Vertical',
        'paint.modeTools.filled': 'Filled',
        'paint.modeTools.outlined': 'Outlined',
        'paint.paintEditor.bitmap': 'Convert to Bitmap',
        'paint.paintEditor.vector': 'Convert to Vector',
        'paint.paintEditor.stroke': 'Outline',
        'paint.brushMode.brush': 'Brush',
        'paint.eraserMode.eraser': 'Eraser',
        'paint.fillMode.fill': 'Fill',
        'paint.lineMode.line': 'Line',
        'paint.ovalMode.oval': 'Circle',
        'paint.rectMode.rect': 'Rectangle',
        'paint.reshapeMode.reshape': 'Reshape',
        'paint.roundedRectMode.roundedRect': 'Rounded Rectangle',
        'paint.selectMode.select': 'Select',
        'paint.textMode.text': 'Text',
        'paint.colorPicker.swap': 'Swap'
    },

    'zh-cn': {
        'gui.alerts.lostPeripheralConnection':
            'Scratch与{extensionName}的连接已断开。',
        'gui.alerts.tryAgain': '重试',
        'gui.alerts.download': '下载',
        'gui.connection.reconnect': '重新连接',
        'gui.backpack.header': '书包',
        'gui.backpack.errorBackpack': '载入书包时出错了',
        'gui.backpack.loadingBackpack': '载入中……',
        'gui.backpack.more': '更多',
        'gui.backpack.emptyBackpack': '书包是空的',
        'gui.unsupportedBrowser.label': '不支持当前浏览器',
        'gui.unsupportedBrowser.errorLabel': '出错了',
        'gui.unsupportedBrowser.notRecommended':
            '我们十分抱歉，Scratch不支持您正在使用的浏览器，我们建议您升级到我们所支持的浏览器的最新版本，例如Chrome、火狐、Edge或者Safari。',
        'gui.unsupportedBrowser.description':
            '我们十分抱歉，Scratch并不支持您当前使用的浏览器，我们建议您升级到我们所支持的浏览器的最新版，例如Chrome、火狐、Edge或者Safari。',
        'gui.unsupportedBrowser.back': '返回',
        'gui.unsupportedBrowser.previewfaq': '访问{previewFaqLink}，了解更多。',
        'gui.unsupportedBrowser.previewfaqlinktext': '常见问题',
        'gui.cameraModal.cameraModalTitle': '拍摄',
        'gui.cameraModal.loadingCameraMessage': '摄像头加载中……',
        'gui.cameraModal.permissionRequest': '需要你的同意才能使用摄像头',
        'gui.cameraModal.retakePhoto': '重新拍摄',
        'gui.cameraModal.save': '保存',
        'gui.cameraModal.takePhoto': '拍摄照片',
        'gui.cameraModal.loadingCaption': '载入中……',
        'gui.cameraModal.enableCameraCaption': '启用摄像头',
        'gui.cards.all-tutorials': '教程',
        'gui.cards.shrink': '收起',
        'gui.cards.expand': '展开',
        'gui.cards.close': '关闭',
        'gui.cards.more-things-to-try': '再试试这些！',
        'gui.cards.see-more': '更多技巧',
        'gui.comingSoon.message1': '别急，我们还在做这个 {emoji}',
        'gui.comingSoon.message2': '即将启用...',
        'gui.comingSoon.message3': '还在开发 {emoji}',
        'gui.connection.auto-scanning.noPeripheralsFound': '没有发现任何设备',
        'gui.connection.auto-scanning.prescan': '把设备放在边上，然后开始搜索',
        'gui.connection.auto-scanning.pressbutton': '按下设备上的按钮',
        'gui.connection.auto-scanning.start-search': '开始搜索',
        'gui.connection.connecting-searchbutton': '正在搜索……',
        'gui.connection.auto-scanning.try-again': '重试',
        'gui.connection.connected': '已连接',
        'gui.connection.disconnect': '断开连接',
        'gui.connection.go-to-editor': '返回编辑器',
        'gui.connection.connecting-cancelbutton': '正在连接……',
        'gui.connection.error.errorMessage': '呀，好像出错了。',
        'gui.connection.error.tryagainbutton': '重试',
        'gui.connection.error.helpbutton': '帮助',
        'gui.connection.peripheral-name-label': '设备名称',
        'gui.connection.connect': '连接',
        'gui.connection.scanning.lookingforperipherals': '正在查找设备',
        'gui.connection.scanning.noPeripheralsFound': '没有发现任何设备',
        'gui.connection.scanning.instructions': '选择上面列出的设备。',
        'gui.connection.search': '刷新',
        'gui.connection.unavailable.installscratchlink':
            '确保Scratch Link已安装并正在运行',
        'gui.connection.unavailable.enablebluetooth': '请检查蓝牙已经开启',
        'gui.connection.unavailable.tryagainbutton': '重试',
        'gui.connection.unavailable.helpbutton': '帮助',
        'gui.controls.go': '运行',
        'gui.controls.stop': '停止',
        'gui.controls.erase': '恢复',
        'gui.crashMessage.label': '呀，出错了。',
        'gui.crashMessage.description':
            '很抱歉，Scratch出故障了。这个问题已经自动报告给Scratch开发团队。请刷新页面再试试。',
        'gui.crashMessage.errorNumber': '该错误已记录，编号{errorId}',
        'gui.crashMessage.reload': '重新载入',
        'gui.customProcedures.myblockModalTitle': '制作新的积木',
        'gui.customProcedures.addAnInputNumberText': '添加输入项',
        'gui.customProcedures.numberTextType': '数字或文本',
        'gui.customProcedures.addAnInputBoolean': '添加输入项',
        'gui.customProcedures.booleanType': '布尔值',
        'gui.customProcedures.addALabel': '添加文本标签：',
        'gui.customProcedures.runWithoutScreenRefresh': '运行时不刷新屏幕',
        'gui.customProcedures.cancel': '取消',
        'gui.customProcedures.ok': '完成',
        'gui.SpriteInfo.direction': '方向',
        'gui.directionPicker.rotationStyles.allAround': '任意旋转',
        'gui.directionPicker.rotationStyles.leftRight': '左右翻转',
        'gui.directionPicker.rotationStyles.dontRotate': '不旋转',
        'gui.gui.addExtension': '添加扩展',
        'gui.gui.codeTab': '代码',
        'gui.gui.backdropsTab': '背景',
        'gui.gui.costumesTab': '造型',
        'gui.gui.soundsTab': '声音',
        'gui.extensionLibrary.comingSoon': '即将启用',
        'gui.extensionLibrary.requires': '系统需求',
        'gui.extensionLibrary.collaboration': '合作者',
        'gui.library.filterPlaceholder': '搜索',
        'gui.library.allTag': '所有',
        'gui.loader.message1': '正在创建积木……',
        'gui.loader.message2': '载入角色……',
        'gui.loader.message3': '载入声音……',
        'gui.loader.message4': '加载扩展……',
        'gui.loader.message5': '呼唤小猫……',
        'gui.loader.message6': '传送Nano……',
        'gui.loader.message7': '给Gobo充气 …',
        'gui.loader.message8': '准备表情……',
        'gui.loader.headline': '载入作品',
        'gui.loader.creating': '新建作品',
        'gui.accountMenu.profile': '个人资料',
        'gui.accountMenu.myStuff': '我的东西',
        'gui.accountMenu.myClasses': '我的所有班级',
        'gui.accountMenu.myClass': '我的班级',
        'gui.accountMenu.accountSettings': '账号设置',
        'gui.accountMenu.signOut': '登出',
        'gui.authorInfo.byUser': '作者{username}',
        'gui.menuBar.seeProjectPage': '查看作品页面',
        'general.username': '用户名',
        'general.password': '密码',
        'general.signIn': '登录',
        'login.needHelp': '需要帮助？',
        'form.validationRequired': '必填字段',
        'gui.menuBar.LanguageSelector': '语言菜单',
        'gui.menuBar.tutorialsLibrary': '教程',
        'gui.menuBar.restoreSprite': '复原删除的角色',
        'gui.menuBar.restoreSound': '复原删除的声音',
        'gui.menuBar.restoreCostume': '复原删除的造型',
        'gui.menuBar.restore': '恢复',
        'gui.menuBar.saveNow': '立即保存',
        'gui.menuBar.saveAsCopy': '保存副本',
        'gui.menuBar.remix': '改编',
        'gui.menuBar.new': '新作品',
        'gui.menuBar.file': '文件',
        'gui.menuBar.downloadToComputer': '保存到电脑',
        'gui.menuBar.edit': '编辑',
        'gui.menuBar.turboModeOff': '关闭加速模式',
        'gui.menuBar.turboModeOn': '打开加速模式',
        'gui.menuBar.joinScratch': '加入 Scratch',
        'gui.menuBar.signIn': '登录',
        'gui.gui.projectTitlePlaceholder': '在此填写作品标题',
        'gui.menuBar.saveNowLink': '立即保存',
        'gui.menuBar.isShared': '已分享',
        'gui.menuBar.share': '分享',
        'gui.modal.help': '帮助',
        'gui.modal.back': '返回',
        'gui.monitor.listMonitor.empty': '(空)',
        'gui.monitor.listMonitor.listLength': '长度{length}',
        'gui.monitor.contextMenu.default': '正常显示',
        'gui.monitor.contextMenu.large': '大字显示',
        'gui.monitor.contextMenu.slider': '滑杆',
        'gui.monitor.contextMenu.sliderRange': '改变滑块范围',
        'gui.monitor.contextMenu.import': '导入',
        'gui.monitor.contextMenu.export': '导出',
        'gui.playButton.play': '播放',
        'gui.playButton.stop': '停止',
        'gui.gui.variableScopeOptionAllSprites': '适用于所有角色',
        'gui.gui.variableScopeOptionSpriteOnly': '仅适用于当前角色',
        'gui.gui.cloudVariableOption': '云变量 (存储在服务器上)',
        'gui.gui.variablePromptAllSpritesMessage': '所有角色都可使用该变量。',
        'gui.prompt.cancel': '取消',
        'gui.prompt.ok': '确定',
        'gui.playbackStep.stopMsg': '停止',
        'gui.playbackStep.playMsg': '播放',
        'gui.playbackStep.loadingMsg': '载入中……',
        'gui.playbackStep.saveMsg': '保存',
        'gui.playbackStep.reRecordMsg': '重新录制',
        'gui.recordModal.title': '录制声音',
        'gui.recordingStep.beginRecord': '点击下方的按钮开始录制',
        'gui.recordingStep.permission':
            '{arrow}我们需要你的许可才能使用麦克风。',
        'gui.recordingStep.stop': '停止录制',
        'gui.recordingStep.record': '录制',
        'gui.sliderModal.min': '最小值',
        'gui.sliderModal.max': '最大值',
        'gui.sliderModal.title': '改变滑块范围',
        'gui.sliderPrompt.cancel': '取消',
        'gui.sliderPrompt.ok': '确定',
        'gui.soundEditor.sound': '声音',
        'gui.soundEditor.play': '播放',
        'gui.soundEditor.stop': '停止',
        'gui.soundEditor.copy': '复制',
        'gui.soundEditor.paste': '粘贴',
        'gui.soundEditor.copyToNew': '新拷贝',
        'gui.soundEditor.delete': '删除',
        'gui.soundEditor.save': '保存',
        'gui.soundEditor.undo': '撤销',
        'gui.soundEditor.redo': '重做',
        'gui.soundEditor.faster': '快一点',
        'gui.soundEditor.slower': '慢一点',
        'gui.soundEditor.echo': '回声',
        'gui.soundEditor.robot': '机械化',
        'gui.soundEditor.louder': '响一点',
        'gui.soundEditor.softer': '轻一点',
        'gui.soundEditor.reverse': '反转',
        'gui.soundEditor.fadeOut': '渐弱',
        'gui.soundEditor.fadeIn': '渐强',
        'gui.soundEditor.mute': '静音',
        'gui.SpriteInfo.spritePlaceholder': '名字',
        'gui.SpriteInfo.sprite': '角色',
        'gui.SpriteInfo.show': '显示',
        'gui.SpriteInfo.size': '大小',
        'gui.spriteSelectorItem.contextMenuDuplicate': '复制',
        'gui.spriteSelectorItem.contextMenuExport': '导出',
        'gui.spriteSelectorItem.contextMenuDelete': '删除',
        'gui.spriteSelector.addSpriteFromLibrary': '选择一个角色',
        'gui.spriteSelector.addSpriteFromPaint': '绘制',
        'gui.spriteSelector.addSpriteFromSurprise': '随机',
        'gui.spriteSelector.addSpriteFromFile': '上传角色',
        'gui.stageHeader.stageSizeLarge': '放大舞台',
        'gui.stageHeader.stageSizeSmall': '缩小舞台',
        'gui.stageHeader.stageSizeFull': '进入全屏模式',
        'gui.stageHeader.stageSizeUnFull': '退出全屏模式',
        'gui.stageHeader.fullscreenControl': '全屏模式',
        'gui.spriteSelector.addBackdropFromLibrary': '选择一个背景',
        'gui.stageSelector.addBackdropFromPaint': '绘制',
        'gui.stageSelector.addBackdropFromSurprise': '随机',
        'gui.stageSelector.addBackdropFromFile': '上传背景',
        'gui.stageSelector.stage': '舞台',
        'gui.stageSelector.backdrops': '背景',
        'gui.telemetryOptIn.label': '统计使用情况并发送给Scratch',
        'gui.telemetryOptIn.body1':
            '允许Scratch自动发送使用情况信息给Scratch团队，帮助我们了解Scratch在全世界的使用情况。',
        'gui.telemetryOptIn.body2':
            '我们收集的信息包括所选语言、积木使用情况以及诸如保存、加载和上传作品等功能的使用情况。我们不会收集任何个人信息。请查看我们的{privacyPolicyLink}了解更多信息。',
        'gui.telemetryOptIn.privacyPolicyLink': '隐私政策',
        'gui.telemetryOptIn.buttonTextNo': '谢谢，不用了。',
        'gui.telemetryOptIn.buttonTooltipNo': '不发送统计信息',
        'gui.telemetryOptIn.buttonTextYes': '好的，我愿意帮助改进Scratch',
        'gui.telemetryOptIn.buttonTooltipYes': '发送统计信息',
        'gui.turboMode.active': '加速模式',
        'gui.webglModal.label': '你的浏览器不支持WenGL',
        'gui.webglModal.description':
            '非常遗憾，你的电脑或浏览器{webGlLink}。Scratch 3.0需要该技术才能运行。',
        'gui.webglModal.webgllink': '不支持WebGL',
        'gui.webglModal.back': '返回',
        'gui.webglModal.previewfaq': '访问{previewFaqLink}，了解更多。',
        'gui.webglModal.previewfaqlinktext': '常见问题',
        'gui.costumeLibrary.chooseABackdrop': '选择一个背景',
        'gui.costumeLibrary.chooseACostume': '选择一个造型',
        'gui.costumeTab.addBackdropFromLibrary': '选择一个背景',
        'gui.costumeTab.addCostumeFromLibrary': '选择一个造型',
        'gui.costumeTab.addBlankCostume': '绘制',
        'gui.costumeTab.addSurpriseCostume': '随机',
        'gui.costumeTab.addFileBackdrop': '上传背景',
        'gui.costumeTab.addFileCostume': '上传造型',
        'gui.costumeTab.addCameraCostume': '摄像头',
        'gui.extensionLibrary.chooseAnExtension': '选择一个扩展',
        'gui.extensionLibrary.extensionUrl': '键入扩展的URL',
        'gui.gui.defaultProjectTitle': 'Scratch作品',
        'gui.monitors.importListColumnPrompt':
            '使用第几列（1-{numberOfColumns}）？',
        'gui.recordingStep.alertMsg': '无法启动录音',
        'gui.projectLoader.loadError': '加载作品文件失败。',
        'gui.soundLibrary.chooseASound': '选择一个声音',
        'gui.soundTab.fileUploadSound': '上传声音',
        'gui.soundTab.surpriseSound': '随机',
        'gui.soundTab.recordSound': '录制',
        'gui.soundTab.addSoundFromLibrary': '选择一个声音',
        'gui.spriteLibrary.chooseASprite': '选择一个角色',
        'gui.tipsLibrary.tutorials': '选择一个教程',
        'gui.alerts.createsuccess': '作品已创建。',
        'gui.alerts.createcopysuccess': '作品已保存为副本。',
        'gui.alerts.createremixsuccess': '改编作品已保存。',
        'gui.alerts.creating': '正在创建...',
        'gui.alerts.creatingCopy': '正在复制作品……',
        'gui.alerts.creatingRemix': '正在改编作品……',
        'gui.alerts.creatingError': '无法创建作品。 请再试一次！',
        'gui.alerts.savingError': '作品未能保存。',
        'gui.alerts.savesuccess': '作品已保存。',
        'gui.alerts.saving': '正在保存作品……',
        'gui.alerts.cloudInfo':
            '请注意，云变量只支持数字，不能存放字母和符号。{learnMoreLink}',
        'gui.alerts.cloudInfoLearnMore': '进一步了解。',
        'gui.alerts.importing': '正在导入……',
        'gui.defaultProject.meow': '喵',
        'gui.defaultProject.variable': '我的变量',
        'gui.howtos.intro-move-sayhello-hat.name': '入门',
        'gui.howtos.intro-move.step_stepMove': '添加「移动」积木',
        'gui.howtos.add-a-move-block.step_stepMoveSayHello': '点击绿旗运行',
        'gui.howtos.animate-a-name.name': '姓名动画',
        'gui.howtos.animate-a-name.step_AnimatePickLetter': '选一个字母角色',
        'gui.howtos.animate-a-name.step_AnimatePlaySound': '点击时播放声音',
        'gui.howtos.animate-a-name.step_AnimatePickLetter2': '另选一个字母角色',
        'gui.howtos.animate-a-name.step_AnimateChangeColor': '改变颜色',
        'gui.howtos.animate-a-name.step_AnimateSpin': '选一个字母，让它旋转',
        'gui.howtos.animate-a-name.step_AnimateGrow': '另选一个字母，让它变大',
        'gui.howtos.imagine-if': '想象一下',
        'gui.howtos.imagine-if.step_imagineTypeWhatYouWant': '键入想说的话',
        'gui.howtos.imagine-if.step_imagineClickGreenFlag': '点击绿旗开始运行',
        'gui.howtos.imagine-if.step_imagineChooseBackdrop': '选择任一背景',
        'gui.howtos.imagine-if.step_imagineChooseSprite': '选择任一角色',
        'gui.howtos.imagine-if.step_imagineFlyAround': '按空格键控制滑动',
        'gui.howtos.imagine-if.step_imagineChooseAnotherSprite': '另选一个角色',
        'gui.howtos.imagine-if.step_imagineLeftRight': '左右移动',
        'gui.howtos.imagine-if.step_imagineUpDown': '上下移动',
        'gui.howtos.imagine-if.step_imagineChangeCostumes': '改变造型',
        'gui.howtos.imagine-if.step_imagineGlideToPoint': '滑动到某处',
        'gui.howtos.imagine-if.step_imagineGrowShrink': '变大变小',
        'gui.howtos.imagine-if.step_imagineChooseAnotherBackdrop':
            '另选一个背景',
        'gui.howtos.imagine-if.step_imagineSwitchBackdrops': '切换背景',
        'gui.howtos.imagine-if.step_imagineRecordASound': '添加一个声音',
        'gui.howtos.imagine-if.step_imagineChooseSound': '录制自己的声音',
        'gui.howtos.make-music.name': '制作音乐',
        'gui.howtos.Make-Music.step_PickInstrument': '选一个乐器角色',
        'gui.howtos.Make-Music.step_PlaySoundClick': '点击时播放声音',
        'gui.howtos.Make-Music.step_MakeSong': '创作乐曲',
        'gui.howtos.make-music.step_MakeBeat': '选一种鼓，演奏鼓点',
        'gui.howtos.make-music.step_MakeBeatBox': '用麦克风角色演唱节奏口技',
        'gui.howtos.story.name': '创作故事',
        'gui.howtos.story.step_addbg': '添加一个背景',
        'gui.howtos.story.step_addsprite': '添加一个角色',
        'gui.howtos.story.step_saysomething': '让角色说些话',
        'gui.howtos.story.step_addanothersprite': '添加另一个角色',
        'gui.howtos.story.step_flip': '翻转方向',
        'gui.howtos.story.step_conversation': '进行对话',
        'gui.howtos.story.addanotherbg': '添加另一个背景',
        'gui.howtos.story.step_swithbg': '切换背景',
        'gui.howtos.story.step_hidewizard': '隐藏一个角色',
        'gui.howtos.story.step_showwizard': '展示一个角色',
        'gui.howtos.make-a-chase-game.name': '制作追赶游戏',
        'gui.howtos.Chase-Game.step_BG': '添加一个背景',
        'gui.howtos.chase-game.step_AddOcto': '添加一个角色',
        'gui.howtos.make-music.step_LeftRight': '用方向键控制左右移动',
        'gui.howtos.Chase-Game.step_UpDown': '用方向键控制上下移动',
        'gui.howtos.Chase-Game.step_AddStar': '添加另一个角色',
        'gui.howtos.Chase-Game.step_MoveRandom': '随机移动',
        'gui.howtos.Chase-Game.step_WhenTouch': '让章鱼被碰到的时候发出声音',
        'gui.howtos.Chase-Game.step_ScoreVariable': '建立一个得分变量',
        'gui.howtos.Chase-Game.ScoreWhenTouch': '让章鱼被碰到时，增加得分',
        'gui.howtos.animate-char.name': '角色动画',
        'gui.howtos.animate-char.step_addbg': '添加一个背景',
        'gui.howtos.animate-char.step_addsprite': '添加一个角色',
        'gui.howtos.animate-char.step_saysomething': '让角色说些话',
        'gui.howtos.animate-char.step_addsound': '添加声音',
        'gui.howtos.animate-char.step_animatetalking': '交谈动画',
        'gui.howtos.animate-char.step_arrowkeys': '使用方向键移动',
        'gui.howtos.animate-char.step_jump': '跳跃',
        'gui.howtos.animate-char.step_changecolor': '改变颜色',
        'gui.howtos.make-a-game.name': '制作消除游戏',
        'gui.howtos.Make-A-Game.step_GamePickSprite': '挑选一个角色',
        'gui.howtos.make-a-game.step_GamePlaySound': '点击时播放声音',
        'gui.howtos.make-a-game.step_GameAddScore': '建立一个得分变量',
        'gui.howtos.make-a-game.step_GameChangeScore': '被点击时增加分数',
        'gui.howtos.make-a-game.step_Random': '移动到随机位置',
        'gui.howtos.make-music.step_GameChangeColor': '改变颜色',
        'gui.howtos.make-music.step_ResetScore': '把得分清零',
        'gui.howtos.make-it-fly.name': '让角色飞行',
        'gui.howtos.fly.step_stepflyChooseBackdrop': '选一个天空的背景',
        'gui.howtos.add-a-move-block.step_stepflyChooseCharacter':
            '选择一个角色',
        'gui.howtos.fly.step_stepflySaySomething': '让角色说些话',
        'gui.howtos.add-a-move-block.step_stepflyMoveArrows':
            '用方向键控制移动',
        'gui.howtos.fly.step_stepflyChooseObject': '选择要采集的对象',
        'gui.howtos.add-a-move-block.step_stepflyFlyingObject': '让对象移动',
        'gui.howtos.add-a-move-block.step_stepflySelectFlyingSprite':
            '点选你的角色',
        'gui.howtos.add-a-move-block.step_stepflyAddScore': '添加分数变量',
        'gui.howtos.add-a-move-block.step_stepflyKeepScore': '保存成绩',
        'gui.howtos.add-a-move-block.step_stepflyAddScenery': '加入景物',
        'gui.howtos.add-a-move-block.step_stepflyMoveScenery': '让景物移动',
        'gui.howtos.add-a-move-block.step_stepflySwitchLooks': '换成下一个造型',
        'gui.howtos.pong': '乒乓球游戏',
        'gui.howtos.pong.step_pongAddBackdrop': '添加一个背景',
        'gui.howtos.pong.step_pongAddBallSprite': '添加球体角色',
        'gui.howtos.pong.step_pongBounceAround': '让球反弹',
        'gui.howtos.pong.step_pongAddPaddle': '添加滑板',
        'gui.howtos.pong.step_pongMoveThePaddle': '控制滑板移动',
        'gui.howtos.pong.step_pongSelectBallSprite': '点选球体角色',
        'gui.howtos.pong.step_pongAddMoreCodeToBall':
            '添加代码让球体碰到滑板反弹',
        'gui.howtos.pong.step_pongAddAScore': '添加分数变量',
        'gui.howtos.pong.step_pongChooseScoreFromMenu': '从菜单中选择「分数」',
        'gui.howtos.pong.step_pongInsertChangeScoreBlock':
            '插入「改变分数」积木',
        'gui.howtos.pong.step_pongResetScore': '把得分清零',
        'gui.howtos.pong.step_pongAddLineSprite': '添加线条角色',
        'gui.howtos.pong.step_pongGameOver': '碰到红线游戏结束',
        'gui.howtos.say-it-out-loud': '创建会说话的动画',
        'gui.howtos.say-it-out-loud.step_AddTXTextension':
            '添加文字朗读扩展积木',
        'gui.howtos.say-it-out-loud.step_TXTSpeech': '让角色说些话',
        'gui.howtos.say-it-out-loud_TXTSetVoice': '设置语音',
        'gui.howtos.say-it-out-loud.step_TXTMove': '四处移动',
        'gui.howtos.say-it-out-loud.step_TXTBackdrop': '添加一个背景',
        'gui.howtos.say-it-out-loud.step_TXTAddSprite': '添加另一个角色',
        'gui.howtos.say-it-out-loud.step_TXTSong': '演奏一首歌',
        'gui.howtos.say-it-out-loud.step_TXTColor': '改变颜色',
        'gui.howtos.say-it-out-loud.step_TXTSpin': '原地转动',
        'gui.howtos.say-it-out-loud.step_TXTGrow': '变大变小',
        'gui.howtos.videosens.name': '视频侦测',
        'gui.howtos.videosens.step_addextension': '添加扩展',
        'gui.howtos.videosens.step_pet': '爱抚猫咪',
        'gui.howtos.videosens.step_animate': '动画',
        'gui.howtos.videosens.step_pop': '戳破气球',
        'gui.howtos.cartoon-network': '冒险游戏动画',
        'gui.howtos.cartoon-network.step_CNcharacter': '选择要显示的角色',
        'gui.howtos.cartoon-network.step_CNsay': '让角色说些话',
        'gui.howtos.cartoon-network.step_CNglide': '滑来滑去',
        'gui.howtos.cartoon-network.step_CNpicksprite': '选择要捕捉的目标',
        'gui.howtos.cartoon-network.step_CNcollect': '收集物品',
        'gui.howtos.cartoon-network.step_CNvariable': '建立分数变量',
        'gui.howtos.cartoon-network.step_CNscore': '保存成绩',
        'gui.howtos.cartoon-network.step_CNbackdrop': '过关：改变背景',
        'gui.howtos.add-sprite.name': '添加一个角色',
        'gui.howtos.add-sprite.step_addSprite': '添加一个角色',
        'gui.howtos.add-a-backdrop.name': '添加一个背景',
        'gui.howtos.add-a-backdrop.step_addBackdrop': '添加一个背景',
        'gui.howtos.change-size.name': '变大变小',
        'gui.howtos.change-size.step_changeSize': '变大变小',
        'gui.howtos.glide-around.name': '滑来滑去',
        'gui.howtos.change-size.step_glideAroundBackAndForth': '滑来滑去',
        'gui.howtos.change-size.step_glideAroundPoint': '滑动到某处',
        'gui.howtos.record-a-sound.name': '录制声音',
        'gui.howtos.change-size.step_recordASoundSoundsTab':
            '点击「声音」标签页',
        'gui.howtos.change-size.step_recordASoundClickRecord': '点击「录制」',
        'gui.howtos.change-size.step_recordASoundPressRecordButton':
            '按下录制按钮',
        'gui.howtos.change-size.step_recordASoundChooseSound':
            '选择已录制的声音',
        'gui.howtos.change-size.step_recordASoundPlayYourSound':
            '播放录制的声音',
        'gui.howtos.spin-video.name': '让字母旋转',
        'gui.howtos.change-size.step_spinTurn': '转动',
        'gui.howtos.change-size.step_spinPointInDirection': '设置方向',
        'gui.howtos.hide-and-show.name': '隐藏和出现',
        'gui.howtos.change-size.step_hideAndShow': '隐藏和出现',
        'gui.howtos.switch-costume.name': '角色动画',
        'gui.howtos.change-size.step_switchCostumes': '角色动画',
        'gui.howtos.move-around-with-arrow-keys.name': '使用方向键',
        'gui.howtos.add-a-backdrop.step_moveArrowKeysLeftRight': '左右移动',
        'gui.howtos.add-a-backdrop.step_moveArrowKeysUpDown': '上下移动',
        'gui.howtos.add-effects.name': '添加一些效果',
        'gui.howtos.videosens.step_addEffects': '添加视觉效果',
        'gui.extension.music.name': '音乐',
        'gui.extension.music.description': '演奏乐器，敲锣打鼓。',
        'gui.extension.pen.name': '画笔',
        'gui.extension.pen.description': '绘制角色。',
        'gui.extension.videosensing.name': '视频侦测',
        'gui.extension.videosensing.description': '使用摄像头侦测运动。',
        'gui.extension.text2speech.name': '文字朗读',
        'gui.extension.text2speech.description': '让你的作品开口说话',
        'gui.extension.translate.name': '翻译',
        'gui.extension.translate.description': '把文字翻译成多种语言。',
        'gui.extension.makeymakey.description': '把任何东西变成按键',
        'gui.extension.microbit.description': '把作品连接到实体世界。',
        'gui.extension.microbit.connectingMessage': '正在连接',
        'gui.extension.ev3.description': '搭建交互机器人等。',
        'gui.extension.ev3.connectingMessage':
            '正在连接。请确保EV3上的识别码(PIN)已经设置为1234。',
        'gui.extension.boost.description': '生动有趣的机器人创作',
        'gui.extension.boost.connectingMessage': '正在连接',
        'gui.extension.wedo2.description': '支持马达和传感器。',
        'gui.extension.wedo2.connectingMessage': '正在连接',
        'gui.extension.gdxfor.description': '感受推拉动转。',
        'gui.extension.gdxfor.connectingMessage': '正在连接',
        'gui.libraryTags.all': '所有',
        'gui.libraryTags.animals': '动物',
        'gui.libraryTags.dance': '舞蹈',
        'gui.libraryTags.effects': '效果',
        'gui.libraryTags.fantasy': '奇幻',
        'gui.libraryTags.fashion': '时尚',
        'gui.libraryTags.food': '食物',
        'gui.libraryTags.indoors': '室内',
        'gui.libraryTags.loops': '可循环',
        'gui.libraryTags.music': '音乐',
        'gui.libraryTags.notes': '音符',
        'gui.libraryTags.outdoors': '户外',
        'gui.libraryTags.patterns': '图案',
        'gui.libraryTags.people': '人物',
        'gui.libraryTags.percussion': '打击乐器',
        'gui.libraryTags.space': '太空',
        'gui.libraryTags.sports': '运动',
        'gui.libraryTags.underwater': '水下',
        'gui.libraryTags.voice': '人声',
        'gui.libraryTags.wacky': '古怪',
        'gui.libraryTags.animation': '动画',
        'gui.libraryTags.art': '艺术',
        'gui.libraryTags.games': '游戏',
        'gui.libraryTags.stories': '故事',
        'gui.libraryTags.letters': '字母',
        'gui.opcodeLabels.direction': '方向',
        'gui.opcodeLabels.xposition': 'x 坐标',
        'gui.opcodeLabels.yposition': 'y 坐标',
        'gui.opcodeLabels.size': '大小',
        'gui.opcodeLabels.costumename': '造型名称',
        'gui.opcodeLabels.costumenumber': '造型编号',
        'gui.opcodeLabels.backdropname': '背景名称',
        'gui.opcodeLabels.backdropnumber': '背景编号',
        'gui.opcodeLabels.volume': '音量',
        'gui.opcodeLabels.tempo': '演奏速度',
        'gui.opcodeLabels.answer': '回答',
        'gui.opcodeLabels.loudness': '响度',
        'gui.opcodeLabels.username': '用户名',
        'gui.opcodeLabels.year': '年',
        'gui.opcodeLabels.month': '月',
        'gui.opcodeLabels.date': '日',
        'gui.opcodeLabels.dayofweek': '星期',
        'gui.opcodeLabels.hour': '时',
        'gui.opcodeLabels.minute': '分',
        'gui.opcodeLabels.second': '秒',
        'gui.opcodeLabels.timer': '计时器',
        'gui.sharedMessages.backdrop': '背景{index}',
        'gui.sharedMessages.costume': '造型{index}',
        'gui.sharedMessages.sprite': '角色{index}',
        'gui.sharedMessages.pop': '啵',
        'gui.sharedMessages.replaceProjectWarning':
            '是否丢弃当前作品中尚未保存的内容？',
        'gui.sharedMessages.loadFromComputerTitle': '从电脑中上传',
        'boost.color.any': '任何颜色',
        'boost.color.black': '黑色',
        'boost.color.blue': '蓝色',
        'boost.color.green': '绿色',
        'boost.color.red': '黄色',
        'boost.color.white': '白色',
        'boost.color.yellow': '黄色',
        'boost.getMotorPosition': '马达 [MOTOR_REPORTER_ID] 位置',
        'boost.getTiltAngle': '向[TILT_DIRECTION]倾角',
        'boost.motorDirection.backward': '逆时针',
        'boost.motorDirection.forward': '顺时针',
        'boost.motorDirection.reverse': '反转',
        'boost.motorOff': '关闭马达 [MOTOR_ID]',
        'boost.motorOn': '开启马达 [MOTOR_ID]',
        'boost.motorOnFor': '使马达 [MOTOR_ID]转动 [DURATION] 秒',
        'boost.motorOnForRotation': '使马达 [MOTOR_ID]转动 [ROTATION] 圈',
        'boost.seeingColor': '看到 [COLOR]积木 ?',
        'boost.setLightHue': '将灯光颜色设为[HUE]',
        'boost.setMotorDirection':
            '将马达[MOTOR_ID] 方向设为 [MOTOR_DIRECTION]',
        'boost.setMotorPower': '将马达 [MOTOR_ID] 速度设为 [POWER]%',
        'boost.tiltDirection.any': '任意方向',
        'boost.tiltDirection.down': '下',
        'boost.tiltDirection.left': '左',
        'boost.tiltDirection.right': '右',
        'boost.tiltDirection.up': '上',
        'boost.whenColor': '当看到[COLOR]积木',
        'boost.whenTilted': '当向[TILT_DIRECTION_ANY]倾斜',
        'ev3.beepNote': '鸣笛[NOTE][TIME]秒',
        'ev3.buttonPressed': '按下按钮[PORT]？',
        'ev3.getBrightness': '亮度',
        'ev3.getDistance': '距离',
        'ev3.getMotorPosition': '马达[PORT]的位置',
        'ev3.motorSetPower': '马达[PORT]功率设为[POWER]%',
        'ev3.motorTurnClockwise': '马达[PORT]正转[TIME]秒',
        'ev3.motorTurnCounterClockwise': '马达[PORT]反转[TIME]秒',
        'ev3.whenBrightnessLessThan': '当亮度 < [DISTANCE]',
        'ev3.whenButtonPressed': '当按下按钮[PORT]',
        'ev3.whenDistanceLessThan': '当距离 < [DISTANCE]',
        'gdxfor.getAcceleration': '向[DIRECTION]的加速度',
        'gdxfor.getForce': '受力',
        'gdxfor.getSpin': '向[DIRECTION]的旋转速度',
        'gdxfor.getTilt': '向[TILT]倾角',
        'gdxfor.isFreeFalling': '自由落体？',
        'gdxfor.isTilted': '向[TILT]倾斜？',
        'gdxfor.pulled': '拉扯',
        'gdxfor.pushed': '挤压',
        'gdxfor.shaken': '被晃动',
        'gdxfor.startedFalling': '开始自由落体',
        'gdxfor.tiltDirectionMenu.any': '任意方向',
        'gdxfor.tiltDirectionMenu.back': '后',
        'gdxfor.tiltDirectionMenu.front': '前',
        'gdxfor.tiltDirectionMenu.left': '左',
        'gdxfor.tiltDirectionMenu.right': '右',
        'gdxfor.turnedFaceDown': '正面向下',
        'gdxfor.turnedFaceUp': '正面向上',
        'gdxfor.whenForcePushedOrPulled': '当力传感器被[PUSH_PULL]',
        'gdxfor.whenGesture': '当传感器[GESTURE]',
        'gdxfor.whenTilted': '当向[TILT]倾斜',
        'makeymakey.downArrow': '↓',
        'makeymakey.downArrowShort': '下',
        'makeymakey.leftArrow': '←',
        'makeymakey.leftArrowShort': '左',
        'makeymakey.rightArrow': '→',
        'makeymakey.rightArrowShort': '右',
        'makeymakey.spaceKey': '空格',
        'makeymakey.upArrow': '↑',
        'makeymakey.upArrowShort': '上',
        'makeymakey.whenKeyPressed': '当按下 [KEY] 键',
        'makeymakey.whenKeysPressedInOrder': '当依次按下[SEQUENCE]键时',
        'microbit.buttonsMenu.any': '任意',
        'microbit.clearDisplay': '清空屏幕',
        'microbit.defaultTextToDisplay': 'Hello!',
        'microbit.displaySymbol': '显示[MATRIX]',
        'microbit.displayText': '显示文本[TEXT]',
        'microbit.gesturesMenu.jumped': '抛起',
        'microbit.gesturesMenu.moved': '移动',
        'microbit.gesturesMenu.shaken': '晃动',
        'microbit.isButtonPressed': '按下[BTN]按钮？',
        'microbit.isTilted': '向[DIRECTION]倾斜？',
        'microbit.pinStateMenu.off': '低电平',
        'microbit.pinStateMenu.on': '高电平',
        'microbit.tiltAngle': '向[DIRECTION]倾角',
        'microbit.tiltDirectionMenu.any': '任意',
        'microbit.tiltDirectionMenu.back': '后',
        'microbit.tiltDirectionMenu.front': '前',
        'microbit.tiltDirectionMenu.left': '左',
        'microbit.tiltDirectionMenu.right': '右',
        'microbit.whenButtonPressed': '当按下[BTN]按钮',
        'microbit.whenGesture': '当被[GESTURE]',
        'microbit.whenPinConnected': '当引脚[PIN]接地',
        'microbit.whenTilted': '当向[DIRECTION]倾斜',
        'music.categoryName': '音乐',
        'music.changeTempo': '将演奏速度增加[TEMPO]',
        'music.drumBass': '(2) 低音鼓',
        'music.drumBongo': '(13) 邦戈鼓',
        'music.drumCabasa': '(15) 卡巴萨',
        'music.drumClaves': '(9) 音棒',
        'music.drumClosedHiHat': '(6) 闭击踩镲',
        'music.drumConga': '(14) 康加鼓',
        'music.drumCowbell': '(11) 牛铃',
        'music.drumCrashCymbal': '(4) 碎音钹',
        'music.drumCuica': '(18) 锯加鼓',
        'music.drumGuiro': '(16) 刮瓜',
        'music.drumHandClap': '(8) 手掌',
        'music.drumOpenHiHat': '(5) 开击踩镲',
        'music.drumSideStick': '(3) 敲鼓边',
        'music.drumSnare': '(1) 小军鼓',
        'music.drumTambourine': '(7) 铃鼓',
        'music.drumTriangle': '(12) 三角铁',
        'music.drumVibraslap': '(17) 颤音器',
        'music.drumWoodBlock': '(10) 木鱼',
        'music.getTempo': '演奏速度',
        'music.instrumentBass': '(6) 贝斯',
        'music.instrumentBassoon': '(14) 巴松管',
        'music.instrumentCello': '(8) 大提琴',
        'music.instrumentChoir': '(15) 唱诗班',
        'music.instrumentClarinet': '(10) 单簧管',
        'music.instrumentElectricGuitar': '(5) 电吉他',
        'music.instrumentElectricPiano': '(2) 电钢琴',
        'music.instrumentFlute': '(12) 长笛',
        'music.instrumentGuitar': '(4) 吉他',
        'music.instrumentMarimba': '(19) 马林巴琴',
        'music.instrumentMusicBox': '(17) 八音盒',
        'music.instrumentOrgan': '(3) 风琴',
        'music.instrumentPiano': '(1) 钢琴',
        'music.instrumentPizzicato': '(7) 拨弦',
        'music.instrumentSaxophone': '(11) 萨克斯管',
        'music.instrumentSteelDrum': '(18) 钢鼓',
        'music.instrumentSynthLead': '(20) 合成主音',
        'music.instrumentSynthPad': '(21) 合成柔音',
        'music.instrumentTrombone': '(9) 长号',
        'music.instrumentVibraphone': '(16) 颤音琴',
        'music.instrumentWoodenFlute': '(13) 木长笛',
        'music.midiPlayDrumForBeats': '击打 [DRUM] [BEATS] 拍',
        'music.midiSetInstrument': '将乐器设为 [INSTRUMENT]',
        'music.playDrumForBeats': '击打 [DRUM] [BEATS] 拍',
        'music.playNoteForBeats': '演奏音符[NOTE][BEATS]拍',
        'music.restForBeats': '休止[BEATS]拍',
        'music.setInstrument': '将乐器设为 [INSTRUMENT]',
        'music.setTempo': '将演奏速度设定为[TEMPO]',
        'pen.categoryName': '画笔',
        'pen.changeColorParam': '将笔的 [COLOR_PARAM] 增加[VALUE]',
        'pen.changeHue': '将笔的颜色增加[HUE]',
        'pen.changeShade': '将笔的亮度增加[SHADE]',
        'pen.changeSize': '将笔的粗细增加[SIZE]',
        'pen.clear': '全部擦除',
        'pen.colorMenu.brightness': '亮度',
        'pen.colorMenu.color': '颜色',
        'pen.colorMenu.saturation': '饱和度',
        'pen.colorMenu.transparency': '透明度',
        'pen.penDown': '落笔',
        'pen.penUp': '抬笔',
        'pen.setColor': '将笔的颜色设为[COLOR]',
        'pen.setColorParam': '将笔的 [COLOR_PARAM] 设为[VALUE]',
        'pen.setHue': '将笔的颜色设为[HUE]',
        'pen.setShade': '将笔的亮度设为[SHADE]',
        'pen.setSize': '将笔的粗细设为[SIZE]',
        'pen.stamp': '图章',
        'speech.defaultWhenIHearValue': '开始',
        'speech.extensionName': '语音识别',
        'speech.listenAndWait': '听候语音输入',
        'speech.speechReporter': '语音输入',
        'speech.whenIHear': '当听到[PHRASE]',
        'text2speech.alto': '中音',
        'text2speech.categoryName': '文字朗读',
        'text2speech.defaultTextToSpeak': '你好',
        'text2speech.giant': '巨人',
        'text2speech.kitten': '小猫',
        'text2speech.setLanguageBlock': '将朗读语言设置为[LANGUAGE]',
        'text2speech.setVoiceBlock': '使用 [VOICE] 嗓音',
        'text2speech.speakAndWaitBlock': '朗读 [WORDS]',
        'text2speech.squeak': '尖细',
        'text2speech.tenor': '男高音',
        'translate.categoryName': '翻译',
        'translate.defaultTextToTranslate': '你好',
        'translate.translateBlock': '将 [WORDS] 译为 [LANGUAGE]',
        'translate.viewerLanguage': '访客语言',
        'videoSensing.categoryName': '视频侦测',
        'videoSensing.direction': '方向',
        'videoSensing.motion': '运动',
        'videoSensing.off': '关闭',
        'videoSensing.on': '开启',
        'videoSensing.onFlipped': '镜像开启',
        'videoSensing.setVideoTransparency': '将视频透明度设为[TRANSPARENCY]',
        'videoSensing.sprite': '角色',
        'videoSensing.stage': '舞台',
        'videoSensing.videoOn': '相对于[SUBJECT]的视频[ATTRIBUTE]',
        'videoSensing.videoToggle': '[VIDEO_STATE]摄像头',
        'videoSensing.whenMotionGreaterThan': '当视频运动 > [REFERENCE]',
        'wedo2.getDistance': '距离',
        'wedo2.getTiltAngle': '向[TILT_DIRECTION]倾角',
        'wedo2.isTilted': '向[TILT_DIRECTION_ANY]倾斜？',
        'wedo2.motorDirection.backward': '逆时针',
        'wedo2.motorDirection.forward': '顺时针',
        'wedo2.motorDirection.reverse': '反转',
        'wedo2.motorId.a': '马达A',
        'wedo2.motorId.all': '所有马达',
        'wedo2.motorId.b': '马达B',
        'wedo2.motorId.default': '马达',
        'wedo2.motorOff': '关闭[MOTOR_ID]',
        'wedo2.motorOn': '开启[MOTOR_ID]',
        'wedo2.motorOnFor': '开启[MOTOR_ID][DURATION]秒',
        'wedo2.playNoteFor': '演奏音符[NOTE][DURATION]秒',
        'wedo2.setLightHue': '将灯光颜色设为[HUE]',
        'wedo2.setMotorDirection': '将[MOTOR_ID]转向设为[MOTOR_DIRECTION]',
        'wedo2.startMotorPower': '将[MOTOR_ID]功率设为[POWER]',
        'wedo2.tiltDirection.any': '任意方向',
        'wedo2.tiltDirection.down': '下',
        'wedo2.tiltDirection.left': '左',
        'wedo2.tiltDirection.right': '右',
        'wedo2.tiltDirection.up': '上',
        'wedo2.whenDistance': '当距离[OP][REFERENCE]',
        'wedo2.whenTilted': '当向[TILT_DIRECTION_ANY]倾斜',
        'paint.paintEditor.hue': '颜色',
        'paint.paintEditor.saturation': '饱和度',
        'paint.paintEditor.brightness': '亮度',
        'paint.paintEditor.fill': '填充',
        'paint.paintEditor.costume': '造型',
        'paint.paintEditor.group': '组合',
        'paint.paintEditor.ungroup': '拆散',
        'paint.paintEditor.undo': '撤销',
        'paint.paintEditor.redo': '重做',
        'paint.paintEditor.forward': '往前放',
        'paint.paintEditor.backward': '往后放',
        'paint.paintEditor.front': '放最前面',
        'paint.paintEditor.back': '放最后面',
        'paint.paintEditor.more': '更多',
        'paint.modeTools.brushSize': '粗细',
        'paint.modeTools.eraserSize': '橡皮擦大小',
        'paint.modeTools.copy': '复制',
        'paint.modeTools.paste': '粘贴',
        'paint.modeTools.delete': '删除',
        'paint.modeTools.curved': '曲线',
        'paint.modeTools.pointed': '折线',
        'paint.modeTools.thickness': '粗细',
        'paint.modeTools.flipHorizontal': '水平翻转',
        'paint.modeTools.flipVertical': '垂直翻转',
        'paint.modeTools.filled': '实心',
        'paint.modeTools.outlined': '轮廓',
        'paint.paintEditor.bitmap': '转换为位图',
        'paint.paintEditor.vector': '转换为矢量图',
        'paint.paintEditor.stroke': '轮廓',
        'paint.brushMode.brush': '画笔',
        'paint.eraserMode.eraser': '橡皮擦',
        'paint.fillMode.fill': '填充',
        'paint.lineMode.line': '线段',
        'paint.ovalMode.oval': '圆',
        'paint.rectMode.rect': '矩形',
        'paint.reshapeMode.reshape': '变形',
        'paint.roundedRectMode.roundedRect': '圆角矩形',
        'paint.selectMode.select': '选择',
        'paint.textMode.text': '文本',
        'paint.colorPicker.swap': '交换'
    }
};
