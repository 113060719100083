import React from 'react';
import log from '../../lib/log';

import userDataManager from 'public-repo/utility/userDataManager';
import generalConfig from 'public-repo/utility/generalConfig';
import PuzzleLoading from '../components/puzzle-loading';
import {PostMessageToHost, IPC_EVENT} from 'public-repo/utility/bellcloudIPC';

const InjectionHOC = function(WrappedComponent, type) {
    class InjectionComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                projectId: null
            };

            this.updateProject = this.updateProject.bind(this);
        }

        componentDidMount() {
            Promise.all([
                import(`./injectors/${type}.jsx`),
                userDataManager.initUserInfo()
            ])
                .then(([data]) => {
                    if (type === 'puzzle2') {
                        PostMessageToHost(IPC_EVENT.enterPuzzle);
                    } else {
                        PostMessageToHost(IPC_EVENT.enterEditor);
                    }
                    const InjectorClass = data.default;

                    if (InjectorClass.NEED_LOGIN && !userDataManager.userInfo) {
                        location.href = `${generalConfig.HomeDomain}#login`;
                        return;
                    }

                    this.injector = new InjectorClass(type);
                    this.injector.inject(this.updateProject);
                })
                .catch(err => log.error(`Inject failed. ${err}`));
        }

        updateProject(id) {
            this.setState({
                projectId: id
            });
        }

        render() {
            if (!this.state.projectId && this.state.projectId !== 0) {
                if (type === 'puzzle2') {
                    return <PuzzleLoading />;
                }
                return null;
            }
            // if (type === 'puzzle2') {
            //     return <PuzzleLoading />;
            // }

            return (
                <WrappedComponent
                    hideIntro
                    injector={this.injector}
                    projectId={this.state.projectId}
                    {...this.props}
                />
            );
        }
    }

    return InjectionComponent;
};

export default InjectionHOC;
