const UPDATE_PROJECT_OWNER_ID = 'scratch-gui/UPDATE_PROJECT_OWNER_ID';

const initialState = {
    // isJunior: false,
    isPortrait: false,
    ownerId: null
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case UPDATE_PROJECT_OWNER_ID:
        return Object.assign(state, {
            ownerId: action.ownerId
        });
    default:
        return state;
    }
};

const updateProjectOwnerId = ownerId => ({
    type: UPDATE_PROJECT_OWNER_ID,
    ownerId
});

export {
    reducer as default,
    initialState as projectMetaInitialState,
    updateProjectOwnerId
};
