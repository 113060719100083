import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SignIn from './SignIn';
import UserType from './Usertype';
import SignUp from './SignUp';

import './index.css';

const STEP = {
  SIGN_IN: 1,
  USER_TYPE: 2,
  SIGN_UP: 3,
};

const EMPTY_FUNC = () => {};

class Account extends Component {
  static propTypes = {
    startStep: PropTypes.number,
    onSignIn: PropTypes.func,
    onLoginSuccess: PropTypes.func,
    onWechatSignIn: PropTypes.func,
    onSignUp: PropTypes.func,
    onTestUsername: PropTypes.func,
    onToggleAccountState: PropTypes.func,
    onClose: PropTypes.func,
    unbindWechat: PropTypes.bool,
    showAccount: PropTypes.bool,
    showCloseIcon: PropTypes.bool,
  };

  static defaultProps = {
    startStep: STEP.SIGN_IN,
    onSignIn: EMPTY_FUNC,
    onLoginSuccess: EMPTY_FUNC,
    onSignOut: EMPTY_FUNC,
    onSignUp: EMPTY_FUNC,
    onTestUsername: Promise.resolve(),
  };

  static STEP = STEP;

  constructor(props) {
    super(props);
    this.state = {
      step: props.startStep,
      userType: null,
    };

    this.routeToSignIn = this.routeToSignIn.bind(this);
    this.routeToUserType = this.routeToUserType.bind(this);
    this.handleUserTypeSelected = this.handleUserTypeSelected.bind(this);
  }

  route(step) {
    this.setState({
      step,
    });
  }

  routeToSignIn() {
    this.route(STEP.SIGN_IN);
  }

  routeToUserType() {
    this.route(STEP.USER_TYPE);
  }

  handleUserTypeSelected(userType) {
    this.setState({
      userType,
    });
    this.route(STEP.SIGN_UP);
  }

  render() {
    let contentChild;

    switch (this.state.step) {
      case STEP.SIGN_IN:
        contentChild = (
          <SignIn
            showCloseIcon={this.props.showCloseIcon}
            onClose={this.props.onClose}
            showAccount={this.props.showAccount}
            unbindWechat={this.props.unbindWechat}
            onLoginSuccess={this.props.onLoginSuccess}
            onSignIn={this.props.onSignIn}
            onWechatSignIn={this.props.onWechatSignIn}
            routeToUserType={this.routeToUserType}
            onToggleAccountState={this.props.onToggleAccountState}
          />
        );
        break;
      case STEP.USER_TYPE:
        contentChild = (
          <UserType
            onSelect={this.handleUserTypeSelected}
            routeToSignIn={this.routeToSignIn}
          />
        );
        break;
      case STEP.SIGN_UP:
        contentChild = (
          <SignUp
            userType={this.state.userType}
            onSignUp={this.props.onSignUp}
            onTestUsername={this.props.onTestUsername}
            routeToSignIn={this.routeToSignIn}
          />
        );
        break;
      default:
    }

    return contentChild;
  }
}

export default Account;
