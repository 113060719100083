import React, { Component } from 'react';
import { MDCSnackbar } from 'PublicRepo/node_modules/@material/snackbar/dist/mdc.snackbar.min';

class SnackBar extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.snackbar = new MDCSnackbar(document.querySelector('.mdc-snackbar'));
  }

  render() {
    return (
      <div className="mdc-snackbar">
        <div className="mdc-snackbar__text" />
        <div className="mdc-snackbar__action-wrapper">
          <button type="button" className="mdc-snackbar__action-button" />
        </div>
      </div>
    );
  }
}

export default SnackBar;
