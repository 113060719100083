import Blockly from 'scratch-blocks';
import buildImgUrl from '../utils/build-img-url';

const REDO_X = -200;
const UNDO_X = -270;

const REDO_offset_X = 138;
const UNDO_offset_X = 70;

const Y = 160;
let init = false;
export const createRedoUnDoButtons = () => {
    if (init) return;
    init = true;
    Blockly.ZoomControls.prototype.createDom = function() {
        const ws = this.workspace_;
        this.svgGroup_ = Blockly.utils.createSvgElement(
            'g',
            {class: 'blocklyZoom'},
            null
        );
        this.createZoomOutSvg_();
        this.createZoomInSvg_();
        this.createZoomResetSvg_();
        const self = this;
        ws.addChangeListener(() => {
            const setStackState = (el, type = 'undo') => {
                const typeMap = {
                    undo: 'hasUndoStack',
                    redo: 'hasRedoStack'
                };
                if (el) {
                    const left = ws?.getMetrics()?.viewWidth;
                    if (left) {
                        el.setAttribute(
                            'x',
                            -(
                                left -
                                (type === 'undo'
                                    ? UNDO_offset_X
                                    : REDO_offset_X)
                            )
                        );
                    }
                    const hasStack = el.getAttribute('class') === 'has-stack';
                    if (hasStack !== ws[typeMap[type]]()) {
                        el.setAttribute(
                            'class',
                            hasStack ? 'no-stack' : 'has-stack'
                        );
                    }
                }
            };
            setStackState(self.cmUndoElement);
            setStackState(self.cmRedoElement, 'redo');
        });
        const originUndo = ws.undo;
        ws.undo = function(a) {
            originUndo.call(this, a);
            self.cmRedoElement &&
                self.cmRedoElement.setAttribute(
                    'class',
                    ws.hasRedoStack() ? 'has-stack' : 'no-stack'
                );
            self.cmUndoElement &&
                self.cmUndoElement.setAttribute(
                    'class',
                    ws.hasUndoStack() ? 'has-stack' : 'no-stack'
                );
        };
        this.createRedoSvg_();
        this.createUndoSvg_();
        return this.svgGroup_;
    };
    Blockly.ZoomControls.prototype.createRedoSvg_ = function() {
        const ws = this.workspace_;

        const redoSvg = Blockly.utils.createSvgElement(
            'image',
            {
                width: this.WIDTH_,
                height: this.WIDTH_,
                x: REDO_X,
                y: Y
            },
            this.svgGroup_
        );
        redoSvg.setAttributeNS(
            'http://www.w3.org/1999/xlink',
            'xlink:href',
            buildImgUrl('motion_turnright_deg', 'png')
        );
        this.cmRedoElement = redoSvg;
        this.cmRedoElement.setAttribute('class', 'no-stack');

        // Attach event listeners.
        Blockly.bindEventWithChecks_(redoSvg, 'mousedown', null, e => {
            ws.markFocused();
            ws.undo(true);
            Blockly.Touch.clearTouchIdentifier(); // Don't block future drags.
            e.stopPropagation(); // Don't start a workspace scroll.
            e.preventDefault(); // Stop double-clicking from selecting text.
        });
    };

    Blockly.ZoomControls.prototype.createUndoSvg_ = function() {
        const ws = this.workspace_;

        const undoSvg = Blockly.utils.createSvgElement(
            'image',
            {
                width: this.WIDTH_,
                height: this.WIDTH_,
                x: UNDO_X,
                y: Y
            },
            this.svgGroup_
        );
        undoSvg.setAttributeNS(
            'http://www.w3.org/1999/xlink',
            'xlink:href',
            buildImgUrl('motion_turnleft_deg', 'png')
        );
        this.cmUndoElement = undoSvg;
        this.cmUndoElement.setAttribute('class', 'no-stack');

        // Attach event listeners.
        Blockly.bindEventWithChecks_(undoSvg, 'mousedown', null, e => {
            ws.markFocused();
            ws.undo();
            Blockly.Touch.clearTouchIdentifier(); // Don't block future drags.
            e.stopPropagation(); // Don't start a workspace scroll.
            e.preventDefault(); // Stop double-clicking from selecting text.
        });
    };
};
