import commonCustomDefine from '../common/custom';

/**
 * Default custom blocks defined with common define.
 * @param {VirtualMachine} vm Instance of VirtualMachine.
 * @return {{}} Default custom blocks definition.
 */
export default function(vm) {
    const definedCustom = commonCustomDefine(vm);

    return {
        ...definedCustom
    };
}
