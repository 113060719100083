import React, { PureComponent } from 'react';
import i18n from 'PublicRepo/i18n/common';
import PropTypes from 'prop-types';
import Record from 'PublicRepo/lib/recorder';
import locationBuilder, {
  redirect2WithMiddleware,
} from 'PublicRepo/utility/locationBuilder';
import GeneralConfig from 'PublicRepo/utility/generalConfig';

// download_chrome

import './publishPopup.css';
import quickPublishIcon from 'PublicRepo/images/quick-publish-icon.svg';
import videoPublishIcon from 'PublicRepo/images/video-publish-icon.svg';
import videoPublishDisabledIcon from 'PublicRepo/images/video-publish-disabled-icon.svg';
import closeIcon from 'PublicRepo/images/exit.svg';
import coinIcon from 'PublicRepo/images/coin-icon.svg';

const { isBrowserSupport, isIpad } = Record.checkIsDeviceSupportMediaRecorder();

export default class PublishPopup extends PureComponent {
  jumpToQuickSubmit() {
    const { id, shouldOpenInNewTab, version } = this.props;
    let origin;
    if (version === 3) {
      window.onbeforeunload = null;
    }
    switch (version) {
      case 2:
        origin = locationBuilder.buildProjectQuickPublishUrl(id);
        break;
      case 3:
        origin = locationBuilder.buildGuiProjectQuickPublishUrl(id);
        break;
      default:
        origin = locationBuilder.buildProjectQuickPublishUrl(id);
    }

    if (shouldOpenInNewTab) {
      this.props.onClose();
      redirect2WithMiddleware(origin, true);
    } else {
      redirect2WithMiddleware(origin);
    }
  }

  jumoToVideoSubmit() {
    if (!isBrowserSupport) {
      return;
    }
    const { id, shouldOpenInNewTab, version } = this.props;
    let origin;
    if (version === 3) {
      window.onbeforeunload = null;
    }
    switch (version) {
      case 2:
        origin = locationBuilder.buildProjectVideoPublishUrl(id);
        break;
      case 3:
        origin = locationBuilder.buildGuiProjectVideoPublishUrl(id);
        break;
      default:
        origin = locationBuilder.buildProjectVideoPublishUrl(id);
    }

    if (shouldOpenInNewTab) {
      this.props.onClose();
      setTimeout(() => redirect2WithMiddleware(origin, true));
    } else {
      redirect2WithMiddleware(origin);
    }
  }

  renderErrorMesseage() {
    // eslint-disable-next-line no-empty
    if (isBrowserSupport) {
    } else if (isIpad) {
      return (
        <div className="error-msg">
          {i18n.get('__Publish-Popup Your device does not support.')}
          <br />
          {i18n.get('__Publish-Popup Please use PC instead.')}
        </div>
      );
    } else {
      return (
        <div className="error-msg">
          {i18n.get("__Publish-Popup your browser doesn't support")}
          <br />
          {i18n.get('__Publish-Popup Use Chrome')}
          <a
            className="chrome-link"
            target="__blank"
            href={GeneralConfig.chromeDownloadPageLink}
          >
            {i18n.get('__Publish-Popup download')}
          </a>
        </div>
      );
    }
  }

  render() {
    const isEnglish = i18n.getCurrentLanguage() === 'en';
    return (
      <div className="publish-popup">
        <div className="content">
          <div
            className="item quick-publish"
            onClick={() => this.jumpToQuickSubmit()}
          >
            <div className="text">{i18n.get('__Publish-Popup Publish')}</div>
            <img className="icon" src={quickPublishIcon} />
          </div>
          <div
            className={`item video-publish ${
              isBrowserSupport ? '' : 'disabled'
            }`}
            onClick={() => this.jumoToVideoSubmit()}
          >
            <span className={`text ${isEnglish ? 'en' : ''}`}>
              {i18n.get('__Publish-Popup Publish with Video')}
            </span>
            <div className="hint-area">
              <div className="text">
                <span>{i18n.get('__Publish-Popup rewards 100 ')}</span>
                <img src={coinIcon} />
                <div
                  className="question-mark"
                  title={i18n.get(
                    '__Publish-Popup Up to 300 gold coins a day.',
                  )}
                >
                  ?
                  {' '}
                </div>
              </div>
            </div>
            {isBrowserSupport ? (
              <img className="icon" src={videoPublishIcon} />
            ) : (
              <img className="icon" src={videoPublishDisabledIcon} />
            )}
            {this.renderErrorMesseage()}
          </div>

          <div
            className="close"
            onClick={e => {
              e.stopPropagation();
              this.props.onClose();
            }}
          >
            <img src={closeIcon} />
          </div>
        </div>
      </div>
    );
  }
}

PublishPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired, // 项目 ID
  shouldOpenInNewTab: PropTypes.bool.isRequired,
  version: PropTypes.number,
};

PublishPopup.defaultProps = {
  version: 2,
};
