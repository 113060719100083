/* eslint-disable no-console */
import applyCustom from './custom';
import get from 'lodash/get';

/**
 * Apply extension custom.
 * @param {object} extensionCustoms Custom extension definitions.
 * @param {VirtualMachine} vm Instance of VirtualMachine.
 * @param {*} categoryInfo Custom extension categoryInfo.
 */
export default function applyExtensionCustom(
    extensionCustoms,
    vm,
    categoryInfo
) {
    const targetTypes = Object.keys(extensionCustoms);
    if (!targetTypes.length) return;
    const type =
        get(categoryInfo, '0.json.type') ||
        get(categoryInfo, 'blocks.0.json.type');
    if (type) {
        const blockType = type.split('_')[0];
        if (targetTypes.indexOf(blockType) >= 0) {
            applyCustom(extensionCustoms[blockType], vm);
        }
    }
}
