import React from 'react';

import './index.less';

/**
 * set the component props :
 *      {
 *          curPage : (number)the current page,
 *          totalPage : (number)the total page,
 *          goPage : (function)excute when click the page
 *      }
 */

const noop = () => {};

const Paging = props => {
  const _props = props;

  const _curPage = _props.curPage;

  const _totalPage = _props.totalPage;

  const className = _props.className || '';

  const _pageHtml = [];

  let _reactKey = 1;

  if (_totalPage <= 1) {
    return <div />;
  }
  /* eslint-disable jsx-a11y/anchor-is-valid,no-script-url */
  // keep the current page with 3 page number beside
  // the previous part
  if (_curPage < 4) {
    for (let i = 1; i <= _curPage - 1; i++) {
      _pageHtml.push(
        <li key={_reactKey++}>
          <a href="javascript:;" onClick={props.goPage.bind(this, i)}>
            {i}
          </a>
        </li>,
      );
    }
  } else {
    _pageHtml.push(
      <li key={_reactKey++}>
        <a href="javascript:;" onClick={props.goPage.bind(this, 1)}>
          1
        </a>
      </li>,
    );
    _pageHtml.push(
      <li key={_reactKey++}>
        <a href="javascript:;">...</a>
      </li>,
    );
    _pageHtml.push(
      <li key={_reactKey++}>
        <a href="javascript:;" onClick={props.goPage.bind(this, _curPage - 1)}>
          {_curPage - 1}
        </a>
      </li>,
    );
  }

  _pageHtml.push(
    <li className="cur" key={_reactKey++}>
      <a href="javascript:;">{_curPage}</a>
    </li>,
  );

  // the subsequent part
  if (_totalPage - _curPage < 3) {
    for (let i = 1; i <= _totalPage - _curPage; i++) {
      _pageHtml.push(
        <li key={_reactKey++}>
          <a
            href="javascript:;"
            onClick={props.goPage.bind(this, _curPage + i)}
          >
            {_curPage + i}
          </a>
        </li>,
      );
    }
  } else {
    _pageHtml.push(
      <li key={_reactKey++}>
        <a href="javascript:;" onClick={props.goPage.bind(this, _curPage + 1)}>
          {_curPage + 1}
        </a>
      </li>,
    );

    _pageHtml.push(
      <li key={_reactKey++}>
        <a href="javascript:;">...</a>
      </li>,
    );
    _pageHtml.push(
      <li key={_reactKey++}>
        <a href="javascript:;" onClick={props.goPage.bind(this, _totalPage)}>
          {_totalPage}
        </a>
      </li>,
    );
  }

  return (
    <div className={`page-nav ${className}`}>
      <ul>
        <li key={_reactKey++}>
          <a
            href="javascript:;"
            onClick={
              _props.curPage > 1 ? props.goPage.bind(this, _curPage - 1) : noop
            }
            className={`last-page ${_props.curPage > 1 ? '' : 'disabled'}`}
          >
            <i />
          </a>
        </li>
        {_pageHtml}
        <li key={_reactKey++}>
          <a
            href="javascript:;"
            onClick={
              _props.curPage < _props.totalPage
                ? props.goPage.bind(this, _curPage + 1)
                : noop
            }
            className={`next-page ${
              _props.curPage < _props.totalPage ? '' : 'disabled'
            }`}
          >
            <i />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Paging;
