import React from 'react';
import {
    PostMessageToHost,
    IPC_EVENT
} from 'public-repo/utility/bellcloudIPC';

const TrackGUI = function (WrappedComponent) {
    class InjectionComponent extends React.Component {
        componentDidMount () {
            PostMessageToHost(IPC_EVENT.enterEditor);
        }

        render () {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    return InjectionComponent;
};

export default TrackGUI;
