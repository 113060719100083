/* eslint-disable babel/camelcase */

import buildImgUrl from '../utils/build-img-url';

const penCustomDefine = () => {
    const PEN_CHANGE_SIZE = 5;

    return {
        pen_pen_down: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('pen_penDown', 'png'),
                        width: 70,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: 'Pen',
                extensions: [
                    'colours_pen',
                    'shape_statement',
                    'scratch_extension'
                ]
            },
            primitive(args, util) {
                return util.runtime.getOpcodeFunction('pen_penDown')(
                    Object.assign({}, args),
                    util
                );
            }
        },
        pen_pen_up: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('pen_penUp', 'png'),
                        width: 70,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: 'Pen',
                extensions: [
                    'colours_pen',
                    'shape_statement',
                    'scratch_extension'
                ]
            },
            primitive(args, util) {
                return util.runtime.getOpcodeFunction('pen_penUp')(
                    Object.assign({}, args),
                    util
                );
            }
        },
        pen_set_pen_color_to_color: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('pen_setPenColorToColor', 'png'),
                        width: 47,
                        height: 52,
                        alt: 'flag'
                    },
                    {
                        type: 'input_value',
                        name: 'COLOR'
                    }
                ],
                category: 'Pen',
                extensions: [
                    'colours_pen',
                    'shape_statement',
                    'scratch_extension'
                ]
            },
            primitive(args, util) {
                return util.runtime.getOpcodeFunction('pen_setPenColorToColor')(
                    Object.assign({}, args),
                    util
                );
            }
        },
        pen_changePenSizeBy_up: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('pen_changePenSizeBy_up', 'png'),
                        width: 70,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: 'Pen',
                extensions: [
                    'colours_pen',
                    'shape_statement',
                    'scratch_extension'
                ]
            },
            primitive(args, util) {
                return util.runtime.getOpcodeFunction('pen_changePenSizeBy')(
                    Object.assign({SIZE: PEN_CHANGE_SIZE}, args),
                    util
                );
            }
        },
        pen_changePenSizeBy_down: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('pen_changePenSizeBy_down', 'png'),
                        width: 70,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: 'Pen',
                extensions: [
                    'colours_pen',
                    'shape_statement',
                    'scratch_extension'
                ]
            },
            primitive(args, util) {
                return util.runtime.getOpcodeFunction('pen_changePenSizeBy')(
                    Object.assign({SIZE: -PEN_CHANGE_SIZE}, args),
                    util
                );
            }
        },
        pen_clear_pen: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('pen_clear_pen', 'png'),
                        width: 70,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: 'Pen',
                extensions: [
                    'colours_pen',
                    'shape_statement',
                    'scratch_extension'
                ]
            },
            primitive(args, util) {
                return util.runtime.getOpcodeFunction('pen_clear')(args, util);
            }
        }
    };
};

const musicCustomDefine = () => ({});

export default {
    pen: penCustomDefine,
    music: musicCustomDefine
};
