import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './guide-bubble.css';

const bubbleTargetIndicateClassName = 'custom-bubble-indicator';
const DirectionType = {
    TOP: 'top',
    BOTTOM: 'bottom',
    LEFT: 'left',
    RIGHT: 'right'
};

const ArrorPositions = {
    CENTER: 'Center',
    LEFT: 'Left',
    RIGHT: 'Right'
};

class GuideBubble extends React.Component {
    static defaultProps = {
        direction: DirectionType.ABOVE
    };

    constructor(props) {
        super(props);
        this.state = {
            shouldShow: false
        };
        this.getBubbleRef = this.getBubbleRef.bind(this);
        this.setBubbleStyle = this.setBubbleStyle.bind(this);
    }

    componentDidMount() {
        this.setBubbleStyle(this.props);
    }

    componentWillReceiveProps(newProps) {
        if (
            newProps.visible !== this.props.visible ||
            newProps.targetIndex !== this.props.targetIndex
        ) {
            this.setBubbleStyle(newProps);
        }
    }

    setBubbleStyle(props) {
        const selfRect = this.bubbleRef.getBoundingClientRect();
        const {
            visible,
            position,
            targetIndex,
            indicatorClass,
            arrowPos
        } = props;
        if (!visible) {
            this.setState({
                shouldShow: false
            });
            return;
        }
        if (position) {
            // static dom position
            this.setState({
                shouldShow: true,
                x: position.x,
                y: position.y
            });
        } else {
            let ele = document.querySelectorAll(`.${indicatorClass}`);
            ele = ele ? ele[targetIndex] : null;
            if (ele) {
                const rect = ele.getBoundingClientRect();
                this.setState({
                    shouldShow: true,
                    x:
                        rect.x +
                        (arrowPos === ArrorPositions.CENTER
                            ? (rect.width / 2) - (selfRect.width / 2)
                            : 0),
                    y: rect.y + rect.height + 10
                });
            }
        }
    }

    getBubbleRef(ele) {
        this.bubbleRef = ele;
    }

    render() {
        const {className, direction, text, arrowPos} = this.props;
        const {shouldShow, x, y} = this.state;
        return ReactDOM.createPortal(
            <div
                ref={this.getBubbleRef}
                style={{
                    visibility: shouldShow ? '' : 'hidden',
                    left: x,
                    top: y
                }}
                className={classNames(
                    className,
                    styles.guideBubble,
                    direction
                        ? styles[direction]
                        : styles[DirectionType.BOTTOM],
                    arrowPos ? styles[`arrow${arrowPos}`] : styles.arrowLeft
                )}
            >
                <span className={styles.triangle} />
                {text}
            </div>,
            document.body
        );
    }
}

GuideBubble.propTypes = {
    arrowPos: PropTypes.oneOf(Object.values(ArrorPositions)),
    className: PropTypes.string,
    direction: PropTypes.oneOf(Object.values(DirectionType)),
    position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    text: PropTypes.string.isRequired,
    visible: PropTypes.bool
};

export {
    GuideBubble as default,
    DirectionType,
    ArrorPositions,
    bubbleTargetIndicateClassName
};
