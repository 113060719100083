import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getParams} from './env-utils';
import get from 'lodash/get';

const GuiConfigHoc = function (WrappedComponent) {
    const ProjectConfigComponent = props => <WrappedComponent {...props} />;

    ProjectConfigComponent.propTypes = {
        canChangeLanguage: PropTypes.bool,
        canCreateCopy: PropTypes.bool,
        canCreateNew: PropTypes.bool,
        canEditTitle: PropTypes.bool,
        canManageFiles: PropTypes.bool,
        canRemix: PropTypes.bool,
        canSave: PropTypes.bool,
        canShare: PropTypes.bool,
        canUseCloud: PropTypes.bool
    };

    const canEdit = get(getParams(), 'edit', '1') !== '0';

    const mapStateToProps = ({scratchGui}) => ({
        canChangeLanguage: true,
        canCreateCopy: scratchGui.account.authenticated,
        canCreateNew: scratchGui.account.authenticated,
        canEditTitle: true,
        canManageFiles: true,
        canRemix: false,
        canSave: scratchGui.account.authenticated && (
            scratchGui.account.info.hashId === scratchGui.projectMeta.ownerId
        ) && (
            canEdit
        ),
        canShare: canEdit,
        canUseCloud: false
    });

    return connect(
        mapStateToProps
    )(ProjectConfigComponent);
};

export {
    GuiConfigHoc as default
};
