/* eslint-disable babel/camelcase */
// todo Remove below
/* eslint-disable no-mixed-operators */

import ScratchBlocks from 'scratch-blocks';
import Config from '../../../config';
import commonCustomDefine from '../common/custom';
import buildImgUrl from '../utils/build-img-url';
import isItemUsable from '../../puzzle/helpers/is-item-usable';
import {reigsterScratchBlocksFieldImageDropdown} from './custom-field/field-image-dropdown';
import {createRedoUnDoButtons} from './add-redo-undo';
/**
 * Junior custom blocks defined with common define.
 * @param {VirtualMachine} vm Instance of VirtualMachine.
 * @return {{}} Junior custom blocks definition.
 */
export default function customDefine(vm) {
    reigsterScratchBlocksFieldImageDropdown();
    createRedoUnDoButtons();
    // TODO:remove
    window.vm = vm;
    window.__ScratchBlocks = ScratchBlocks;
    const spriteMenu = function() {
        const sprites = [];
        for (const targetId in vm.runtime.targets) {
            if (!vm.runtime.targets.hasOwnProperty(targetId)) continue;
            if (vm.runtime.targets[targetId].isOriginal) {
                if (!vm.runtime.targets[targetId].isStage) {
                    if (vm.runtime.targets[targetId] === vm.editingTarget) {
                        continue;
                    }
                    if (
                        isItemUsable(vm.runtime.targets[targetId].sprite.name)
                    ) {
                        const target = vm.runtime.targets[targetId];
                        sprites.push([
                            {
                                src: target.sprite.costumes_[
                                    target.currentCostume
                                ].asset.encodeDataURI(),
                                width: 48,
                                height: 48,
                                alt: vm.runtime.targets[targetId].sprite.name
                            },
                            vm.runtime.targets[targetId].sprite.name
                        ]);
                    }
                }
            }
        }
        return sprites;
    };
    const jsonForMenuBlock = function(
        name,
        menuOptionsFn,
        colors,
        start,
        category = null
    ) {
        return {
            message0: '%1',
            args0: [
                {
                    type: 'field_image_dropdown',
                    name: name,
                    options: function() {
                        return start.concat(menuOptionsFn());
                    }
                }
            ],
            inputsInline: true,
            output: 'String',
            colour: colors.secondary,
            colourSecondary: colors.secondary,
            colourTertiary: colors.tertiary,
            outputShape: ScratchBlocks.OUTPUT_SHAPE_ROUND,
            category: category
        };
    };

    const jsonForHatBlockMenu = function(
        hatName,
        name,
        menuOptionsFn,
        colors,
        start,
        category = null
    ) {
        return {
            message0: hatName,
            args0: [
                {
                    type: 'field_image',
                    src: Config.empty1pxImgUrl,
                    width: 30,
                    height: 30,
                    alt: 'flag'
                },
                {
                    type: 'field_image_dropdown',
                    name: name,
                    options: function() {
                        return start.concat(menuOptionsFn());
                    }
                }
            ],
            colour: colors.primary,
            colourSecondary: colors.secondary,
            colourTertiary: colors.tertiary,
            extensions: ['shape_hat'],
            category: category
        };
    };
    const eventColors = ScratchBlocks.Colours.event;
    const looksColors = ScratchBlocks.Colours.looks;
    const motionColors = ScratchBlocks.Colours.motion;

    const costumesMenu = function() {
        if (vm.editingTarget && vm.editingTarget.getCostumes().length > 0) {
            const costumes = vm.editingTarget.getCostumes();
            return costumes.map(costume => {
                const index = vm.editingTarget.getCostumeIndexByName(
                    costume.name
                );
                return [
                    {
                        src: costumes[index].asset.encodeDataURI(),
                        width: 48,
                        height: 48,
                        alt: costume.name
                    },
                    costume.name
                ];
            });
        }
        return [['', '']];
    };
    const backdropsMenu = function() {
        // const next = ScratchBlocks.ScratchMsgs.translate('LOOKS_NEXTBACKDROP', 'next backdrop');
        // const previous = ScratchBlocks.ScratchMsgs.translate('LOOKS_PREVIOUSBACKDROP', 'previous backdrop');
        // const random = ScratchBlocks.ScratchMsgs.translate('LOOKS_RANDOMBACKDROP', 'random backdrop');
        const stage = vm.runtime.targets[0];
        const stageCostumes = vm.runtime.targets[0]?.getCostumes();
        if (stage && stageCostumes?.length > 0) {
            const costumes = stage.getCostumes();
            return stageCostumes
                .map(costume => {
                    const index = stage.getCostumeIndexByName(costume.name);
                    return [
                        {
                            src: costumes[index].asset.encodeDataURI(),
                            width: 48,
                            height: 48,
                            alt: costume.name
                        },
                        costume.name
                    ];
                })
                .concat([
                    // [next, 'next backdrop'],
                    // [previous, 'previous backdrop'],
                    // [random, 'random backdrop']
                ]);
        }
        return [['', '']];
    };
    const definedCustom = commonCustomDefine(vm);
    const emptyEventJson = {
        message0: '%1',
        args0: [
            {
                type: 'field_image',
                src: Config.empty1pxImgUrl,
                width: 1,
                height: 1,
                alt: 'flag'
            }
        ],
        category: ScratchBlocks.Categories.event,
        extensions: ['colours_event', 'shape_hat']
    };

    vm.runtime._hats.event_whentouchingtargetobject = {
        restartExistingThreads: false,
        edgeActivated: true
    };

    vm.runtime._hats.event_whentouchingotherobjects = {
        restartExistingThreads: false,
        edgeActivated: true
    };

    vm.runtime._hats.event_whenbroadcastreceivedv2 = {
        restartExistingThreads: true
    };

    vm.runtime._hats.event_whenstagetouched = {
        restartExistingThreads: true
    };

    const looksJsonWithImage = function(type, width = 43, height = 46) {
        return {
            message0: '%1',
            args0: [
                {
                    type: 'field_image',
                    src: buildImgUrl(type, 'png'),
                    width: width,
                    height: height,
                    alt: 'flag'
                }
            ],
            category: ScratchBlocks.Categories.looks,
            extensions: ['colours_looks', 'shape_statement']
        };
    };

    const MOVE_STEPS = 40;
    // const MOVE_TIME = 500;
    const TURN_DEGREES = 15;
    // const FINISH_WAIT_SECS = .6;
    const ZOOM_SIZE = 10;
    const BROADCAST_V2_OPTION_WIDTH = 62;
    const BROADCAST_V2_OPTION_HEIGHT = 48;
    const BROADCAST_RECEIVED_V2_OPTION_WIDTH = 60;
    const BROADCAST_RECEIVED_V2_OPTION_HEIGHT = 66;

    return {
        ...definedCustom,
        event_whenflagclicked: {
            json: emptyEventJson
        },
        event_whenkeypressed: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: Config.empty1pxImgUrl,
                        width: 34,
                        height: 1,
                        alt: 'flag'
                    },
                    {
                        type: 'field_dropdown',
                        name: 'KEY_OPTION',
                        options: [
                            [
                                ScratchBlocks.Msg.EVENT_WHENKEYPRESSED_SPACE,
                                'space'
                            ],
                            [
                                `← ${ScratchBlocks.Msg.EVENT_WHENKEYPRESSED_LEFT}`,
                                'left arrow'
                            ],
                            [
                                `→ ${ScratchBlocks.Msg.EVENT_WHENKEYPRESSED_RIGHT}`,
                                'right arrow'
                            ],
                            [
                                `↓ ${ScratchBlocks.Msg.EVENT_WHENKEYPRESSED_DOWN}`,
                                'down arrow'
                            ],
                            [
                                `↑ ${ScratchBlocks.Msg.EVENT_WHENKEYPRESSED_UP}`,
                                'up arrow'
                            ],
                            [ScratchBlocks.Msg.EVENT_WHENKEYPRESSED_ANY, 'any'],
                            ['A', 'a'],
                            ['B', 'b'],
                            ['C', 'c'],
                            ['D', 'd'],
                            ['E', 'e'],
                            ['F', 'f'],
                            ['G', 'g'],
                            ['H', 'h'],
                            ['I', 'i'],
                            ['J', 'j'],
                            ['K', 'k'],
                            ['L', 'l'],
                            ['M', 'm'],
                            ['N', 'n'],
                            ['O', 'o'],
                            ['P', 'p'],
                            ['Q', 'q'],
                            ['R', 'r'],
                            ['S', 's'],
                            ['T', 't'],
                            ['U', 'u'],
                            ['V', 'v'],
                            ['W', 'w'],
                            ['X', 'x'],
                            ['Y', 'y'],
                            ['Z', 'z'],
                            ['0', '0'],
                            ['1', '1'],
                            ['2', '2'],
                            ['3', '3'],
                            ['4', '4'],
                            ['5', '5'],
                            ['6', '6'],
                            ['7', '7'],
                            ['8', '8'],
                            ['9', '9']
                        ]
                    }
                ],
                category: ScratchBlocks.Categories.event,
                extensions: ['colours_event', 'shape_hat']
            }
        },
        event_whenthisspriteclicked: {
            json: emptyEventJson
        },
        event_whenstageclicked: {
            json: emptyEventJson
        },
        event_whenstagetouched: {
            json: emptyEventJson
        },
        event_broadcast: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('event_broadcast'),
                        width: 65,
                        height: 31,
                        alt: 'flag'
                    },
                    {
                        type: 'input_value',
                        name: 'BROADCAST_INPUT'
                    }
                ],
                category: ScratchBlocks.Categories.event,
                extensions: ['colours_event', 'shape_statement']
            }
        },
        event_broadcastv2: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        name: 'BROADCAST_IMAGE',
                        src: buildImgUrl('greenSend', 'png'),
                        width: 62,
                        height: 38,
                        alt: 'flag'
                    },
                    {
                        type: 'field_dropdown',
                        name: 'BROADCAST_SEND',
                        options: [
                            'green',
                            'pink',
                            'blue',
                            'purple',
                            'yellow',
                            'red'
                        ].map(color => [
                            {
                                src: buildImgUrl(`${color}Send`, 'png'),
                                width: BROADCAST_V2_OPTION_WIDTH,
                                height: BROADCAST_V2_OPTION_HEIGHT,
                                alt: ''
                            },
                            color
                        ])
                    }
                ],
                category: ScratchBlocks.Categories.event,
                extensions: ['colours_event', 'shape_statement'],
                mutator: 'bellcode_broadcast_mutator'
            },
            primitive(args, util) {
                const broadcastValue = args.BROADCAST_SEND;
                if (broadcastValue) {
                    util.startHats('event_whenbroadcastreceivedv2', {
                        BROADCAST_RECEIVED: broadcastValue
                    });
                }
            }
        },
        event_whenbroadcastreceived: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: Config.empty1pxImgUrl,
                        width: 10,
                        height: 1,
                        alt: 'flag'
                    },
                    {
                        type: 'field_variable',
                        name: 'BROADCAST_OPTION',
                        variableTypes: [
                            ScratchBlocks.BROADCAST_MESSAGE_VARIABLE_TYPE
                        ],
                        variable:
                            ScratchBlocks.Msg.DEFAULT_BROADCAST_MESSAGE_NAME
                    }
                ],
                category: ScratchBlocks.Categories.event,
                extensions: ['colours_event', 'shape_hat']
            }
        },
        event_whenbroadcastreceivedv2: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        name: 'BROADCAST_RECEIVED_IMAGE',
                        src: buildImgUrl('greenReceive', 'png'),
                        width: 50,
                        height: 55,
                        alt: 'flag'
                    },
                    {
                        type: 'field_dropdown',
                        name: 'BROADCAST_RECEIVED',
                        options: [
                            'green',
                            'pink',
                            'blue',
                            'purple',
                            'yellow',
                            'red'
                        ].map(color => [
                            {
                                src: buildImgUrl(`${color}Receive`, 'png'),
                                width: BROADCAST_RECEIVED_V2_OPTION_WIDTH,
                                height: BROADCAST_RECEIVED_V2_OPTION_HEIGHT,
                                alt: ''
                            },
                            color
                        ])
                    }
                ],
                category: ScratchBlocks.Categories.event,
                extensions: ['colours_event', 'shape_hat'],
                mutator: 'bellcode_broadcast_received_mutator'
            }
        },

        event_whentouchingotherobjects: {
            json: emptyEventJson,
            primitive(args, util) {
                const targets = vm.runtime.targets
                    .filter(
                        target =>
                            !target.isStage &&
                            target.sprite.name !== util.target.sprite.name
                    )
                    .map(target => target.sprite.name);
                for (let i = 0; i < targets.length; i++) {
                    if (
                        util.runtime.getOpcodeFunction(
                            'sensing_touchingobject'
                        )(
                            Object.assign(
                                {TOUCHINGOBJECTMENU: targets[i]},
                                args
                            ),
                            util
                        )
                    ) {
                        return true;
                    }
                }
            }
        },
        event_whentouchingtargetobject: {
            json: function() {
                return jsonForHatBlockMenu(
                    '    %1 %2',
                    'TOUCHINGOBJECTMENU',
                    spriteMenu,
                    eventColors,
                    [['', '']],
                    ScratchBlocks.Categories.event
                );
            },
            primitive(args, util) {
                if (
                    util.runtime.getOpcodeFunction('sensing_touchingobject')(
                        args,
                        util
                    )
                ) {
                    return true;
                }
            }
        },

        motion_movesteps_top: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('motion_movesteps_top', 'png'),
                        width: 48,
                        height: 48,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.motion,
                extensions: ['colours_motion', 'shape_statement']
            },
            primitive(args, util) {
                // Hack to trigger script glow on.
                util.thread.requestScriptGlowInFrame = true;

                const times = 5; // util.target.sprite.costumes.length;

                // todo remove below after implement workspace click feature
                //             if (
                //                 util.stackFrame.loopCounter === undefined &&
                // util.target.currentCostume !== 0
                //             ) {
                //                 util.target.setCostume(0);
                //             }
                // end

                if (!util.stackFrame._resetStateFunc) {
                    util.stackFrame._resetStateFunc = (data => () => {
                        util.runtime.getOpcodeFunction(
                            'motion_pointindirection'
                        )(
                            Object.assign(
                                {DIRECTION: data.pointInDirection},
                                args
                            ),
                            util
                        );
                        util.runtime.getOpcodeFunction(
                            'motion_setrotationstyle'
                        )(
                            Object.assign({STYLE: data.rotationStyle}, args),
                            util
                        );
                    })({
                        pointInDirection: util.target.direction,
                        rotationStyle: util.target.rotationStyle
                    });
                }

                const stackFrame = util.stackFrame;

                util.runtime.getOpcodeFunction('control_repeat')(
                    Object.assign({TIMES: times}, args),
                    util
                );

                if (stackFrame.loopCounter >= 0) {
                    util.runtime.getOpcodeFunction('motion_pointindirection')(
                        Object.assign({DIRECTION: '0'}, args),
                        util
                    );
                    util.runtime.getOpcodeFunction('motion_setrotationstyle')(
                        Object.assign({STYLE: 'left-right'}, args),
                        util
                    );
                    util.runtime.getOpcodeFunction('motion_movesteps')(
                        Object.assign({STEPS: MOVE_STEPS / times}, args),
                        util
                    );
                    // util.runtime.getOpcodeFunction('looks_nextcostume')(args, util);
                    stackFrame._resetStateFunc();
                    return util.runtime.getOpcodeFunction('control_wait')(
                        Object.assign(
                            {
                                DURATION: 0
                                //     Math.max(
                                //     (MOVE_TIME - util.runtime.currentStepTime * times) / (times - 1) / 1000, 0
                                // )
                            },
                            args
                        ),
                        util
                    );
                }
                stackFrame._resetStateFunc();
                stackFrame._resetStateFunc = null;
                return util.runtime.getOpcodeFunction('control_wait')(
                    Object.assign({DURATION: 0.1}, args),
                    util
                );
            }
        },
        motion_movesteps_bottom: {
            json: {
                id: 'motion_movesteps_bottom',
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('motion_movesteps_bottom', 'png'),
                        width: 48,
                        height: 48,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.motion,
                extensions: ['colours_motion', 'shape_statement']
            },
            primitive(args, util) {
                // Hack to trigger script glow on.
                util.thread.requestScriptGlowInFrame = true;

                const times = 5; // util.target.sprite.costumes.length;

                // todo remove below after implement workspace click feature
                // if (
                //     util.stackFrame.loopCounter === undefined &&
                //     util.target.currentCostume !== 0
                // ) {
                //     util.target.setCostume(0);
                // }
                // end

                if (!util.stackFrame._resetStateFunc) {
                    util.stackFrame._resetStateFunc = (data => () => {
                        util.runtime.getOpcodeFunction(
                            'motion_pointindirection'
                        )(
                            Object.assign(
                                {DIRECTION: data.pointInDirection},
                                args
                            ),
                            util
                        );
                        util.runtime.getOpcodeFunction(
                            'motion_setrotationstyle'
                        )(
                            Object.assign({STYLE: data.rotationStyle}, args),
                            util
                        );
                    })({
                        pointInDirection: util.target.direction,
                        rotationStyle: util.target.rotationStyle
                    });
                }

                const stackFrame = util.stackFrame;

                util.runtime.getOpcodeFunction('control_repeat')(
                    Object.assign({TIMES: times}, args),
                    util
                );

                if (stackFrame.loopCounter >= 0) {
                    util.runtime.getOpcodeFunction('motion_pointindirection')(
                        Object.assign({DIRECTION: '180'}, args),
                        util
                    );
                    util.runtime.getOpcodeFunction('motion_setrotationstyle')(
                        Object.assign({STYLE: 'left-right'}, args),
                        util
                    );
                    util.runtime.getOpcodeFunction('motion_movesteps')(
                        Object.assign({STEPS: MOVE_STEPS / times}, args),
                        util
                    );
                    // util.runtime.getOpcodeFunction('looks_nextcostume')(args, util);
                    stackFrame._resetStateFunc();
                    return util.runtime.getOpcodeFunction('control_wait')(
                        Object.assign(
                            {
                                DURATION: 0
                                //     Math.max(
                                //     (MOVE_TIME - util.runtime.currentStepTime * times) / (times - 1) / 1000, 0
                                // )
                            },
                            args
                        ),
                        util
                    );
                }
                stackFrame._resetStateFunc();
                stackFrame._resetStateFunc = null;
                return util.runtime.getOpcodeFunction('control_wait')(
                    Object.assign({DURATION: 0.1}, args),
                    util
                );
            }
        },
        motion_movesteps_left: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('motion_movesteps_left', 'png'),
                        width: 53,
                        height: 36,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.motion,
                extensions: ['colours_motion', 'shape_statement']
            },
            primitive(args, util) {
                // Hack to trigger script glow on.
                util.thread.requestScriptGlowInFrame = true;

                const times = 5; // util.target.sprite.costumes.length;

                // todo remove below after implement workspace click feature
                // if (
                //     util.stackFrame.loopCounter === undefined &&
                //    util.target.currentCostume !== 0
                // ) {
                //     util.target.setCostume(0);
                // }
                // end
                const stackFrame = util.stackFrame;

                util.runtime.getOpcodeFunction('control_repeat')(
                    Object.assign({TIMES: times}, args),
                    util
                );

                if (stackFrame.loopCounter >= 0) {
                    util.runtime.getOpcodeFunction('motion_movesteps')(
                        Object.assign({STEPS: -(MOVE_STEPS / times)}, args),
                        util
                    );
                    // util.runtime.getOpcodeFunction('looks_nextcostume')(args, util);
                    return util.runtime.getOpcodeFunction('control_wait')(
                        Object.assign(
                            {
                                DURATION: 0
                                // Math.max(
                                //     (MOVE_TIME - util.runtime.currentStepTime * times) / (times - 1) / 1000, 0
                                // )
                            },
                            args
                        ),
                        util
                    );
                }
                return util.runtime.getOpcodeFunction('control_wait')(
                    Object.assign({DURATION: 0.1}, args),
                    util
                );
            }
        },
        motion_movesteps_right: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('motion_movesteps_right', 'png'),
                        width: 53,
                        height: 36,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.motion,
                extensions: ['colours_motion', 'shape_statement']
            },
            primitive(args, util) {
                // Hack to trigger script glow on.
                util.thread.requestScriptGlowInFrame = true;

                const times = 5; // util.target.sprite.costumes.length;

                // todo remove below after implement workspace click feature
                // if (
                //     util.stackFrame.loopCounter === undefined &&
                //     util.target.currentCostume !== 0
                // ) {
                //     util.target.setCostume(0);
                // }
                // end
                const stackFrame = util.stackFrame;

                util.runtime.getOpcodeFunction('control_repeat')(
                    Object.assign({TIMES: times}, args),
                    util
                );

                if (stackFrame.loopCounter >= 0) {
                    util.runtime.getOpcodeFunction('motion_movesteps')(
                        Object.assign({STEPS: MOVE_STEPS / times}, args),
                        util
                    );
                    // util.runtime.getOpcodeFunction('looks_nextcostume')(args, util);
                    return util.runtime.getOpcodeFunction('control_wait')(
                        Object.assign(
                            {
                                DURATION: 0
                                // Math.max(
                                //     (MOVE_TIME - util.runtime.currentStepTime * times) / (times - 1) / 1000, 0
                                // )
                            },
                            args
                        ),
                        util
                    );
                }
                return util.runtime.getOpcodeFunction('control_wait')(
                    Object.assign({DURATION: 0.1}, args),
                    util
                );
            }
        },
        motion_turnright_deg: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('motion_turnright_deg', 'png'),
                        width: 53,
                        height: 47,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.motion,
                extensions: ['colours_motion', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('motion_turnright')(
                    Object.assign({DEGREES: TURN_DEGREES}, args),
                    util
                );
            }
        },
        motion_turnleft_deg: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('motion_turnleft_deg', 'png'),
                        width: 53,
                        height: 47,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.motion,
                extensions: ['colours_motion', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('motion_turnleft')(
                    Object.assign({DEGREES: TURN_DEGREES}, args),
                    util
                );
            }
        },
        motion_goto: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('motion_goto_actor', 'png'),
                        width: 53,
                        height: 47,
                        alt: 'flag'
                    },
                    {
                        type: 'input_value',
                        name: 'TO'
                    }
                ],
                category: ScratchBlocks.Categories.motion,
                extensions: ['colours_motion', 'shape_statement']
            }
        },
        motion_cm_goto_menu: {
            json: function() {
                return jsonForMenuBlock(
                    'TO',
                    spriteMenu,
                    motionColors,
                    [['', '']],
                    ScratchBlocks.Categories.motion
                );
            }
        },
        motion_cm_goto: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('motion_goto_actor', 'png'),
                        width: 53,
                        height: 47,
                        alt: 'flag'
                    },
                    {
                        type: 'input_value',
                        name: 'TO'
                    }
                ],
                category: ScratchBlocks.Categories.motion,
                extensions: ['colours_motion', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('motion_goto')(args, util);
            }
        },
        motion_goto__mouse_pointer: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('motion_goto__mouse_pointer', 'png'),
                        width: 53,
                        height: 42,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.motion,
                extensions: ['colours_motion', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('motion_goto')(
                    Object.assign({TO: '_mouse_'}, args),
                    util
                );
            }
        },
        motion_goto__random_position: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('motion_goto__random_position', 'png'),
                        width: 53,
                        height: 47,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.motion,
                extensions: ['colours_motion', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('motion_goto')(
                    Object.assign({TO: '_random_'}, args),
                    util
                );
            }
        },

        looks_show: {
            json: looksJsonWithImage('looks_show')
        },
        looks_hide: {
            json: looksJsonWithImage('looks_hide')
        },
        looks_nextbackdrop: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('looks_nextbackdrop', 'png'),
                        width: 63,
                        height: 44,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            }
        },
        looks_cm_costume: {
            json: function() {
                return jsonForMenuBlock(
                    'COSTUME',
                    costumesMenu,
                    looksColors,
                    [],
                    ScratchBlocks.Categories.looks
                );
            }
        },
        looks_cm_backdrops: {
            json: function() {
                return jsonForMenuBlock(
                    'BACKDROP',
                    backdropsMenu,
                    looksColors,
                    [],
                    ScratchBlocks.Categories.looks
                );
            }
        },

        looks_nextcostume: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('looks_nextcostume', 'png'),
                        width: 63,
                        height: 44,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            }
        },

        looks_cm_switchcostumeto: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('looks_nextcostume', 'png'),
                        width: 63,
                        height: 44,
                        alt: 'flag'
                    },
                    {
                        type: 'input_value',
                        name: 'COSTUME'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_switchcostumeto')(
                    args,
                    util
                );
            }
        },

        looks_cm_switchbackdropto: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('looks_nextbackdrop', 'png'),
                        width: 63,
                        height: 44,
                        alt: 'flag'
                    },
                    {
                        type: 'input_value',
                        name: 'BACKDROP'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_switchbackdropto')(
                    args,
                    util
                );
            }
        },

        looks_setsizeto_reset: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('looks_setsizeto_reset', 'png'),
                        width: 51,
                        height: 39,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_setsizeto')(
                    Object.assign({SIZE: 100}, args),
                    util
                );
            }
        },
        looks_sayforsecs_shorttime: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('looks_sayforsecs_shorttime', 'png'),
                        width: 42,
                        height: 36,
                        alt: 'flag'
                    },
                    {
                        type: 'input_value',
                        name: 'MESSAGE'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                // todo Cal secs dynamically
                util.runtime.getOpcodeFunction('looks_sayforsecs')(
                    Object.assign({SECS: 3}, args),
                    util
                );
            }
        },
        looks_changesizeby_zoomin: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('looks_changesizeby_zoomin', 'png'),
                        width: 50,
                        height: 41,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_changesizeby')(
                    Object.assign({CHANGE: ZOOM_SIZE}, args),
                    util
                );
            }
        },
        looks_changesizeby_zoomout: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('looks_changesizeby_zoomout', 'png'),
                        width: 49,
                        height: 39,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_changesizeby')(
                    Object.assign({CHANGE: -ZOOM_SIZE}, args),
                    util
                );
            }
        },
        looks_seteffectto__color: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('looks_seteffectto__color', 'png'),
                        width: 48,
                        height: 48,
                        alt: 'flag'
                    },
                    {
                        type: 'input_value',
                        name: 'VALUE'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_seteffectto')(
                    Object.assign({EFFECT: 'COLOR'}, args),
                    util
                );
            }
        },
        looks_changeeffectby__fisheye_minus: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl(
                            'looks_changeeffectby__fisheye_minus',
                            'png'
                        ),
                        width: 130,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_changeeffectby')(
                    Object.assign({CHANGE: '-20', EFFECT: 'FISHEYE'}, args),
                    util
                );
            }
        },
        looks_changeeffectby__fisheye_plus: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl(
                            'looks_changeeffectby__fisheye_plus',
                            'png'
                        ),
                        width: 130,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_changeeffectby')(
                    Object.assign({CHANGE: '20', EFFECT: 'FISHEYE'}, args),
                    util
                );
            }
        },
        looks_changeeffectby__mosaic_minus: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl(
                            'looks_changeeffectby__mosaic_minus',
                            'png'
                        ),
                        width: 130,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_changeeffectby')(
                    Object.assign({CHANGE: '-10', EFFECT: 'MOSAIC'}, args),
                    util
                );
            }
        },
        looks_changeeffectby__mosaic_plus: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl(
                            'looks_changeeffectby__mosaic_plus',
                            'png'
                        ),
                        width: 130,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_changeeffectby')(
                    Object.assign({CHANGE: '10', EFFECT: 'MOSAIC'}, args),
                    util
                );
            }
        },
        looks_changeeffectby__ghost_minus: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl(
                            'looks_changeeffectby__ghost_minus',
                            'png'
                        ),
                        width: 130,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_changeeffectby')(
                    Object.assign({CHANGE: '-20', EFFECT: 'GHOST'}, args),
                    util
                );
            }
        },
        looks_changeeffectby__ghost_plus: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl(
                            'looks_changeeffectby__ghost_plus',
                            'png'
                        ),
                        width: 130,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_changeeffectby')(
                    Object.assign({CHANGE: '20', EFFECT: 'GHOST'}, args),
                    util
                );
            }
        },
        looks_changeeffectby__whirl_minus: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl(
                            'looks_changeeffectby__whirl_minus',
                            'png'
                        ),
                        width: 130,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_changeeffectby')(
                    Object.assign({CHANGE: '-20', EFFECT: 'WHIRL'}, args),
                    util
                );
            }
        },
        looks_changeeffectby__whirl_plus: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl(
                            'looks_changeeffectby__whirl_plus',
                            'png'
                        ),
                        width: 130,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.looks,
                extensions: ['colours_looks', 'shape_statement']
            },
            primitive(args, util) {
                util.runtime.getOpcodeFunction('looks_changeeffectby')(
                    Object.assign({CHANGE: '20', EFFECT: 'WHIRL'}, args),
                    util
                );
            }
        },

        control_wait: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('control_wait', 'png'),
                        width: 44,
                        height: 44,
                        alt: 'flag'
                    },
                    {
                        type: 'input_value',
                        name: 'DURATION'
                    }
                ],
                category: ScratchBlocks.Categories.control,
                extensions: ['colours_control', 'shape_statement']
            }
        },
        control_forever: {
            json: {
                message0: '%1',
                message1: '%1', // Statement
                // message2: '%1', // Icon
                // lastDummyAlign2: 'RIGHT',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('control_forever', 'png'),
                        width: 60,
                        height: 44,
                        alt: 'flag'
                    }
                ],
                args1: [
                    {
                        type: 'input_statement',
                        name: 'SUBSTACK'
                    }
                ],
                category: ScratchBlocks.Categories.control,
                extensions: ['colours_control', 'shape_end']
            }
        },
        control_repeat: {
            json: {
                message0: '%1 %2',
                message1: '%1', // Statement
                // message2: '%1', // Icon
                // lastDummyAlign2: 'RIGHT',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('control_repeat', 'png'),
                        width: 43.5,
                        height: 48,
                        alt: '*',
                        flip_rtl: true
                    },
                    {
                        type: 'input_value',
                        name: 'TIMES'
                    }
                ],
                args1: [
                    {
                        type: 'input_statement',
                        name: 'SUBSTACK'
                    }
                ],
                category: ScratchBlocks.Categories.control,
                extensions: ['colours_control', 'shape_statement']
            }
        },
        control_stop_other_scripts_in_sprite: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl(
                            'control_stop_other_scripts_in_sprite',
                            'png'
                        ),
                        width: 60,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.control,
                extensions: ['colours_control', 'shape_statement']
            },
            primitive(args, util) {
                util.stopOtherTargetThreads();
            }
        },

        control_stop_all_scripts: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: '',
                        width: 60,
                        height: 50,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.control,
                extensions: ['colours_control', 'shape_end']
            },
            primitive(args, util) {
                setTimeout(() => util.stopAll(), 0);
            }
        },

        sound_play: {
            json: {
                message0: '%1 %2',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('sound_play', 'png'),
                        width: 45,
                        height: 33,
                        alt: 'flag'
                    },
                    {
                        type: 'input_value',
                        name: 'SOUND_MENU'
                    }
                ],
                category: ScratchBlocks.Categories.sound,
                extensions: ['colours_sounds', 'shape_statement']
            }
        },

        sound_stopallsounds: {
            json: {
                message0: '%1',
                args0: [
                    {
                        type: 'field_image',
                        src: buildImgUrl('sound_stopallsounds', 'png'),
                        width: 45,
                        height: 33,
                        alt: 'flag'
                    }
                ],
                category: ScratchBlocks.Categories.sound,
                extensions: ['colours_sounds', 'shape_statement']
            }
        }
    };
}

const BROADCAST_RECEIVED_MUTATOR = {
    mutationToDom: function() {
        const container = document.createElement('mutation');
        container.setAttribute(
            'bColor',
            this.getFieldValue('BROADCAST_RECEIVED')
        );
        this.updateImage_(this.getFieldValue('BROADCAST_RECEIVED'));
        return container;
    },
    domToMutation: function(xmlElement) {
        this.bColor = xmlElement.getAttribute('bColor');
        if (this.bColor) {
            this.updateImage_(this.bColor);
        }
    },
    updateImage_: function(bColor) {
        const field = this.getField('BROADCAST_RECEIVED_IMAGE');
        field.setValue(buildImgUrl(`${bColor}Receive`, 'png'));
    }
};

const BROADCAST_RECEIVED_MUTATOR_INIT = function() {
    this.getField('BROADCAST_RECEIVED').setValidator(x => {
        this.updateImage_(x);
    });
};

ScratchBlocks.Extensions.registerMutator(
    'bellcode_broadcast_received_mutator',
    BROADCAST_RECEIVED_MUTATOR,
    BROADCAST_RECEIVED_MUTATOR_INIT
);

const BROADCAST_MUTATOR = {
    mutationToDom: function() {
        const container = document.createElement('mutation');
        container.setAttribute('bColor', this.getFieldValue('BROADCAST_SEND'));
        this.updateImage_(this.getFieldValue('BROADCAST_SEND'));
        return container;
    },
    domToMutation: function(xmlElement) {
        this.bColor = xmlElement.getAttribute('bColor');
        if (this.bColor) {
            this.updateImage_(this.bColor);
        }
    },
    updateImage_: function(bColor) {
        const field = this.getField('BROADCAST_IMAGE');
        field.setValue(buildImgUrl(`${bColor}Send`, 'png'));
    }
};

const BROADCAST_MUTATOR_INIT = function() {
    this.getField('BROADCAST_SEND').setValidator(x => {
        this.updateImage_(x);
    });
};

ScratchBlocks.Extensions.registerMutator(
    'bellcode_broadcast_mutator',
    BROADCAST_MUTATOR,
    BROADCAST_MUTATOR_INIT
);
