import defaultStyle from './style';
import defaultColour from './colour';
import defaultCustom from './custom';
import defaultExtensionCustom from './extension-custom';

export const extension = {
    extensionCustom: defaultExtensionCustom
};

export default {
    style: defaultStyle,
    colour: defaultColour,
    custom: defaultCustom
};
