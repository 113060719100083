import GeneralConfig from '../public-repo/utility/generalConfig';
import Fetch from '../public-repo/lib/fetch';

const SPRITE_TAG = 17;
const BACKDROP_TAG = 18;
const SOUND_TAG = 19;

const Type = {
    SPRITE: 'sprite',
    BACKDROP: 'backdrop',
    SOUND: 'sound',
    COSTUME: 'costume'
};

const functionCallOnce = fn => {
    let isCall = false;
    return function () {
        if (!isCall) {
            isCall = true;
            return fn && fn()
                .catch(() => {
                    isCall = false;
                });
        }
        return Promise.resolve(null);
    };
};

const _getMaterials = (categoryId, kw) => {
    const params = {
        page: 1,
        category_id: categoryId,
        kw
    };
    return Fetch({
        method: 'get',
        url: GeneralConfig.serverIo.getMaterial,
        params
    });
};

const transformToCostumes = sprite => {
    const costumeLibraryContent = [];
    for (let i = 0; i < sprite.length; i++) {
        for (let k = 0; k < sprite[i].json.costumes.length; k++) {
            sprite[i].json.costumes[k].type = 'costume';
            sprite[i].json.costumes[k].tags = sprite[i].tags;
            sprite[i].json.costumes[k].md5 = sprite[i].json.costumes[k].md5ext;
            costumeLibraryContent.push(sprite[i].json.costumes[k]);
        }
    }
    return costumeLibraryContent;
};

const transformToSprites = sprites => {
    const spriteLibraryContent = [...sprites];
    for (let i = 0; i < spriteLibraryContent.length; i++) {
        spriteLibraryContent[i].type = 'sprite';
        spriteLibraryContent[i].md5 = spriteLibraryContent[i].md5ext;
        spriteLibraryContent[i].info = spriteLibraryContent[i].info || [];
        spriteLibraryContent[i].json.blocks = spriteLibraryContent[i].json.blocks || {};
        spriteLibraryContent[i].json.costumes = spriteLibraryContent[i].json.costumes || [];
        spriteLibraryContent[i].json.sounds = spriteLibraryContent[i].json.sounds || [];
        spriteLibraryContent[i].json.currentCostume = spriteLibraryContent[i].json.currentCostume || 0;
        spriteLibraryContent[i].json.direction = spriteLibraryContent[i].json.direction || 90;
        spriteLibraryContent[i].json.draggable = spriteLibraryContent[i].json.draggable || false;
        spriteLibraryContent[i].json.isStage = spriteLibraryContent[i].json.isStage || false;
        spriteLibraryContent[i].json.name = spriteLibraryContent[i].name;
        spriteLibraryContent[i].json.rotationStyle = spriteLibraryContent[i].json.rotationStyle || 'all around';
        spriteLibraryContent[i].json.size = spriteLibraryContent[i].json.size || 100;
        spriteLibraryContent[i].json.variables = spriteLibraryContent[i].json.variables || {};
        spriteLibraryContent[i].json.visible = spriteLibraryContent[i].json.visible || true;
        const costumes = spriteLibraryContent[i].json.costumes;
        const sounds = spriteLibraryContent[i].json.sounds;
        if (costumes && costumes.length) {
            for (let j = 0; j < costumes.length; j++) {
                const custume = costumes[j];
                custume.assetId = custume.md5ext.slice(0, custume.md5ext.lastIndexOf('.'));
                custume.dataFormat = custume.md5ext.slice(custume.md5ext.lastIndexOf('.') + 1);
                custume.bitmapResolution = custume.info[2];
                custume.rotationCenterX = custume.info[0];
                custume.rotationCenterY = custume.info[1];
            }
        }
        if (sounds && sounds.length) {
            for (let j = 0; j < sounds.length; j++) {
                const sound = sounds[j];
                sound.assetId = sound.md5ext.slice(0, sound.md5ext.lastIndexOf('.'));
                sound.dataFormat = sound.md5ext.slice(sound.md5ext.lastIndexOf('.') + 1);
                sound.format = sound.md5ext.slice(sound.md5ext.lastIndexOf('.') + 1);
                sound.rate = sound.rate || 0;
                sound.sampleCount = sound.sampleCount || 0;
            }
        }
    }
    return spriteLibraryContent;
};

const transformToSounds = sounds => {
    const soundLibraryContent = [...sounds];
    for (let i = 0; i < soundLibraryContent.length; i++) {
        const sound = soundLibraryContent[i];
        sound.md5 = sound.md5ext;
    }
    return soundLibraryContent;
};

const transformToBackdrops = backdrops => {
    const backdropLibraryContent = [...backdrops];
    for (let i = 0; i < backdropLibraryContent.length; i++) {
        const backdrop = backdropLibraryContent[i];
        // For backdrop, info[0] info[1] map to width height
        // instead of rotationCenterXY
        backdrop.info[0] *= 2;
        backdrop.info[1] *= 2;
        backdrop.md5 = backdrop.md5ext;
    }
    return backdropLibraryContent;
};

const getBackdropLibrary = functionCallOnce(
    () => _getMaterials(BACKDROP_TAG)
        .then(data => transformToBackdrops(data.list))
);
const getSoundLibrary = functionCallOnce(
    () => _getMaterials(SOUND_TAG)
        .then(data => transformToSounds(data.list))
);
const getSpriteLibrary = functionCallOnce(
    () => _getMaterials(SPRITE_TAG)
        .then(data => transformToSprites(data.list))
);
const getCostumeLibrary = functionCallOnce(
    () => _getMaterials(SPRITE_TAG)
        .then(data => transformToCostumes(data.list))
);

const getContentLibrary = type => {
    switch (type) {
    case Type.SPRITE:
        return getSpriteLibrary();
    case Type.BACKDROP:
        return getBackdropLibrary();
    case Type.SOUND:
        return getSoundLibrary();
    case Type.COSTUME:
        return getCostumeLibrary();
    }
};

const extendContentLibrary = (source, contentLibrary, contentTags) => {
    if (!source) return;

    if (contentLibrary) {
        Array.prototype.push.apply(contentLibrary, source);
    }

    if (contentTags) {
        source.forEach(item => {
            item.tags.forEach(val => {
                if (contentTags.every(tagObj => tagObj.tag !== val)) {
                    contentTags.push({
                        tag: val,
                        intlLabel: {
                            defaultMessage: val,
                            description: `Custom Tag ${val}`,
                            id: `gui.libraryTags.${val}`
                        }
                    });
                }
            });
        });
    }
};

export {
    BACKDROP_TAG,
    SPRITE_TAG,
    SOUND_TAG,
    Type,
    getContentLibrary,
    extendContentLibrary
};
