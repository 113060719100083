/* deprecate */

import {USER, LESSON, DETECT} from 'PublicRepo/lib/config';
import GeneralConfig from 'PublicRepo/utility/generalConfig';
export {USER, LESSON, DETECT};

export default {
    redirectMode: false,

    userTypeMap: {
        student: 1,
        teacher: 2
    },

    recordStateMap: {
        READY: 'ready',
        START: 'start',
        FINISH: 'finish',
        RECORDING: 'recording'
    },

    serverIo: GeneralConfig.serverIo,

    portraitIde: 2,
    resourceOrigin: 'https://img.wkcoding.com/',
    ideOrigin: GeneralConfig.guiUrl,

    get defaultAudio() {
        const defaultAudioList = [
            'https://other.wkcoding.com/FqT0AFygQkcXB6boplXCFw48LcZb'
            // 'https://other.wkcoding.com/FpMIVJtCO4obkPQyigGcjjyNtG2n',
            // 'https://other.wkcoding.com/admin/resource/FvKBuF1T-G5is0DZyrBKWG3T1tKn',
            // 'https://other.wkcoding.com/admin/resource/FuXaOXdq-KE0BDGWNKuVtRqeb1OZ',
            // 'https://other.wkcoding.com/Fqohx3Xm8QiM97hPRNvcH-13xsDG'
        ];
        const num = Math.floor(Math.random() * defaultAudioList.length);
        return defaultAudioList[num];
    },
    empty1pxImgUrl: `${GeneralConfig.resourceOrigin}puzzle-1px/1px-small.png`,

    portraitWidth: 750,
    portraitHeihgt: 1050,
    stageWidth: 682 * 2,
    stageHeight: 384 * 2,
    stageRatio: 408 / 306,
    stageRatioForPortrait: 5 / 7,

    TIMEOUT: 20000,

    bellplanet: 'bellplanet',

    postMsgId: {
        puzzeSettle: 'puzzleSettle',
        closePuzzle: 'closePuzzle',
        diySettle: 'diySettle', // 推送DIY关卡完成消息给小贝
        closeDiy: 'closeDiy' // 推送关闭DIY关卡消息给小贝
    }
};
