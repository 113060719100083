// Polyfills
import 'babel-polyfill';
import 'es6-object-assign/auto';
import 'core-js/fn/array/includes';
import 'core-js/fn/promise/finally';
import 'intl'; // For Safari 9

import React from 'react';
import ReactDOM from 'react-dom';

import analytics from '../lib/analytics';
import AppStateHOC from '../lib/app-state-hoc.jsx';
import BrowserModalComponent from '../components/browser-modal/browser-modal.jsx';
import supportedBrowser from '../lib/supported-browser';
import tokenStorage from '../public-repo/utility/tokenStorage';
import styles from './index.css';
import '!style-loader!css-loader!./fix.css';
import '../lib/libraries/extend-lib-json';

// Register "base" page view
analytics.pageview('/');

const appTarget = document.createElement('div');
appTarget.className = styles.app;
document.body.appendChild(appTarget);

// 从其他系统重定向过来时. 如果携带了token. 则使用token自动登陆
tokenStorage.setupByLocation().then(() => {
    if (supportedBrowser()) {
        // require needed here to avoid importing unsupported browser-crashing code
        // at the top level
        require('./render-gui.jsx').default(appTarget);
    
    } else {
        BrowserModalComponent.setAppElement(appTarget);
        const WrappedBrowserModalComponent = AppStateHOC(BrowserModalComponent, true /* localesOnly */);
        const handleBack = () => {};
        // eslint-disable-next-line react/jsx-no-bind
        ReactDOM.render(<WrappedBrowserModalComponent onBack={handleBack} />, appTarget);
    }
});
