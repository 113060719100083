/**
 * 生成火山引擎配置
 */
import Rangers from '@apm-insight-web/rangers-site-sdk/private';

export default {
  generateVolcengineConfig(params) {
    return Rangers('config', {
      app_id: 238430,
      serverDomain: 'https://tbm.snssdk.com',
      ...params,
    });
  },
};
